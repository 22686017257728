/* eslint-disable jsx-a11y/label-has-associated-control */
import './InvoicedSince.scss';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import LookupOptions from '../../features/lookups/LookupOptions';
import { LookupTypes } from '../../features/lookups/types';
import { selectAllProjects } from '../../features/projects/slice';
import { selectAllRequests } from '../../features/billingRequests/slice';
import { InvoiceDetailsViewModel } from './viewModels';
import InvoiceTable from './InvoiceTable';
import { buildInvoiceData } from './logic';

const InvoicedSince: React.FC = () => {
  const allProjects = useAppSelector((state: RootState) => selectAllProjects(state));
  const allBillingRequests = useAppSelector((state: RootState) => selectAllRequests(state));

  const [invoiceType, setInvoiceType] = React.useState<string>('');
  const [invoiceDate, setInvoiceDate] = React.useState<string | null>(null);
  const [data, setData] = React.useState<InvoiceDetailsViewModel[] | null>(null);

  const onClick = React.useCallback(() => {
    setData(buildInvoiceData(allBillingRequests, allProjects, invoiceDate, invoiceType));
  }, [allBillingRequests, allProjects, invoiceDate, invoiceType]);

  return (
    <main className="invoiced-since">
      <header className="invoiced-since-header">
        <h1 className="center-align-text invoiced-since-title">Invoice Summary</h1>
        <nav>
          <ul className="link-list">
            <li className="link">
              <label>
                Invoice Type:
                <select
                  onChange={(e) => setInvoiceType(e.target.value)}
                  value={invoiceType}
                >
                  <LookupOptions lookupType={LookupTypes.InvoiceType} />
                </select>
              </label>
            </li>
            <li className="link">
              <label>
                Invoices Since:
                <input
                  type="date"
                  value={invoiceDate ?? ''}
                  onChange={(e) => setInvoiceDate(e.target.value)}
                />
              </label>
            </li>
            <li className="link">
              <button
                type="button"
                onClick={onClick}
              >
                Load
              </button>
            </li>
            <li className="link"><Link to="/">Home</Link></li>
          </ul>
        </nav>
      </header>
      <section className="invoiced-since-main">
        <InvoiceTable invoices={data} />
      </section>
    </main>
  );
};

export default InvoicedSince;
