import React from 'react';
import { useAppSelector } from '../../app/hooks';
import { selectOptionValue } from './slice';

type ContractBillingStatusEmojiProps = {
  id: number;
};

function ContractBillingStatusEmoji({ id }: ContractBillingStatusEmojiProps): JSX.Element {
  const value = useAppSelector((state) => selectOptionValue(state, id));

  if (!value) {
    return (<span aria-label={`Unknown status: ${id}`} title={`Unknown status: ${id}`}>&nbsp;</span>);
  }

  if (!value.enabled) {
    return (<span aria-label={`Disabled status: ${id}`} title={`Disabled status: ${id}`}>&nbsp;</span>);
  }

  return (
    <span role="img" aria-label={value.name} title={value.name}>{String.fromCodePoint(value.emojiCode)}</span>
  );
}

export default ContractBillingStatusEmoji;
