import {
  BillingTemplate, Contract, ContractCloseRequestUpdate, ContractCorrection, ContractUpdate,
  NewCloseContractRequest, PhaseAdjustment, PhaseAuthorization, Project, UnsentAuthorization,
} from './types';

export const fetchProjects = async (): Promise<Project[] | undefined> => {
  try {
    const result = await fetch('/api/v1/projects', { credentials: 'include' });

    if (!result.ok) {
      return undefined;
    }

    return result.json();
  } catch {
    return undefined;
  }
};

export const updateProject = async (project: Project): Promise<Project | undefined> => {
  try {
    const result = await fetch(`/api/v1/projects/${project.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(project),
      credentials: 'include',
    });

    if (!result.ok) {
      return undefined;
    }

    return result.json();
  } catch {
    return undefined;
  }
};

export const updateContract = async (
  projectId: number, contract: ContractUpdate,
): Promise<Contract | undefined> => {
  try {
    const result = await fetch(`/api/v1/projects/${projectId}/contracts/${contract.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(contract),
      credentials: 'include',
    });

    if (!result.ok) {
      return undefined;
    }

    return result.json();
  } catch {
    return undefined;
  }
};

export const generatePaymentSchedule = async (project: Project): Promise<Blob | undefined> => {
  try {
    const result = await fetch(`/api/v1/projects/${project.id}/paymentschedule`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(project),
      credentials: 'include',
    });

    if (!result.ok) {
      return undefined;
    }

    return result.blob();
  } catch {
    return undefined;
  }
};

export const saveTemplate = async (
  projectId: number, template: BillingTemplate, isNew: boolean,
): Promise<BillingTemplate | undefined> => {
  try {
    const url = isNew
      ? `/api/v1/projects/${projectId}/billingTemplates`
      : `/api/v1/projects/${projectId}/billingTemplates/${template.id}`;
    const method = isNew ? 'POST' : 'PUT';

    const result = await fetch(url, {
      method,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(template),
      credentials: 'include',
    });

    if (!result.ok) {
      return undefined;
    }

    return result.json();
  } catch {
    return undefined;
  }
};

export const linkTemplates = async (
  projectId: number, templateId: number, contractIds: number[],
): Promise<boolean> => {
  try {
    const result = await fetch(`/api/v1/projects/${projectId}/billingTemplates/${templateId}/contracts`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(contractIds),
      credentials: 'include',
    });

    return result.ok;
  } catch {
    return false;
  }
};

export const refreshContract = async (
  projectId: number, contract: ContractUpdate,
): Promise<Contract | undefined> => {
  try {
    const result = await fetch(`/api/v1/projects/${projectId}/contracts/${contract.id}/refresh`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(contract),
      credentials: 'include',
    });

    if (!result.ok) {
      return undefined;
    }

    return result.json();
  } catch {
    return undefined;
  }
};

export const authorizeAmounts = async (
  projectId: number, contractId: number, authorizations: PhaseAuthorization[],
): Promise<boolean> => {
  try {
    const result = await fetch(`/api/v1/projects/${projectId}/contracts/${contractId}/authorize`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(authorizations),
      credentials: 'include',
    });

    if (!result.ok) {
      return false;
    }

    return true;
  } catch {
    return false;
  }
};

export const deleteAuthorizations = async (
  projectId: number, contractId: number, authorizationMonth: string | null,
): Promise<boolean> => {
  try {
    const monthParam = authorizationMonth === null
      ? ''
      : `authorizationMonth=${authorizationMonth}`;
    const result = await fetch(`/api/v1/projects/${projectId}/contracts/${contractId}/authorize/?${monthParam}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });

    if (!result.ok) {
      return false;
    }

    return true;
  } catch {
    return false;
  }
};

export const submitContractCorrection = async (
  projectId: number, contractId: number, correction: ContractCorrection,
): Promise<boolean> => {
  try {
    const result = await fetch(`/api/v1/projects/${projectId}/contracts/${contractId}/correction`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(correction),
    });

    return result.ok;
  } catch {
    return false;
  }
};

export const submitNewCloseContractRequest = async (
  projectId: number, contractId: number, request: NewCloseContractRequest,
): Promise<boolean> => {
  try {
    const result = await fetch(`/api/v1/projects/${projectId}/contracts/${contractId}/close`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(request),
    });

    return result.ok;
  } catch {
    return false;
  }
};

export const updateCloseContractRequest = async (
  projectId: number, contractId: number, request: ContractCloseRequestUpdate,
): Promise<boolean> => {
  try {
    const result = await fetch(`/api/v1/projects/${projectId}/contracts/${contractId}/close/${request.closeRequestId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(request),
    });

    return result.ok;
  } catch {
    return false;
  }
};

export const fetchUnsentAuthorizations = async (
): Promise<UnsentAuthorization[] | undefined> => {
  try {
    const result = await fetch('/api/v1/admin/authorizations/unsent', { credentials: 'include' });

    if (!result.ok) {
      return undefined;
    }

    return result.json();
  } catch {
    return undefined;
  }
};

export const submitNewPhaseAdjustment = async (
  projectId: number, contractId: number, contractPhaseId: number, adjustment: PhaseAdjustment,
) : Promise<boolean> => {
  try {
    const result = await fetch(`/api/v1/projects/${projectId}/contracts/${contractId}/contractPhases/${contractPhaseId}/adjustment`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(adjustment),
    });

    return result.ok;
  } catch {
    return false;
  }
};
