/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */

import './BillingTemplateCard.scss';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, Card } from '@dsx/react';
import { useAppDispatch } from '../../app/hooks';
import { Project } from '../../features/projects/types';
import {
  clearNewBillingTemplate,
  initializeNewBillingTemplate,
  linkTemplates,
  saveTemplate,
} from '../../features/projects/slice';
import LookupOptions from '../../features/lookups/LookupOptions';
import { LookupTypes } from '../../features/lookups/types';
import { BillingTemplateViewModel } from './viewModels';
import { fromBillingTemplateViewModel } from './mapping';
import displayError from '../../components/displayError';

type BillingTemplateCardProps = {
  template: BillingTemplateViewModel;
  project: Project;
  contractId: number;
  relatedContractIds: number[];
  isSelected: boolean;
}

/*
Title/Name:
  * For brand new templates, it should default to "New template..." {not tested with this component}
  * For clones, then it should default to "Copy of {original}" {not tested with this component}
  * If the template is currently selected, show some visual cue
  * If the template is currently disabled, show a different visual cue?

Editability:
  * If the id === 0 (i.e., a brand new template), then everything is editable
  * If the id !== 0 (i.e., a template that has been saved), then only the status is editable
    * Maybe this is done by a button click?

Actions...
  * ...for new billing templates:
    * Save
      * Always present - clicking saves the template
    * Discard
      * Always present - clicking discards the template
  * ...for existing billing templates:
    * Select
      * Selects the template for the single contract
      * Always present, but disabled if the template is disabled
    * Select for X Contracts
      * Selects the template for the contract + all other contracts with the same
      * Always present, but disabled if the template is disabled
    * Edit
      * Creates a new template, defaulting to the same values as this one
      * Always present
    * Enable / Disable
      * Enables / disable the template
      * Always present, but the text and action changes based off current status
*/

const yesNoOptions = (
  <>
    <option value="">&nbsp;</option>
    <option value={1}>Yes</option>
    <option value={0}>No</option>
  </>
);

function buildTitleDecorator(isSelected: boolean, enabled: boolean): string {
  const selected = isSelected ? '(SELECTED)' : '';
  const disabled = enabled ? '' : '(INACTIVE)';
  return `${selected} ${disabled}`;
}

function buildTitleClass(isSelected: boolean, enabled: boolean): string {
  const selectedClass = isSelected ? ' bold-text' : '';
  const disabledClass = enabled ? '' : ' disabled-template';
  return `left-align-text${selectedClass}${disabledClass}`;
}

function BillingTemplateCard(
  {
    template, project, contractId, relatedContractIds, isSelected,
  }: BillingTemplateCardProps,
): JSX.Element {
  const { id } = template;
  const editable = id === 0;
  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const {
    register, getValues, formState, trigger,
  } = useForm<BillingTemplateViewModel>({
    defaultValues: template, mode: 'all',
  });
  const { errors } = formState;
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    trigger();
  }, [trigger]);

  const linkFunction = (contractIds: number[]): void => {
    setIsSaving(true);
    linkTemplates(dispatch, project, id, contractIds)
      .then((success) => {
        if (!success) {
          displayError('Failed to save billing template');
        }
        setIsSaving(false);
      })
      .catch(() => {
        displayError('Failed to save billing template');
        setIsSaving(false);
      });
  };

  const saveFunction = React.useCallback((t: BillingTemplateViewModel): void => {
    setIsSaving(true);

    saveTemplate(dispatch, project, fromBillingTemplateViewModel(t), editable)
      .then((success) => {
        if (!success) {
          displayError('Failed to save billing template');
        }
        setIsSaving(false);
      })
      .catch(() => {
        displayError('Failed to save billing template');
        setIsSaving(false);
      });
  }, [dispatch, editable, project]);

  const toggleEnabled = React.useCallback((): void => {
    const updated = {
      ...template,
      enabled: !template.enabled,
    };
    saveFunction(updated);
  }, [saveFunction, template]);

  const saveNew = React.useCallback((): void => {
    const t = getValues();
    saveFunction(t);
  }, [getValues, saveFunction]);

  const clone = React.useCallback((): void => {
    const t = fromBillingTemplateViewModel(template);
    t.id = 0;
    t.name = `Template ${(project?.billingTemplates.length ?? 0) + 1}`;
    t.enabled = true;
    dispatch(initializeNewBillingTemplate(t));
  }, [dispatch, project, template]);

  const billToOptions = project.customerContacts.map(
    (c) => (<option key={c} value={c}>{c}</option>),
  );
  const billingAddressOptions = project.billingAddresses.map(
    (c) => (<option key={c} value={c}>{c}</option>),
  );

  // Generate the buttons
  const selectSingleButton = editable
    ? (<></>)
    : (
      <Button type="button" variant="primary" disabled={isSaving || !template.enabled} onClick={() => linkFunction([contractId])}>
        Select
      </Button>
    );
  const selectAllButton = editable
    ? (<></>)
    : (
      <Button type="button" variant="default" disabled={isSaving || !template.enabled} onClick={() => linkFunction(relatedContractIds)}>
        {`Select and Apply for ${relatedContractIds.length - 1} Other Contract(s)`}
      </Button>
    );
  const cloneButton = editable
    ? (<></>)
    : (
      <Button
        type="button"
        variant="default"
        disabled={isSaving}
        onClick={clone}
      >
        Edit
      </Button>
    );
  const statusButton = editable
    ? (<></>)
    : (
      <Button type="button" variant="default" disabled={isSaving} onClick={toggleEnabled}>
        {template.enabled ? 'Disable' : 'Enable'}
      </Button>
    );
  const saveButton = editable
    ? (
      <Button type="button" variant="default" disabled={isSaving || !formState.isValid} onClick={saveNew}>
        Save
      </Button>
    )
    : (<></>);
  const discardButton = editable
    ? (
      <Button type="button" variant="default" disabled={isSaving} onClick={() => dispatch(clearNewBillingTemplate())}>
        Discard
      </Button>
    )
    : (<></>);

  // These 2 fields are being set to text boxes if they are not editable.
  // This is because the original production data was formatted differently for billing addresses.
  // If it stayed as a select, then when the billing addresses don't match,
  // it will show as a blank select field. By being a text box, you can see the value.
  const billingAddressField = editable
    ? (
      <select
        className={errors.billingAddress ? 'invalid' : ''}
        {...register('billingAddress', { disabled: !editable, required: true })}
      >
        {billingAddressOptions}
      </select>
    )
    : (
      <input type="text" {...register('billingAddress', { disabled: true, required: true })} />
    );

  const billToField = editable
    ? (
      <select
        className={errors.billToAttention ? 'invalid' : ''}
        {...register('billToAttention', { disabled: !editable, required: true })}
      >
        {billToOptions}
      </select>
    )
    : (
      <input type="text" {...register('billToAttention', { disabled: true, required: true })} />
    );

  return (
    <Card variant="outline">
      <div className="template-card-title">
        <span>
          {buildTitleDecorator(isSelected, template.enabled)}
        </span>
        <span className={buildTitleClass(isSelected, template.enabled)}>
          {template.name}
        </span>
      </div>
      <form className="billing-template-data">
        <input type="hidden" {...register('id')} />
        <input type="hidden" {...register('enabled')} />
        <label className="billing-template-field name-label">
          <span>Name</span>
          <input
            className={errors.name ? 'invalid name-value' : 'name-value'}
            type="text"
            {...register('name', { disabled: !editable, required: true })}
          />
        </label>
        <label className="billing-template-field">
          <span>Do Reimbursables Require Receipts?</span>
          <select
            className={errors.includeReceipts ? 'invalid' : ''}
            {...register('includeReceipts', { disabled: !editable, required: true })}
          >
            {yesNoOptions}
          </select>
        </label>
        <label className="billing-template-field">
          <span>Provide Lien Waiver?</span>
          <select
            className={errors.provideLienWaiver ? 'invalid' : ''}
            {...register('provideLienWaiver', { disabled: !editable, required: true })}
          >
            {yesNoOptions}
          </select>
        </label>
        <label className="billing-template-field">
          <span>Bill To Contact</span>
          {billToField}
        </label>
        <label className="billing-template-field">
          <span>Billing Address</span>
          {billingAddressField}
        </label>
        <label className="billing-template-field">
          <span>Customer PO Number</span>
          <input type="text" {...register('customerPONumber', { disabled: !editable })} />
        </label>
        <label className="billing-template-field">
          <span>Invoice Type</span>
          <select
            className={errors.invoiceTypeId ? 'invalid' : ''}
            {...register('invoiceTypeId', { disabled: !editable, required: true })}
          >
            <LookupOptions lookupType={LookupTypes.InvoiceType} />
          </select>
        </label>
        <label className="billing-template-field">
          <span>Financial Services Sends To (Email)</span>
          <textarea
            className={errors.invoiceTo ? 'invalid' : ''}
            {...register('invoiceTo', { disabled: !editable, required: true })}
          />
        </label>
        <label className="billing-template-field">
          <span>Financial Services Sends CC (Email)</span>
          <textarea {...register('invoiceCc', { disabled: !editable })} />
        </label>
        <label className="billing-template-field">
          <span>Additional Notes</span>
          <textarea {...register('notes', { disabled: !editable })} />
        </label>
        <div className="billing-template-actions">
          {selectSingleButton}
          {selectAllButton}
          {cloneButton}
          {statusButton}
          {saveButton}
          {discardButton}
        </div>
      </form>
    </Card>
  );
}

export default BillingTemplateCard;
