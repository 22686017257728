import React from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import DistinctStatusEmoji from '../contractBillingStatusOptions/DistinctStatusEmoji';
import LookupValue from '../lookups/LookupValue';
import { LookupTypes } from '../lookups/types';
import { determineCurrentMonthAbbreviation } from '../monthUtils';
import UserName from '../users/UserName';
import { selectProjectById } from './slice';
import { computeMonthlyAmounts, formatAsCurrency } from './utils';

type ProjectRowProps = {
  id: number;
}

function ConstructDesignerRow(s: string): JSX.Element {
  return (
    <div key={s}>{s}</div>
  );
}

function ProjectRow({ id }: ProjectRowProps): JSX.Element {
  const project = useAppSelector((state: RootState) => selectProjectById(state, id));
  const statusIds = project?.contracts.map((c) => c.contractBillingStatusId) ?? [];

  const designerRows = project?.designers
    ? project.designers.map(ConstructDesignerRow)
    : (<></>);

  const {
    authorizedCurrentMonth, expectedCurrentMonth,
  } = React.useMemo(() => {
    if (!project) { return { authorizedCurrentMonth: 0, expectedCurrentMonth: 0 }; }
    return computeMonthlyAmounts(project);
  }, [project]);

  if (!project) {
    return (<></>);
  }

  return (
    <tr>
      <td className="center-align-text"><DistinctStatusEmoji ids={statusIds} /></td>
      <td><Link to={`/projects/${id}`}>{project.pmtNumber}</Link></td>
      <td>{project.name}</td>
      <td><LookupValue lookupType={LookupTypes.ProjectStatus} lookupId={project.statusId} /></td>
      <td><UserName userId={project.dataManagerId} /></td>
      <td>{designerRows}</td>
      <td className="right-align-text">{formatAsCurrency(expectedCurrentMonth)}</td>
      <td className="right-align-text">{formatAsCurrency(authorizedCurrentMonth)}</td>
    </tr>
  );
}

function FilterdProjectsTable(): JSX.Element {
  const projectIds = useAppSelector((state: RootState) => state.projects.filteredProjectIds);

  const rows = projectIds.map((id) => (<ProjectRow key={id} id={id} />));

  return (
    <table>
      <thead>
        <tr>
          <th>Status</th>
          <th>PMT #</th>
          <th>Project Name</th>
          <th>Project Status</th>
          <th>Data Manager</th>
          <th>Interior Designer</th>
          <th>{`Expected ${determineCurrentMonthAbbreviation()} Amount`}</th>
          <th>{`Authorized ${determineCurrentMonthAbbreviation()} Amount`}</th>
        </tr>
      </thead>
      <tbody>
        {rows}
      </tbody>
    </table>
  );
}

export default FilterdProjectsTable;
