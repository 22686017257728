export default function displayDownload(data: Blob, name: string): void {
  const url = window.URL.createObjectURL(data);
  const a = document.createElement('a');
  a.href = url;
  a.download = name;
  document.body.appendChild(a);
  // we need to append the element to the dom -> otherwise it will not work in firefox
  a.click();
  a.remove(); // afterwards we remove the element again
}
