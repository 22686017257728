import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import usersReducer from '../features/users/slice';
import optionsReducer from '../features/contractBillingStatusOptions/slice';
import lookupsReducer from '../features/lookups/slice';
import projectsReducer from '../features/projects/slice';
import billingRequestsReducer from '../features/billingRequests/slice';

export const store = configureStore({
  reducer: {
    users: usersReducer,
    contractBillingStatusOptions: optionsReducer,
    lookups: lookupsReducer,
    projects: projectsReducer,
    billingRequests: billingRequestsReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
