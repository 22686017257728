import {
  BillingRequest, BilllingRequestUpdate, CustomerPOVerificationRequest,
  CustomerPOVerificationResult, NewBillingRequest, ReceiptTemplateRequest,
} from './types';

export const fetchBillingRequests = async (): Promise<BillingRequest[] | undefined> => {
  try {
    const result = await fetch('/api/v1/billingRequests', { credentials: 'include' });

    if (!result.ok) {
      return undefined;
    }

    return result.json();
  } catch {
    return undefined;
  }
};

export const fetchBillingRequestById = async (id: number): Promise<BillingRequest | undefined> => {
  try {
    const result = await fetch(`/api/v1/billingRequests/${id}`, { credentials: 'include' });

    if (!result.ok) {
      return undefined;
    }

    return result.json();
  } catch {
    return undefined;
  }
};

export const submitBillingRequests = async (
  newRequest: NewBillingRequest,
): Promise<BillingRequest[] | undefined> => {
  try {
    const result = await fetch('/api/v1/billingRequests', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newRequest),
      credentials: 'include',
    });

    if (!result.ok) {
      return undefined;
    }

    return result.json();
  } catch {
    return undefined;
  }
};

export const updateBillingRequest = async (
  update: BilllingRequestUpdate,
): Promise<BillingRequest | undefined> => {
  try {
    const result = await fetch(`/api/v1/billingRequests/${update.id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(update),
      credentials: 'include',
    });

    if (!result.ok) {
      return undefined;
    }

    return result.json();
  } catch {
    return undefined;
  }
};

export const generateReceiptTemplate = async (
  data: ReceiptTemplateRequest,
): Promise<Blob | undefined> => {
  try {
    const result = await fetch(`/api/v1/billingRequests/${data.billingRequestId}/reimbursableReceipt`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      credentials: 'include',
    });

    if (!result.ok) {
      return undefined;
    }

    return result.blob();
  } catch {
    return undefined;
  }
};

export const verifyCustPOrderNumbers = async (
  payload: CustomerPOVerificationRequest,
): Promise<CustomerPOVerificationResult[] | undefined> => {
  try {
    const result = await fetch('/api/v1/billingRequests/verifyCustomerPONumbers', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
      credentials: 'include',
    });

    if (!result.ok) {
      return undefined;
    }

    return result.json();
  } catch {
    return undefined;
  }
};
