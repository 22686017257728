import './UnsentAuthorizations.scss';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { UnsentAuthorization } from '../../features/projects/types';
import { fetchUnsentAuthorizations } from '../../features/projects/api';
import MonthSummaryTable from './MonthSummaryTable';
import displayError from '../../components/displayError';

type GroupedAuthorizations = {
  month: string;
  authorizations: UnsentAuthorization[];
}

function groupAuthorizations(
  prev: Map<string, GroupedAuthorizations>,
  curr: UnsentAuthorization,
): Map<string, GroupedAuthorizations> {
  const group = prev.get(curr.authorizedMonth);
  if (group === undefined) {
    const newGroup: GroupedAuthorizations = {
      month: curr.authorizedMonth,
      authorizations: [curr],
    };
    prev.set(curr.authorizedMonth, newGroup);
  } else {
    group.authorizations.push(curr);
  }
  return prev;
}

const UnsentAuthorizations: React.FC = () => {
  const [authorizations, setAuthorizations] = React.useState<GroupedAuthorizations[]>([]);

  const onRefresh = React.useCallback(() => {
    fetchUnsentAuthorizations()
      .then((o) => {
        if (o === undefined) {
          displayError('Failed to load unsent authorizations');
          return;
        }

        const results = o.reduce(groupAuthorizations, new Map<string, GroupedAuthorizations>());
        const authList: GroupedAuthorizations[] = [];
        results.forEach((item) => authList.push(item));
        setAuthorizations(authList);
      })
      .catch(() => {
        displayError('Failed to load unsent authorizations');
      });
  }, []);

  return (
    <main className="unsent-auths">
      <header className="unsent-auths-header">
        <h1 className="center-align-text unsent-auths-title">Unsent Authorizations</h1>
        <nav>
          <ul className="link-list">
            <li className="link">
              <button
                type="button"
                onClick={onRefresh}
              >
                Refresh
              </button>
            </li>
            <li className="link"><Link to="/">Home</Link></li>
          </ul>
        </nav>
      </header>
      <section className="unsent-auths-cards">
        {
          authorizations.map(
            (a) => (
              <MonthSummaryTable key={a.month} month={a.month} authorizations={a.authorizations} />
            ),
          )
        }
      </section>
    </main>
  );
};

export default UnsentAuthorizations;
