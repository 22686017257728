/* eslint-disable jsx-a11y/label-has-associated-control */
// The label > input pattern should be fine based off of:
// https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/label-has-associated-control.md
// However, it is still failing linting, so suppressing for this file.

import React from 'react';
import { useAppDispatch } from '../../app/hooks';
import LookupValue from '../lookups/LookupValue';
import { LookupTypes } from '../lookups/types';
import UserName from '../users/UserName';
import { Project } from './types';
import { determineCustomPropertyDisplayValue, formatAsCurrency } from './utils';
import { updateProject, generatePaymentSchedule } from './slice';
import downloadHelper from '../downloadHelper';

type ProjectSummaryProps = {
  project: Project;
}

function ProjectSummary({ project }: ProjectSummaryProps): JSX.Element {
  const dispatch = useAppDispatch();
  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const [isGenerating, setIsGenerating] = React.useState<boolean>(false);
  const [notes, setNotes] = React.useState<string>(project.notes);

  const isNotesDirty = project.notes !== notes;

  React.useEffect(() => {
    setNotes(project.notes);
  }, [project]);

  const clickHandler = React.useCallback((): void => {
    setIsSaving(true);
    const updated: Project = {
      ...project,
      notes,
    };
    updateProject(dispatch, updated)
      .then((success) => {
        if (!success) {
          // eslint-disable-next-line no-alert
          alert('Failed to save the notes.');
        }
        setIsSaving(false);
      })
      .catch(() => {
        // eslint-disable-next-line no-alert
        alert('Failed to save the notes.');
        setIsSaving(false);
      });
  }, [project, dispatch, notes]);

  const generatePaymentScheduleClickHandler = React.useCallback((): void => {
    setIsGenerating(true);

    generatePaymentSchedule(project)
      .then((blob) => {
        if (blob) {
          downloadHelper(blob, `${project.name}.xlsx`);
        } else {
          // eslint-disable-next-line no-alert
          alert('Failed to generate payment schedule.');
        }
        setIsGenerating(false);
      })
      .catch(() => {
        // eslint-disable-next-line no-alert
        alert('Failed to generate payment schedule.');
        setIsGenerating(false);
      });
  }, [project]);

  return (
    <aside className="project-details-summary">
      <dl>
        <dt>Project Type</dt>
        <dd>
          <LookupValue
            lookupType={LookupTypes.ProjectType}
            lookupId={project.typeId}
          />
        </dd>
        <dt>Project Status</dt>
        <dd>
          <LookupValue
            lookupType={LookupTypes.ProjectStatus}
            lookupId={project.statusId}
          />
        </dd>
        <dt>Project Total</dt>
        <dd>{formatAsCurrency(project.contracts.reduce((acc, c) => acc + c.totalAmount, 0))}</dd>
        <dt>Owner Dept.</dt>
        <dd>
          <LookupValue
            lookupType={LookupTypes.OwnerDepartment}
            lookupId={project.ownerDepartmentId}
          />
        </dd>
        <dt>Master Account</dt>
        <dd>{project.masterAccount}</dd>
        <dt>Data Manager</dt>
        <dd><UserName userId={project.dataManagerId} /></dd>
        <dt>Interior Designer</dt>
        <dd>{project.designers.join(', ')}</dd>
        <dt>Capital Sales Consultants</dt>
        <dd>{project.capitalSalesConsultants.join(', ')}</dd>
        <dt>UDI Enabled</dt>
        <dd>{project.userDefinedInvoicingEnabled ? 'Yes' : 'No'}</dd>
        <dt>Do Not Send Allowed</dt>
        <dd>
          <LookupValue
            lookupType={LookupTypes.ApprovalItemStatus}
            lookupId={project.doNotSendAllowed}
          />
        </dd>
        <dt>Professional Services Info Sheet</dt>
        <dd>
          {determineCustomPropertyDisplayValue(project.professionalServicesProvidedInfoSheet)}
        </dd>
        <dt>Invoice Requirements Sheet</dt>
        <dd>
          {determineCustomPropertyDisplayValue(project.invoiceRequirementsSheet)}
        </dd>
      </dl>
      <form>
        <div>
          <label>
            Notes:
            <textarea
              id="project-notes"
              name="project-notes"
              className="notes"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              disabled={isSaving}
            />
          </label>
          <button
            type="button"
            disabled={!isNotesDirty || isSaving}
            onClick={clickHandler}
          >
            Save Notes
          </button>
        </div>
        <button
          type="button"
          disabled={isGenerating}
          onClick={generatePaymentScheduleClickHandler}
        >
          Download Payment Schedule
        </button>
      </form>
    </aside>
  );
}

export default ProjectSummary;
