import * as React from 'react';
import LookupValue from '../../features/lookups/LookupValue';
import { LookupTypes } from '../../features/lookups/types';
import UserName from '../../features/users/UserName';
import { formatAsCurrency } from '../../features/projects/utils';
import { InvoiceDetailsViewModel } from './viewModels';

type InvoiceTableProps = {
  invoices: InvoiceDetailsViewModel[] |null;
}

const InvoiceTable: React.FC<InvoiceTableProps> = ({ invoices }: InvoiceTableProps) => {
  if (!invoices) {
    return (<></>);
  }

  return (
    <table>
      <thead>
        <tr>
          <th>From Contract Billing?</th>
          <th>Invoice Date</th>
          <th>Invoice Type</th>
          <th>Invoice Number</th>
          <th>Invoice Total</th>
          <th>Send Invoice To</th>
          <th>CC Invoice To</th>
          <th>Responsible Party</th>
          <th>Bill To Attention</th>
          <th>Project Manager</th>
          <th>Project ID</th>
          <th>Needs Lien Waiver?</th>
          <th>Provide Receipts to Client?</th>
          <th>Additional Notes</th>
        </tr>
      </thead>
      <tbody>
        {
          invoices.map((i) => (
            <tr key={i.invoiceNumber}>
              <td>{i.fromContractBilling ? 'Yes' : 'No'}</td>
              <td>{i.invoiceDate}</td>
              <td>
                <LookupValue
                  lookupType={LookupTypes.InvoiceType}
                  lookupId={i.invoiceTypeId}
                />
              </td>
              <td>{i.invoiceNumber}</td>
              <td>{formatAsCurrency(i.total)}</td>
              <td>{i.sendTo}</td>
              <td>{i.sendCc}</td>
              <td>{i.responsibleParty}</td>
              <td>{i.billToAttention}</td>
              <td>
                <UserName userId={i.dataManagerId} />
              </td>
              <td>{i.pmtNumber}</td>
              <td>{i.lienWaiver ? 'Yes' : 'No'}</td>
              <td>{i.needsReimbursableReciepts ? 'Yes' : 'No'}</td>
              <td>{i.notes}</td>
            </tr>
          ))
        }
      </tbody>
    </table>
  );
};

export default InvoiceTable;
