import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { RootState } from '../../app/store';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  clearNewBillingTemplate, initializeNewBillingTemplate, selectProjectById,
} from '../../features/projects/slice';
import { BillingTemplate, Contract, Project } from '../../features/projects/types';
import { BillingTemplateViewModel } from './viewModels';
import { newBillingTemplateViewModel, toBillingTemplateViewModel } from './mapping';

export type ConfigureBillingTemplateHookData = {
  project: Project | undefined;
  contract: Contract | undefined;
  newBillingTemplate: BillingTemplateViewModel | undefined;
  relatedContractIds: number[];
  projectUrl: string;
  onNew: () => void;
  onBack: () => void;
  onFinish: () => void;
};

function buildNewBillingTemplateViewModel(
  newTemplate: BillingTemplate | 'New' | undefined, numberOfExistingTemplates: number,
): BillingTemplateViewModel | undefined {
  if (newTemplate === undefined) return undefined;

  return newTemplate === 'New'
    ? newBillingTemplateViewModel(numberOfExistingTemplates)
    : toBillingTemplateViewModel(newTemplate);
}

export function useConfigureBillingTemplate(): ConfigureBillingTemplateHookData {
  const { projectId, id } = useParams<{ projectId: string, id: string }>();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const newTemplate = useAppSelector(
    (state: RootState) => state.projects.newBillingTemplate,
  );
  const project = useAppSelector(
    (state: RootState) => selectProjectById(state, Number(projectId)),
  );
  const contract = project?.contracts.find((c) => c.id === Number(id));

  const projectUrl = `/projects/${projectId}`;

  const relatedContractIds = React.useMemo(() => {
    if (!project || !contract) return [];

    // If contract doesn't have a billing template - return just it
    if (!contract.billingTemplateId) return [contract.id];

    return project.contracts
      .filter((c) => c.billingTemplateId === contract.billingTemplateId)
      .map((c) => c.id);
  }, [project, contract]);

  // When the screen loads the first time, clear any existing template
  React.useEffect(() => {
    dispatch(clearNewBillingTemplate());
  }, [dispatch]);

  const onNew = React.useCallback(() => dispatch(initializeNewBillingTemplate('New')), [dispatch]);
  const onBack = React.useCallback(() => history.goBack(), [history]);
  const onFinish = React.useCallback(() => history.push(projectUrl), [history, projectUrl]);

  return {
    project,
    contract,
    newBillingTemplate: buildNewBillingTemplateViewModel(
      newTemplate, project?.billingTemplates.length ?? 0,
    ),
    relatedContractIds,
    projectUrl,
    onNew,
    onBack,
    onFinish,
  };
}
