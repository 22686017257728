import React from 'react';
import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import LookupValue from '../lookups/LookupValue';
import { LookupTypes } from '../lookups/types';
import { selectBillingTemplate } from './slice';

type YesNoOrNotSetProps = {
  value: boolean | undefined;
}

function YesNoOrNotSet({ value }: YesNoOrNotSetProps): JSX.Element {
  if (value === undefined) return (<>(not set)</>);

  return (
    <>
      {value ? 'Yes' : 'No'}
    </>
  );
}

type BillingTemplateTableProps = {
  projectId: number;
  templateId: number | null;
}

function BillingTemplateTable(
  { projectId, templateId }: BillingTemplateTableProps,
): JSX.Element {
  const template = useAppSelector(
    (state: RootState) => selectBillingTemplate(state, projectId, templateId),
  );

  return (
    <>
      <dt>Template Name</dt>
      <dd>{template?.name ?? '(not set)'}</dd>
      <dt>Bill To</dt>
      <dd>{template?.billToAttention ?? '(not set)'}</dd>
      <dt>Billing Address</dt>
      <dd>{template?.billingAddress ?? '(not set)'}</dd>
      <dt>Customer PO Number</dt>
      <dd>{template?.customerPONumber ?? '(not set)'}</dd>
      <dt>Do Reimbursables Require Receipts?</dt>
      <dd><YesNoOrNotSet value={template?.includeReceipts} /></dd>
      <dt>Provide Lien Waiver?</dt>
      <dd><YesNoOrNotSet value={template?.provideLienWaiver} /></dd>
      <dt>Invoice Type</dt>
      <dd>
        <LookupValue lookupType={LookupTypes.InvoiceType} lookupId={template?.invoiceTypeId} />
      </dd>
      <dt>FS Send To</dt>
      <dd>{template?.invoiceTo ?? '(not set)'}</dd>
      <dt>FS CC</dt>
      <dd>{template?.invoiceCc ?? '(not set)'}</dd>
      <dt>Notes</dt>
      <dd>{template?.notes ?? '(not set)'}</dd>
    </>
  );
}

export default BillingTemplateTable;
