import React, { useReducer } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectProjectById, updateContract } from '../../features/projects/slice';
import { ContractViewModel } from './viewModels';
import { mergeFromContractViewModel } from './mapping';
import {
  ChangeBillingModeAction, ChangeDatesAction,
  ChangePercentOfContractAction, ChangePhaseAmountAction,
  initialize, reduce,
} from './reducer';

export type ConfigureContractResults = {
  contract: ContractViewModel | undefined;
  currentlyDirty: boolean;
  currentlySaving: boolean;
  updateBillingModeId: (newMode: number | undefined) => void;
  updatePhaseMonths: (
    phaseNameId: number, startMonth: number, startYear: number, endMonth: number, endYear: number,
  ) => void;
  updatePhaseAmount: (phaseNameId: number, newAmount: number) => void;
  updatePhasePercent: (phaseNameId: number, newPercent: number) => void;
  saveAndExitClicked: () => void;
  saveAndContinueClicked: () => void;
};

export function useConfigureContract(): ConfigureContractResults {
  // *** Set up all of the state ***
  const { projectId, id } = useParams<{ projectId: string, id: string }>();
  const history = useHistory();
  const project = useAppSelector(
    (state) => selectProjectById(state, Number(projectId)),
  );
  const contract = project?.contracts.find((c) => c.id === Number(id));
  const globalDispatch = useAppDispatch();

  const [viewModel, localDispatch] = useReducer(
    reduce,
    undefined,
    () => initialize({ contract, project }),
  );

  const [dirty, setDirty] = React.useState<boolean>(false);
  const [saving, setSaving] = React.useState<boolean>(false);

  // *** Generate callbacks ***

  const updateBillingModeId = React.useCallback(
    (billingModeId: number | undefined) => {
      setDirty(true);

      const action: ChangeBillingModeAction = {
        type: 'changeBillingMode',
        payload: billingModeId,
      };
      localDispatch(action);
    },
    [],
  );

  const updatePhaseMonths = React.useCallback(
    (
      phaseNameId: number, startMonth: number, startYear: number, endMonth: number, endYear: number,
    ) => {
      setDirty(true);

      const action: ChangeDatesAction = {
        type: 'changeDates',
        payload: {
          phaseNameId,
          startMonth,
          startYear,
          endMonth,
          endYear,
        },
      };
      localDispatch(action);
    },
    [],
  );

  const updatePhaseAmount = React.useCallback(
    (phaseNameId: number, newAmount: number) => {
      setDirty(true);

      const action: ChangePhaseAmountAction = {
        type: 'changePhaseAmount',
        payload: {
          phaseNameId, newAmount,
        },
      };
      localDispatch(action);
    },
    [],
  );

  const updatePhasePercent = React.useCallback(
    (phaseNameId: number, newPercent: number) => {
      setDirty(true);

      const action: ChangePercentOfContractAction = {
        type: 'changePercentOfContract',
        payload: {
          phaseNameId, newPercent,
        },
      };
      localDispatch(action);
    },
    [],
  );

  const saveClicked = React.useCallback((nextUrl: string) => {
    // If project or contract are unknown, then just exit early
    if (!project || !contract || !viewModel) return;

    setSaving(true);
    const mergedContract = mergeFromContractViewModel(viewModel, contract);
    updateContract(globalDispatch, project, mergedContract)
      .then((success) => {
        setSaving(false);
        setDirty(false);

        if (!success) {
          // eslint-disable-next-line no-alert
          alert('Failed to save the contract.');
        } else {
          history.push(nextUrl);
        }
      })
      .catch(() => {
        // eslint-disable-next-line no-alert
        alert('Failed to save the contract.');
        setSaving(false);
        setDirty(false);
      });
  }, [contract, globalDispatch, history, project, viewModel]);

  const saveAndExitClicked = React.useCallback(
    () => saveClicked(`/projects/${projectId}`),
    [projectId, saveClicked],
  );

  const saveAndContinueClicked = React.useCallback(
    () => saveClicked(`/projects/${projectId}/contracts/${id}/templates`),
    [id, projectId, saveClicked],
  );

  return {
    contract: viewModel,
    currentlyDirty: dirty,
    currentlySaving: saving,
    saveAndExitClicked,
    saveAndContinueClicked,
    updateBillingModeId,
    updatePhaseMonths,
    updatePhaseAmount,
    updatePhasePercent,
  };
}
