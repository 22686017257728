/* eslint-disable jsx-a11y/label-has-associated-control */
// The label > input pattern should be fine based off of:
// https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/label-has-associated-control.md
// However, it is still failing linting, so suppressing for this file.

import React from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import UserOptions from '../users/UserOptions';
import ContractBillingStatusSeletion from '../contractBillingStatusOptions/ContractBillingStatusSelection';
import { dashboardFilterUpdated, dashboardFilterReset } from './slice';

function ProjectsFilter(): JSX.Element {
  const dispatch = useAppDispatch();
  const filter = useAppSelector((state: RootState) => state.projects.dashboardFilter);

  return (
    <div>
      <label htmlFor="filter-data-manager">Data Manager:</label>
      <select
        id="filter-data-manager"
        value={filter.dataManagerId ?? ''}
        onChange={(e) => {
          if (e.target.value) {
            dispatch(dashboardFilterUpdated({ ...filter, dataManagerId: Number(e.target.value) }));
          } else {
            dispatch(dashboardFilterUpdated({ ...filter, dataManagerId: undefined }));
          }
        }}
      >
        <UserOptions />
      </select>
      <ContractBillingStatusSeletion
        selectId="filter-contract-billing-status"
        statusId={filter.contractStatusOptionId}
        labelText="Contract Billing Status:"
        selectionChanged={
          (newId) => dispatch(
            dashboardFilterUpdated({ ...filter, contractStatusOptionId: newId }),
          )
        }
      />
      <label>
        Interior Designer:
        <input
          type="text"
          value={filter.designerName}
          onChange={
            (e) => dispatch(dashboardFilterUpdated({ ...filter, designerName: e.target.value }))
          }
        />
      </label>
      <label>
        PMT #:
        <input
          type="text"
          value={filter.pmtNumber}
          onChange={
            (e) => dispatch(dashboardFilterUpdated({ ...filter, pmtNumber: e.target.value }))
          }
        />
      </label>
      <label>
        Project Name:
        <input
          type="text"
          value={filter.projectName}
          onChange={
            (e) => dispatch(dashboardFilterUpdated({ ...filter, projectName: e.target.value }))
          }
        />
      </label>
      <label>
        <input
          type="checkbox"
          checked={filter.onlyProjectsWithExpectedAmounts}
          onChange={
            (e) => dispatch(
              dashboardFilterUpdated(
                { ...filter, onlyProjectsWithExpectedAmounts: e.target.checked },
              ),
            )
          }
        />
        Expected to invoice
      </label>
      <button
        type="button"
        onClick={() => dispatch(dashboardFilterReset())}
      >
        Reset
      </button>
    </div>
  );
}

export default ProjectsFilter;
