import React from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { loadUsers, selectAllUsers } from './slice';
import { LoadingStatus } from '../LoadingStatus';

function UserOptions(): JSX.Element {
  const usersState = useAppSelector((state) => state.users.loading);
  const users = useAppSelector((state) => selectAllUsers(state));
  const dispatch = useAppDispatch();

  if (usersState === LoadingStatus.Uninitialized) {
    dispatch(loadUsers());
    return (<option value="" disabled>Loading...</option>);
  }
  if (usersState === LoadingStatus.Pending) {
    return (<option value="" disabled>Loading...</option>);
  }
  if (usersState === LoadingStatus.Errored) {
    return (<option value="" disabled>Failed to load users</option>);
  }

  const mappedLookups = (users ?? [])
    .filter((u) => u.isActive) // Only active users
    .map((u) => (<option key={u.id} value={u.id}>{u.fullName}</option>));

  return (
    <>
      <option value="">Select user...</option>
      <option value="0">(no user)</option>
      {mappedLookups}
    </>
  );
}

export default UserOptions;
