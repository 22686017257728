import { createSlice, createEntityAdapter, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../../app/store';
import {
  BillingTemplate,
  Contract,
  ContractCloseRequestUpdate,
  ContractCorrection,
  ContractUpdate,
  DashboardFilter,
  FilterNavigation,
  NewCloseContractRequest,
  PhaseAdjustment,
  PhaseAuthorization,
  Project,
} from './types';
import {
  authorizeAmounts as authorizeAmountsApi,
  deleteAuthorizations as deleteAuthorizationsApi,
  fetchProjects,
  generatePaymentSchedule as generatePaymentScheduleApi,
  linkTemplates as linkTemplatesApi,
  refreshContract,
  saveTemplate as saveTemplateApi,
  submitContractCorrection as submitContractCorrectionApi,
  submitNewCloseContractRequest as submitNewCloseContractRequestApi,
  submitNewPhaseAdjustment,
  updateContract as updateContractApi,
  updateProject as updateProjectApi,
  updateCloseContractRequest,
} from './api';
import { projectPassesFilter } from './utils';
import { LoadingStatus } from '../LoadingStatus';

type ProjectsState = {
  loading: LoadingStatus;
  dashboardFilter: DashboardFilter;
  filteredProjectIds: number[];
  newBillingTemplate: BillingTemplate | 'New' | undefined;
};

const defaultFilter: DashboardFilter = {
  dataManagerId: undefined,
  pmtNumber: '',
  projectName: '',
  contractStatusOptionId: undefined,
  designerName: '',
  onlyProjectsWithExpectedAmounts: false,
};

const initialState: ProjectsState = {
  loading: LoadingStatus.Uninitialized,
  dashboardFilter: defaultFilter,
  filteredProjectIds: [],
  newBillingTemplate: undefined,
};

const projectsAdapter = createEntityAdapter<Project>();

export const projectsSlice = createSlice({
  name: 'projects',
  initialState: projectsAdapter.getInitialState(initialState),
  reducers: {
    projectsLoaded: (state, action) => {
      state.loading = LoadingStatus.Idle;
      projectsAdapter.setAll(state, action);
      state.filteredProjectIds = projectsAdapter
        .getSelectors(() => state)
        .selectAll(state)
        .map((p) => p.id);
    },
    projectsLoading: (state) => {
      state.loading = LoadingStatus.Pending;
    },
    projectsLoadingFailed: (state) => {
      state.loading = LoadingStatus.Errored;
    },
    projectUpdated: projectsAdapter.updateOne,
    dashboardFilterUpdated: (state, action: PayloadAction<DashboardFilter>) => {
      state.dashboardFilter = action.payload;
      state.filteredProjectIds = projectsAdapter
        .getSelectors(() => state)
        .selectAll(state)
        .filter((p) => projectPassesFilter(p, action.payload))
        .map((p) => p.id);
    },
    dashboardFilterReset: (state) => {
      state.dashboardFilter = defaultFilter;
      state.filteredProjectIds = projectsAdapter
        .getSelectors(() => state)
        .selectAll(state)
        .map((p) => p.id);
    },
    initializeNewBillingTemplate: (state, action: PayloadAction<BillingTemplate | 'New'>) => {
      state.newBillingTemplate = action.payload;
    },
    clearNewBillingTemplate: (state) => {
      state.newBillingTemplate = undefined;
    },
  },
});

export const {
  projectsLoaded,
  projectsLoading,
  projectsLoadingFailed,
  projectUpdated,
  dashboardFilterUpdated,
  dashboardFilterReset,
  initializeNewBillingTemplate,
  clearNewBillingTemplate,
} = projectsSlice.actions;

const selectors = projectsAdapter.getSelectors((state: RootState) => state.projects);

export const selectAllProjects = selectors.selectAll;
export const selectProjectEntities = selectors.selectEntities;
export const selectProjectById = selectors.selectById;

export const selectFilterNavigation = (state: RootState, projectId: number): FilterNavigation => {
  const filteredIds = state.projects.filteredProjectIds;
  const index = filteredIds.findIndex((id) => id === projectId);
  const totalCount = filteredIds.length;

  return {
    totalCount,
    currentIndex: (index === -1 ? undefined : index),
    nextId: (index === -1 || index + 1 >= totalCount) ? undefined : filteredIds[index + 1],
    previousId: (index - 1 < 0 ? undefined : filteredIds[index - 1]),
  };
};

export function selectBillingTemplate(
  state: RootState, projectId: number, templateId: number | null,
): BillingTemplate | undefined {
  if (templateId === null) return undefined;

  const project = selectProjectById(state, projectId);
  if (project === undefined) return undefined;

  return project.billingTemplates.find((t) => t.id === templateId);
}

export default projectsSlice.reducer;

export const loadProjects = () => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(projectsLoading());
  try {
    const response = await fetchProjects();
    dispatch(projectsLoaded(response));
  } catch {
    dispatch(projectsLoadingFailed());
  }
};

export const updateProject = async (dispatch: AppDispatch, project: Project): Promise<boolean> => {
  try {
    const response = await updateProjectApi(project);
    // Undefined response, so some issue saving
    if (response === undefined) return false;

    dispatch(projectUpdated({ id: response.id, changes: response }));
    return true;
  } catch {
    return false;
  }
};

export const updateContract = async (
  dispatch: AppDispatch, project: Project, contract: Contract,
): Promise<boolean> => {
  try {
    if (!project || !contract) return false;

    const payload: ContractUpdate = {
      ...contract,
      projectStatusId: project.statusId,
    };

    const response = await updateContractApi(project.id, payload);
    // Undefined response, so some issue saving
    if (response === undefined) return false;

    const updatedProject = { ...project };
    updatedProject.contracts = project.contracts.map((c) => (c.id === response.id ? response : c));

    dispatch(projectUpdated({ id: project.id, changes: updatedProject }));
    return true;
  } catch {
    return false;
  }
};

export const generatePaymentSchedule = async (project: Project): Promise<Blob | undefined> => {
  try {
    const response = await generatePaymentScheduleApi(project);
    // Undefined response, so some issue saving
    if (response === undefined) return undefined;
    return response;
  } catch {
    return undefined;
  }
};

export const saveTemplate = async (
  dispatch: AppDispatch, project: Project, template: BillingTemplate, isNew: boolean,
): Promise<boolean> => {
  try {
    const response = await saveTemplateApi(project.id, template, isNew);

    if (!response) {
      return false;
    }

    let { billingTemplates } = project;

    if (isNew) {
      billingTemplates = [...billingTemplates, response];
    } else {
      billingTemplates = billingTemplates.map((t) => (t.id === response.id ? response : t));
    }

    const copy = {
      ...project,
      billingTemplates,
    };
    dispatch(projectUpdated({ id: copy.id, changes: copy }));

    if (isNew) {
      dispatch(clearNewBillingTemplate());
    }

    return true;
  } catch {
    return false;
  }
};

export const refreshContracts = async (
  dispatch: AppDispatch, project: Project, contractIds: number[],
): Promise<boolean> => {
  const contracts = contractIds.map((id): ContractUpdate | undefined => {
    const contract = project.contracts.find((c) => c.id === id);
    if (!contract) return undefined;

    return {
      ...contract,
      projectStatusId: project.statusId,
    };
  }).filter((c) => c !== undefined) as ContractUpdate[];
  // The `as` is to help the compiler - we shouldn't have any undefined in the array.

  const promises = contracts.map((c) => refreshContract(project.id, c));

  try {
    const updatedContracts = (await Promise.all(promises))
      .filter((c) => c !== undefined) as Contract[];
    // The `as` is to help the compiler - we shouldn't have any undefined in the array.

    // Now swap out the contract data
    const newContractData = project.contracts.map((c) => {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < updatedContracts.length; ++i) {
        if (c.id === updatedContracts[i]?.id) {
          return updatedContracts[i];
        }
      }
      return c;
    });

    const newProjectData = {
      ...project,
      contracts: newContractData,
    };

    dispatch(projectUpdated({ id: project.id, changes: newProjectData }));

    return true;
  } catch {
    return false;
  }
};

export const linkTemplates = async (
  dispatch: AppDispatch, project: Project, templateId: number, contractIds: number[],
): Promise<boolean> => {
  try {
    if (!(await linkTemplatesApi(project.id, templateId, contractIds))) {
      return false;
    }

    return await refreshContracts(dispatch, project, contractIds);
  } catch {
    return false;
  }
};

export const authorizeAmounts = async (
  dispatch: AppDispatch, project: Project, contractId: number, authorizations: PhaseAuthorization[],
): Promise<boolean> => {
  try {
    if (!(await authorizeAmountsApi(project.id, contractId, authorizations))) {
      return false;
    }

    return await refreshContracts(dispatch, project, [contractId]);
  } catch {
    return false;
  }
};

export const deleteAuthorizations = async (
  dispatch: AppDispatch, project: Project, contractId: number, authorizationMonth: string | null,
): Promise<boolean> => {
  try {
    if (!(await deleteAuthorizationsApi(project.id, contractId, authorizationMonth))) {
      return false;
    }

    return await refreshContracts(dispatch, project, [contractId]);
  } catch {
    return false;
  }
};

export const submitContractCorrection = async (
  projectId: number, contractId: number, correction: ContractCorrection,
): Promise<boolean> => {
  try {
    return await submitContractCorrectionApi(projectId, contractId, correction);
  } catch {
    return false;
  }
};

export const submitNewCloseContractRequest = async (
  projectId: number, contractId: number, request: NewCloseContractRequest,
): Promise<boolean> => {
  try {
    return await submitNewCloseContractRequestApi(projectId, contractId, request);
  } catch {
    return false;
  }
};

export const updateCloseRequest = async (
  projectId: number, contractId: number, request: ContractCloseRequestUpdate,
): Promise<boolean> => {
  try {
    return await updateCloseContractRequest(projectId, contractId, request);
  } catch {
    return false;
  }
};

export const submitPhaseAdjustment = async (
  projectId: number, contractId: number, contractPhaseId: number, adjustment: PhaseAdjustment,
): Promise<boolean> => {
  try {
    return await submitNewPhaseAdjustment(projectId, contractId, contractPhaseId, adjustment);
  } catch {
    return false;
  }
};
