import * as React from 'react';
import { Link } from 'react-router-dom';
import FilterdProjectsTable from '../features/projects/FilteredProjectsTable';
import ProjectsFilter from '../features/projects/ProjectsFilter';

function ProjectDashboard(): JSX.Element {
  return (
    <div>
      <div>
        <Link to="/">Home</Link>
      </div>
      <ProjectsFilter />
      <FilterdProjectsTable />
    </div>
  );
}

export default ProjectDashboard;
