export interface User {
  id: number;
  fullName: string;
  singleSignOnId: string;
  dsUserId: number | null;
  isActive: boolean;
  email: string | null;
  roleId: number | null;
}

export enum AuthStatus {
  Unknown = 'unknown',
  Checking = 'checking',
  Authenticated = 'authenticated',
  Unauthenticated = 'unauthenticated',
  Inactive = 'inactive'
}
