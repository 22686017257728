/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Card, Button } from '@dsx/react';
import { useAppDispatch } from '../../app/hooks';
import ContractBillingStatusEmoji from '../../features/contractBillingStatusOptions/ContractBillingStatusEmoji';
import LookupValue from '../../features/lookups/LookupValue';
import { LookupTypes } from '../../features/lookups/types';
import { refreshContracts, submitPhaseAdjustment } from '../../features/projects/slice';
import { Contract, PhaseAdjustment, Project } from '../../features/projects/types';
import { formatAsCurrency } from '../../features/projects/utils';
import displayError from '../../components/displayError';
import {
  initialize, reduce,
} from './reducer';

type DiscrepancyCardProps = {
  project: Project;
  contract: Contract;
}

function DiscrepancyCard({ project, contract }: DiscrepancyCardProps): JSX.Element {
  const transactions = React.useMemo(() => contract.transactions.flatMap(
    (t) => t.invoiceNumbers
      .split(', ')
      .map((i) => ({
        invoiceNumber: i.trim(),
        amount: t.amount,
      })),
  ).map((t) => (
    <option key={t.invoiceNumber} value={t.invoiceNumber}>
      {`${t.invoiceNumber} (${formatAsCurrency(t.amount)})`}
    </option>
  )), [contract]);

  const [state, localDispatch] = React.useReducer(reduce, undefined, initialize);
  const [processing, setProcessing] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();
  const contractBillingRemaining = React.useMemo(() => contract.phases
    .map((c) => c.remainingAmount)
    .reduce((total, remaining) => total + remaining, 0), [contract]);

  if (state === undefined) {
    return (
      <></>
    );
  }

  return (
    <Card variant="outline">
      <div className="discrepancy-card-body">
        <dl className="discrepany-contract-summary">
          <dt>PMT Number</dt>
          <dd>{project.pmtNumber}</dd>
          <dt>Contract Name</dt>
          <dd>{contract.serviceContractName}</dd>
          <dt>PMT Remaining</dt>
          <dd>{formatAsCurrency(contract.amountRemaining)}</dd>
          <dt>Contract Billing Remaining</dt>
          <dd>{formatAsCurrency(contractBillingRemaining)}</dd>
          <dt>Necessary Adjustment Total</dt>
          <dd>{formatAsCurrency(contractBillingRemaining - contract.amountRemaining)}</dd>
          <dt>Contract Status</dt>
          <dd>
            <ContractBillingStatusEmoji id={contract.contractBillingStatusId} />
          </dd>
        </dl>
        <div className="discrepancy-reconciliation">
          <h2>Phases</h2>
          <table>
            <thead>
              <tr>
                <th>Phase Name</th>
                <th>Total</th>
                <th>Remaining</th>
                <th>Adjustment</th>
              </tr>
            </thead>
            <tbody>
              {
                contract.phases.map((p) => (
                  <tr key={p.id}>
                    <td>
                      <LookupValue lookupType={LookupTypes.PhaseName} lookupId={p.phaseNameId} />
                    </td>
                    <td>{formatAsCurrency(p.totalAmount)}</td>
                    <td>{formatAsCurrency(p.remainingAmount)}</td>
                    <td>
                      <input
                        type="number"
                        disabled={processing}
                        value={state.adjustmentAmounts[p.phaseNameId].amount}
                        onChange={(e) => {
                          localDispatch({
                            type: 'changeAdjustmentAmount',
                            payload: {
                              amount: Number(e.target.value),
                              contractPhaseId: p.id,
                              phaseNameId: p.phaseNameId,
                            },
                          });
                        }}
                      />
                    </td>
                  </tr>
                ))
              }
              <tr>
                <td />
                <td />
                <td />
                <td className="bold-text">{state.currentAdjustmentTotal}</td>
              </tr>
            </tbody>
          </table>
          <div>
            <label htmlFor={`adjustment-note-${contract.id}`}>
              Adjustment Note:
            </label>
          </div>
          <textarea
            className="discrepancy-adjustment-note"
            id={`adjustment-note-${contract.id}`}
            disabled={processing}
            value={state.adjustmentNote}
            onChange={(e) => localDispatch({
              type: 'changeAdjustmentNote',
              payload: e.target.value,
            })}
          />
          <div>
            <label>
              Linked Transaction:
              <select
                disabled={processing}
                onChange={(e) => localDispatch({
                  type: 'changeInvoiceNumber',
                  payload: e.target.value,
                })}
                value={state.linkedInvoiceNumber}
              >
                <option value="">Select invoice number...</option>
                {transactions}
              </select>
            </label>
          </div>
          <div>
            <Button
              variant="default"
              disabled={processing || !state.canSubmit}
              onClick={async () => {
                setProcessing(true);
                const promises = state.adjustmentAmounts
                  .filter((a) => a.id !== 0 && a.amount !== 0)
                  .map((a) => {
                    const adjustment: PhaseAdjustment = {
                      amount: a.amount,
                      associatedInvoiceNumber: Number(state.linkedInvoiceNumber),
                      id: a.id,
                      notes: state.adjustmentNote,
                    };
                    return submitPhaseAdjustment(project.id, contract.id, a.id, adjustment);
                  });
                const results = await Promise.all(promises);
                if (results.some((r) => r === false)) {
                  displayError('Not all adjustments were saved. Please refresh and try again.');
                } else if (!(await refreshContracts(dispatch, project, [contract.id]))) {
                  displayError('Failed to refesh the contract. Please refresh to see the latest data.');
                } else {
                  localDispatch({ type: 'clearData' });
                }
                setProcessing(false);
              }}
            >
              Save Adjustments
            </Button>
          </div>
        </div>
      </div>
    </Card>
  );
}

export default DiscrepancyCard;
