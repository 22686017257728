/* eslint-disable react/jsx-props-no-spreading */
// Prop spreading is standard practice with react-hook-forms

import * as React from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { selectUserById, updateUser } from '../features/users/slice';
import { User } from '../features/users/types';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import LookupOptions from '../features/lookups/LookupOptions';
import { LookupTypes } from '../features/lookups/types';

const EditUser: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const user = useAppSelector((state) => selectUserById(state, Number(id)));
  const dispatch = useAppDispatch();
  const { register, handleSubmit, formState } = useForm<User>({
    defaultValues: user,
  });

  const links = (
    <ul className="link-list">
      <li>
        <Link to="/users" className="link">Go back to users</Link>
      </li>
      <li>
        <Link to="/" className="link">Home</Link>
      </li>
    </ul>
  );

  const onSubmit = async (data: User): Promise<void> => {
    updateUser(dispatch, data)
      .then((success) => {
        if (!success) {
          // eslint-disable-next-line no-alert
          alert('Failed to save the user.');
        }

        history.push('/users');
      })
      .catch(() => {
        // eslint-disable-next-line no-alert
        alert('Failed to save the user.');
        history.push('/users');
      });
  };

  if (!user) {
    return (
      <main>
        <h1>
          Can not find user:
          {id}
        </h1>
        <p>
          No user found. Click
          <Link to="/users">here</Link>
          to go back.
        </p>
        {links}
      </main>
    );
  }

  return (
    <main>
      <h1>
        {`Edit ${user.fullName}`}
      </h1>
      {links}
      <form onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" {...register('id', { value: user.id, valueAsNumber: true })} />
        <input type="hidden" {...register('fullName', { value: user.fullName })} />
        <input type="hidden" {...register('singleSignOnId', { value: user.singleSignOnId })} />
        <input type="hidden" {...register('email', { value: user.email })} />

        <dl>
          <dt>Is active?</dt>
          <dd>
            <input type="checkbox" {...register('isActive', { value: user.isActive })} />
          </dd>
          <dt>Single sign on id</dt>
          <dd>{user.singleSignOnId}</dd>
          <dt>DS user id</dt>
          <dd>
            <input type="textbox" {...register('dsUserId', { value: user.dsUserId, valueAsNumber: true })} />
          </dd>
          <dt>Email</dt>
          <dd>{user.email}</dd>
          <dt>Role</dt>
          <dd>
            <select {...register('roleId', { value: user.roleId, valueAsNumber: true })}>
              <LookupOptions lookupType={LookupTypes.UserRole} />
            </select>
          </dd>
        </dl>
        <button
          type="submit"
          disabled={!formState.isDirty || formState.isValid || formState.isSubmitting}
        >
          Save
        </button>
      </form>
    </main>
  );
};

export default EditUser;
