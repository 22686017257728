import { BillingTemplate } from '../../features/projects/types';
import { BillingTemplateViewModel } from './viewModels';

export function newBillingTemplateViewModel(
  numberOfExistingTemplates: number,
): BillingTemplateViewModel {
  return {
    billToAttention: '',
    billingAddress: '',
    customerPONumber: '',
    enabled: true,
    id: 0,
    includeReceipts: '',
    invoiceCc: '',
    invoiceTo: '',
    invoiceTypeId: undefined,
    name: `Template ${numberOfExistingTemplates + 1}`,
    notes: '',
    provideLienWaiver: '',
  };
}

export function toBillingTemplateViewModel(template: BillingTemplate): BillingTemplateViewModel {
  const {
    billToAttention, billingAddress, customerPONumber, enabled, id, includeReceipts,
    invoiceCc, invoiceTo, invoiceTypeId, name, notes, provideLienWaiver,
  } = template;

  return {
    billToAttention,
    billingAddress,
    customerPONumber,
    enabled,
    id,
    includeReceipts: includeReceipts ? '1' : '0',
    invoiceCc,
    invoiceTo,
    invoiceTypeId,
    name,
    notes,
    provideLienWaiver: provideLienWaiver ? '1' : '0',
  };
}

export function fromBillingTemplateViewModel(vm: BillingTemplateViewModel): BillingTemplate {
  const {
    billToAttention, billingAddress, customerPONumber, enabled, id, includeReceipts,
    invoiceCc, invoiceTo, invoiceTypeId, name, notes, provideLienWaiver,
  } = vm;

  return {
    billToAttention,
    billingAddress,
    customerPONumber,
    enabled,
    id,
    includeReceipts: includeReceipts === '1',
    invoiceCc,
    invoiceTo,
    invoiceTypeId: invoiceTypeId ?? 0,
    name,
    notes,
    provideLienWaiver: provideLienWaiver === '1',
  };
}
