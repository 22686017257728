import './ContractDiscrepancies.scss';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { selectAllProjects } from '../../features/projects/slice';
import { Contract, BillingStatuses } from '../../features/projects/types';
import DiscrepancyCard from './DiscrepancyCard';

function contractHasMeaningfulDiscrepancy(contract: Contract): boolean {
  if (!contract.discrepancyInRemainingAmount) {
    return false;
  }

  // Ignore any discrepancies due to authorizations
  if (
    contract.contractBillingStatusId === BillingStatuses.AuthorizedToBill
    || contract.contractBillingStatusId === BillingStatuses.SentToBPO
  ) {
    return false;
  }

  return true;
}

const Placeholder: React.FC = () => {
  const allProjects = useAppSelector((state) => selectAllProjects(state));

  const contractsWithDiscrepancies = allProjects
    .flatMap((p) => p.contracts.map((c) => ({ project: p, contract: c })))
    .filter((c) => contractHasMeaningfulDiscrepancy(c.contract))
    .sort((a, b) => a.project.id - b.project.id);

  return (
    <main className="discrepancies">
      <header className="discrepancies-header">
        <h1 className="center-align-text discrepancies-title">Contracts with Discrepancies</h1>
        <nav>
          <ul className="link-list">
            <li className="link"><Link to="/">Home</Link></li>
          </ul>
        </nav>
      </header>
      <section className="discrepancies-cards">
        {
          contractsWithDiscrepancies.map((d) => (
            <DiscrepancyCard key={d.contract.id} contract={d.contract} project={d.project} />
          ))
        }
      </section>
    </main>
  );
};

export default Placeholder;
