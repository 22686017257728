export interface AuthorizedContract {
  contractId: number;
  authorizedTotal: number;
  invoiceDescription: string;
  isReimbursable: boolean;
  isChangeOrder: boolean;
  responsibleParty: string;
}

export interface BillingRequest {
  id: number;
  projectId: number;
  billingTemplateId: number;
  statusTypeId: number;
  invoiceNumber: number | null;
  submittedBy: number;
  submittedOn: string;
  invoicedBy: number | null;
  invoicedOn: string | null;
  completedBy: number | null;
  completedOn: string | null;
  authorizationIds: number[];
  needsReceipts: boolean;
  authorizedContracts: AuthorizedContract[];
}

export type BillingRequestBuckets = {
  completedWithReceiptsCount: number;
  onHoldWithReceipts: number;
  readyWithReceipts: number[];
  needReceipts: number[];
  onHoldWithoutReceipts: number;
  completedWithoutReceiptsCount: number;
  readyWithoutReceipts: number[];
}

export interface BillingRequestNavigation {
  totalCount: number;
  currentIndex: number | undefined;
  nextId: number | undefined;
  previousId: number | undefined;
}

export interface BilllingRequestUpdate {
  id: number;
  invoiceNumber: number | null;
  blocked: boolean;
}

export interface ContractInvoiceDescriptionOverride {
  contractId: number;
  invoiceLineItemDescription: string;
}

export interface NewBillingRequest {
  projectId: number;
  authorizationMonth: string | null;
  descriptionOverrides: ContractInvoiceDescriptionOverride[];
}

export interface ReceiptContract {
  id: number;
  name: string;
  serviceContractDescription: string;
}

export interface ReceiptTemplateRequest {
  billingRequestId: number;
  projectName: string;
  contracts: ReceiptContract[];
}

export enum Statuses {
  ReadyToInvoice = 1,
  NeedsReceipts = 2,
  Complete = 3,
  OnHold = 4,
}

export interface ContractCorpLocation {
  contractId: number;
  corpLocation: string;
}

export interface CustomerPOVerificationRequest {
  projectId: number;
  contractCorpLocations: ContractCorpLocation[];
}

export interface CustomerPOVerificationResult {
  valid: boolean;
  contractId: number;
}
