import React from 'react';
import ContractBillingStatusOptions from './ContractBillingStatusOptions';

type ContractBillingStatusSelectionProps = {
  selectId: string;
  statusId: number | undefined;
  labelText: string;
  selectionChanged: (statusId: number | undefined) => void;
  // eslint-disable-next-line react/require-default-props
  disabled?: boolean;
};

function ContractBillingStatusSelection({
  selectId, statusId, labelText, selectionChanged, disabled,
}: ContractBillingStatusSelectionProps): JSX.Element {
  return (
    <>
      <label htmlFor={selectId}>
        {labelText}
      </label>
      <select
        id={selectId}
        value={statusId ?? 0}
        disabled={disabled}
        onChange={(e) => {
          if (e.target.value) {
            selectionChanged(Number(e.target.value));
          } else {
            selectionChanged(undefined);
          }
        }}
      >
        <ContractBillingStatusOptions />
      </select>
    </>
  );
}

export default ContractBillingStatusSelection;
