import { BillingRequest } from '../../features/billingRequests/types';
import { BillingTemplate, Project } from '../../features/projects/types';
import { InvoiceDetailsViewModel } from './viewModels';

export function isInvoicedOnOrAfter(
  details: InvoiceDetailsViewModel, date: Date, invoiceType: string,
): boolean {
  // If we have an invoice type (defined and not NaN), make sure the invoice matches it
  const typeId = Number(invoiceType);
  if (
    invoiceType !== ''
    && !Number.isNaN(typeId)
    && typeId !== details.invoiceTypeId
  ) {
    return false;
  }

  const invoiceDate = new Date(details.invoiceDate);
  return invoiceDate.getTime() >= date.getTime();
}

export function findRequestsInvoicedOnOrAfter(
  details: InvoiceDetailsViewModel[], date: string, invoiceType: string,
): InvoiceDetailsViewModel[] {
  const checkDate = new Date(date);
  return details
    .filter((d) => isInvoicedOnOrAfter(d, checkDate, invoiceType))
    .sort((a, b) => {
      if (a.invoiceDate === b.invoiceDate) {
        return (a.invoiceNumber ?? 0) - (b.invoiceNumber ?? 0);
      }

      const bInvoicedOn = new Date(b.invoiceDate).getTime();
      const aInvoicedOn = new Date(a.invoiceDate).getTime();

      return aInvoicedOn - bInvoicedOn;
    });
}

function marryData(
  request: BillingRequest, allProjects: Project[],
): InvoiceDetailsViewModel {
  const project = allProjects.find((p) => p.id === request.projectId);
  const billingTemplate = project?.billingTemplates.find((t) => t.id === request.billingTemplateId);
  const responsibleParty = request.authorizedContracts.length > 0
    ? request.authorizedContracts[0].responsibleParty
    : '';
  const hasReimbursables = request.authorizedContracts.some((c) => c.isReimbursable);
  const needsReimbursableReciepts = hasReimbursables
    && (billingTemplate?.includeReceipts ?? false);

  return {
    billToAttention: billingTemplate?.billToAttention ?? '',
    dataManagerId: project?.dataManagerId ?? 0,
    invoiceDate: request.invoicedOn ?? '',
    invoiceNumber: request.invoiceNumber ?? 0,
    invoiceTypeId: billingTemplate?.invoiceTypeId ?? 0,
    lienWaiver: billingTemplate?.provideLienWaiver ?? false,
    needsReimbursableReciepts,
    notes: billingTemplate?.notes ?? '',
    pmtNumber: project?.pmtNumber ?? 0,
    responsibleParty,
    sendCc: billingTemplate?.invoiceCc ?? '',
    sendTo: billingTemplate?.invoiceTo ?? '',
    fromContractBilling: true,
    total: request.authorizedContracts.reduce((agg, c) => agg + c.authorizedTotal, 0),
  };
}

export function buildPsuedoInvoicesFromProject(
  project: Project, invoiceDate: string,
): InvoiceDetailsViewModel[] {
  const date = new Date(invoiceDate).getTime();
  const billingTemplatesById = project.billingTemplates.reduce(
    (agg, bt) => agg.set(bt.id, bt), new Map<number, BillingTemplate>(),
  );
  return project.contracts
    .map((c) => ({ contract: c, template: billingTemplatesById.get(c.billingTemplateId ?? 0) }))
    .filter((c) => c.template !== undefined)
    .flatMap(
      ({ contract: c, template }) => c.transactions
        .filter((t) => (new Date(`${t.monthRecorded}/${t.dayRecorded}/${t.yearRecorded}`)).getTime() >= date)
        .map((t) => ({ transaction: t, template, respParty: c.responsibleParty })),
    )
    .flatMap(({ transaction: t, template, respParty }) => t.invoiceNumbers
      .split(', ').map((i) => ({
        billToAttention: template?.billToAttention ?? '',
        dataManagerId: project.dataManagerId,
        invoiceDate: `${t.monthRecorded}/${t.dayRecorded}/${t.yearRecorded}`,
        invoiceNumber: Number(i),
        invoiceTypeId: template?.invoiceTypeId ?? 0,
        lienWaiver: template?.provideLienWaiver ?? false,
        needsReimbursableReciepts: template?.includeReceipts ?? false,
        notes: template?.notes ?? '',
        pmtNumber: project.pmtNumber,
        responsibleParty: respParty,
        sendCc: template?.invoiceCc ?? '',
        sendTo: template?.invoiceTo ?? '',
        fromContractBilling: false,
        total: t.amount,
      })));
}

export function buildInvoiceData(
  allRequests: BillingRequest[], allProjects: Project[],
  invoiceDate: string | null, invoiceType: string,
): InvoiceDetailsViewModel[] | null {
  if (!invoiceDate) {
    return null;
  }

  const invoiceDetails = allRequests.map((r) => marryData(r, allProjects));
  const pseudoInvoiceDetails = allProjects.flatMap(
    (p) => buildPsuedoInvoicesFromProject(p, invoiceDate),
  ).filter(
    // Filter out any pseudo-invoice details that have a contract billing record to avoid duplicates
    (p) => invoiceDetails.find((i) => i.invoiceNumber === p.invoiceNumber) === undefined,
  );
  const combined = [
    ...invoiceDetails,
    ...pseudoInvoiceDetails,
  ];
  return findRequestsInvoicedOnOrAfter(combined, invoiceDate, invoiceType);
}
