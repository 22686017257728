import * as React from 'react';
import { Link } from 'react-router-dom';
import { selectAllUsers } from '../features/users/slice';
import { User } from '../features/users/types';
import { useAppSelector } from '../app/hooks';

function buildLinkText(user: User): string {
  const disabled = user.isActive ? '' : ' (disabled)';
  const missingDsId = user.dsUserId ? '' : ' (missing DS User Id)';
  return `${user.fullName}${disabled}${missingDsId}`;
}

const ListUsers: React.FC = () => {
  const users = useAppSelector((state) => selectAllUsers(state));

  return (
    <main>
      <header>
        <h1 className="center-align-text unsent-auths-title">Select User</h1>
        <nav>
          <ul className="link-list">
            <li className="link"><Link to="/">Home</Link></li>
          </ul>
        </nav>
      </header>
      <ul>
        {
          users.map((u) => (
            <li key={u.id}>
              <Link to={`/users/${u.id}/edit`}>{buildLinkText(u)}</Link>
            </li>
          ))
        }
      </ul>
    </main>
  );
};

export default ListUsers;
