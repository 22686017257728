import * as React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Authenticated from './views/Authenticated';
import ConfigureContract from './views/ConfigureContract/ConfigureContract';
import LoadingScreen from './views/LoadingScreen';
import ProjectDashboard from './views/ProjectDashboard';
import ProjectDetails from './views/ProjectDetails';
import EditUser from './views/EditUser';
import ListUsers from './views/ListUsers';
import ConfigureBillingTemplate from './views/ConfigureBillingTemplate/ConfigureBillingTemplate';
import VerifyContract from './views/VerifyContract';
import LandingPage from './views/LandingPage';
import BillingRequestDetails from './views/BillingRequestDetails/BillingRequestDetails';
import UnsentAuthorizations from './views/UnsentAuthorizations/UnsentAuthorizations';
import InvoicedSince from './views/InvoicedSince/InvoicedSince';
import ContractDiscrepancies from './views/ContractDiscrepancies/ContractDiscrepancies';
import SendToBpo from './views/SendToBpo/SendToBpo';

const App: React.FC = () => (
  <Router>
    <Authenticated>
      <LoadingScreen>
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <Route exact path="/dashboard" component={ProjectDashboard} />
          <Route exact path="/projects/:projectId" component={ProjectDetails} />
          <Route exact path="/projects/:projectId/contracts/:id/verify" component={VerifyContract} />
          <Route exact path="/projects/:projectId/contracts/:id/phases" component={ConfigureContract} />
          <Route exact path="/projects/:projectId/contracts/:id/templates" component={ConfigureBillingTemplate} />
          <Route exact path="/projects/:projectId/sendToBpo" component={SendToBpo} />
          <Route exact path="/users" component={ListUsers} />
          <Route exact path="/users/:id/edit" component={EditUser} />
          <Route exact path="/billing/:requestId" component={BillingRequestDetails} />
          <Route exact path="/authorizations/unsent" component={UnsentAuthorizations} />
          <Route exact path="/invoices" component={InvoicedSince} />
          <Route exact path="/discrepancies" component={ContractDiscrepancies} />
        </Switch>
      </LoadingScreen>
    </Authenticated>
  </Router>
);

export default App;
