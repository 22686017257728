import { ContractBillingStatusOption } from './types';

type Option = ContractBillingStatusOption | undefined;

function pickHigher(o1: Option, o2: Option): Option {
  // Priority is based off of lowest sort order.
  // Sort 1 is a higher priority than 2, and 2 is higher than 3.

  // Skip any missing or disabled values (i.e., return previous)
  if (!o2 || !o2.enabled) return o1;

  // Use MAX_VALUE so that undefined values have the highest number (i.e., lowest priority)
  if (o2.sortOrder < (o1?.sortOrder ?? Number.MAX_VALUE)) return o2;

  return o1;
}

export function determineHighestPriority(options: Option[]): Option {
  return options.reduce((prev, curr) => pickHigher(prev, curr), undefined);
}

export function determineDistinctAppliedTypes(ids: number[]): number[] {
  const distinctOptions: number[] = [];
  for (let i = 0; i < ids.length; i += 1) {
    if (distinctOptions.indexOf(ids[i]) === -1) {
      distinctOptions.push(ids[i]);
    }
  }
  return distinctOptions.sort();
}
