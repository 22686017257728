export interface PhaseAuthorization {
  phaseId: number;
  amount: number;
}

export interface ContractPhaseAuthorization {
  id: number;
  amount: number;
  sentToBpo: boolean;
  invoiced: boolean;
}

export interface BillingTemplate {
  id: number;
  name: string;
  enabled: boolean;
  billToAttention: string;
  billingAddress: string;
  includeReceipts: boolean;
  provideLienWaiver: boolean;
  customerPONumber: string;
  invoiceTypeId: number;
  invoiceTo: string;
  invoiceCc: string;
  notes: string;
}

export interface Transaction {
  amount: number;
  dayRecorded: number;
  monthRecorded: number;
  yearRecorded: number;
  orderNumbers: string;
  invoiceNumbers: string;
}

export interface ContractPhase {
  id: number;
  phaseNameId: number;
  totalAmount: number;
  remainingAmount: number;
  expectedCurrentMonth: number | null;
  currentMonthAuthorization: ContractPhaseAuthorization | null;
  startMonth: number;
  startYear: number;
  endMonth: number;
  endYear: number;
}

export interface ContractCloseRequest {
  id: number;
  statusTypeId: number;
  // Not pulling the note information, since it isn't used anywhere
}

export interface Contract {
  id: number;
  serviceContractName: string;
  serviceContractId: number;
  serviceComponentId: number;
  serviceContractTypeId: number;
  serviceContractStatusId: number;
  serviceComponentTypeId: number;
  serviceComponentStatusId: number;
  responsibleParty: string;
  responsiblePartyCorpLocation: string;
  serviceLocation: string;
  serviceAddress: string;
  totalAmount: number;
  expectedCurrentMonth: number | null;
  authorizedCurrentMonth: number;
  billingModeId: number | null;
  billingTemplateId: number | null;
  contractBillingStatusId: number;
  phases: ContractPhase[];
  transactions: Transaction[];
  discrepancyInRemainingAmount: boolean;
  amountRemaining: number;
  dateEntered: string;
  serviceComponentDescription: string;
  invoiceDescription: string;
  isReimbursable: boolean;
  isChangeOrder: boolean;
  closeRequest: ContractCloseRequest | null;
  serviceContractDescription: string;
}

export interface ContractUpdate extends Contract {
  projectStatusId: number;
}

export interface Project {
  id: number;
  pmtNumber: number;
  name: string;
  typeId: number;
  statusId: number;
  ownerDepartmentId: number;
  dataManagerId: number;
  billingAddresses: string[];
  masterAccount: string;
  contracts: Contract[];
  notes: string;
  designers: string[];
  capitalSalesConsultants: string[];
  responsibleParties: string[];
  customerContacts: string[];
  billingTemplates: BillingTemplate[];
  professionalServicesProvidedInfoSheet: string;
  invoiceRequirementsSheet: string;
  userDefinedInvoicingEnabled: boolean;
  doNotSendAllowed: number;
}

export type DashboardFilter = {
  dataManagerId: number | undefined;
  pmtNumber: string;
  projectName: string;
  contractStatusOptionId: number | undefined;
  designerName: string;
  onlyProjectsWithExpectedAmounts: boolean;
};

export enum BillingModes {
  FlatBill = 1,
  ByPhase = 2
}

export enum PhaseNames {
  ConceptualAndSchematicDesign = 1,
  DesignDevelopment = 2,
  ConstructionDocuments = 3,
  FFandEDesign = 4,
  ContractAdministration = 5,
  FlatBill = 6
}

export enum BillingStatuses {
  VerifyContract = 1,
  NeedsAuthorization = 2,
  AuthorizedToBill = 3,
  Billed = 4,
  OnHold = 5,
  CloseRequested = 6,
  ApprovedToClose = 7,
  Closed = 8,
  CompletelyBilled = 9,
  NoAction = 10,
  SentToBPO = 11,
}

export enum InvoiceTypes {
  Standard = 1,
  Aptura360 = 2,
  Other = 3,
}

export enum ApprovalItemStatuses {
  NotSet = 0,
  No = 1,
  Yes = 2,
}

export enum ContractStatuses {
  Pending = 1,
  Approved = 2,
  Closed = 3,
  Rejected = 4,
  New = 5,
  Canceled = 6
}

export enum CloseRequestStatuses {
  Requested = 1,
  Approved = 2,
  Denied = 3,
}

export interface ContractCorrection {
  pmtNumber: number;
  serviceContractName: string;
  dataManagerId: number;
  newContractTotal: number | null;
  newResponsibleParty: string | null;
  newContractTypeName: string | null;
  newServiceComponentTypeName: string | null;
  newServiceLocation: string | null;
}

export interface FilterNavigation {
  totalCount: number;
  currentIndex: number | undefined;
  nextId: number | undefined;
  previousId: number | undefined;
}

export interface NewCloseContractRequest {
  projectName: string;
  contractName: string;
  contractTypeName: string;
  serviceComponentTypeName: string;
  amountRemainingOnContract: number;
  notes: string;
}

export interface ContractCloseRequestUpdate extends NewCloseContractRequest {
  closeRequestId: number;
  statusId: number;
  dataManagerId: number;
}

export interface UnsentAuthorization {
  projectId: number;
  pmtNumber: number;
  contractId: number;
  whoAuthorized: number;
  whenAuthorized: string;
  authorizedMonth: string;
  authorizedAmount: number;
}

export interface PhaseAdjustment {
  id: number; // Not actually part of the model, but useful to track data
  amount: number;
  notes: string;
  associatedInvoiceNumber: number;
}
