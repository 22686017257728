import './SendToBpo.scss';
import * as React from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Button } from '@dsx/react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import displayError from '../../components/displayError';
import { sendBillingRequestsToBpo, verifyCustomerPurchaseOrderNumbers } from '../../features/billingRequests/slice';
import { refreshContracts, selectProjectById } from '../../features/projects/slice';
import { BillingStatuses } from '../../features/projects/types';
import { formatAsCurrency } from '../../features/projects/utils';
import { initialize, reduce } from './reducer';

const SendToBpo: React.FC = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const history = useHistory();
  const project = useAppSelector((state) => selectProjectById(state, projectId));
  const dispatch = useAppDispatch();
  const [state, localDispatch] = React.useReducer(reduce, project, initialize);
  const [sending, setSending] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (project === undefined) {
      return;
    }

    const corpMappings = project.contracts
      .filter((c) => c.contractBillingStatusId === BillingStatuses.AuthorizedToBill)
      .map((c) => ({
        contractId: c.id,
        corpLocation: c.responsiblePartyCorpLocation,
      }));

    if (corpMappings.length === 0) {
      return;
    }

    verifyCustomerPurchaseOrderNumbers(project.id, corpMappings)
      .then((response) => {
        if (!response) {
          displayError('Failed to verify the customer PO numbers.');
          localDispatch({ type: 'changeContractVerificationStatus', payload: [] });
        } else {
          localDispatch({ type: 'changeContractVerificationStatus', payload: response });
        }
      })
      .catch(() => {
        displayError('Failed to verify the customer PO numbers.');
        localDispatch({ type: 'changeContractVerificationStatus', payload: [] });
      });
  }, [project]);

  if (project === undefined || state === undefined) {
    return (
      <div>
        <div className="error-msg">
          {`Invalid project id: ${projectId}`}
        </div>
        <div>
          <Link to="/dashboard">Go back to dashboard</Link>
        </div>
      </div>
    );
  }

  return (
    <main>
      <header className="send-to-bpo-header">
        <h1 className="send-to-bpo-header-title">
          {state.title}
        </h1>
        <nav>
          <ul className="link-list">
            <li className="link"><Link to="/dashboard">Dashboard</Link></li>
            <li className="link"><Link to={`/projects/${projectId}`}>Back to Project</Link></li>
          </ul>
        </nav>
      </header>
      <div className="red-text center-align-text bold-text">
        {state.unauthedMessage}
      </div>
      <table className="no-top-border">
        <thead>
          <tr>
            <th>Contract Name</th>
            <th>Total</th>
            <th>Authorized Amount</th>
            <th className="description-column">Description</th>
            <th className="description-column">Override</th>
            <th>Customer PO # Valid?</th>
          </tr>
        </thead>
        <tbody>
          {
            state.authorizedContracts.map((c) => (
              <tr key={c.id}>
                <td>{c.contractName}</td>
                <td className="right-align-text">
                  {formatAsCurrency(c.total)}
                </td>
                <td className="right-align-text">
                  {formatAsCurrency(c.authorizedAmount)}
                </td>
                <td>{c.description}</td>
                <td>
                  <textarea
                    className="override-text"
                    value={c.override}
                    onChange={(e) => localDispatch({ type: 'changeOverride', payload: { id: c.id, override: e.target.value } })}
                  />
                </td>
                <td className="center-align-text">
                  {c.customerPONumberValidMessage}
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
      <div className="flex-row-reverse">
        <Button
          variant="primary"
          disabled={state.authorizedContracts.length === 0 || !state.canSubmit || sending}
          onClick={() => {
            setSending(true);
            const overrides = state.authorizedContracts
              .filter((c) => c.override !== '')
              .map(
                (c) => ({ contractId: c.id, invoiceLineItemDescription: c.override }),
              );
            sendBillingRequestsToBpo(dispatch, project.id, null, overrides)
              .then((results) => {
                if (!results) {
                  displayError('Failed to submit billing requests to BPO.');
                  setSending(false);
                } else {
                  refreshContracts(dispatch, project, state.authorizedContracts.map((c) => c.id))
                    .then((success) => {
                      if (!success) {
                        displayError('Sent authorizations to BPO, but failed to refresh the page. Please refresh your browser.');
                      } else {
                        history.push(`/projects/${projectId}`);
                      }
                    })
                    .catch(() => {
                      displayError('Sent authorizations to BPO, but failed to refresh the page. Please refresh your browser.');
                    })
                    .finally(() => {
                      setSending(false);
                    });
                }
              })
              .catch(() => {
                displayError('Failed to submit billing requests to BPO.');
                setSending(false);
              });
          }}
        >
          Send to BPO
        </Button>
      </div>
    </main>
  );
};

export default SendToBpo;
