import { CustomerPOVerificationResult } from '../../features/billingRequests/types';
import { BillingStatuses, Contract, Project } from '../../features/projects/types';

export interface ContractViewModel {
  id: number;
  contractName: string;
  total: number;
  authorizedAmount: number;
  description: string;
  override: string;
  corpLocation: string;
  customerPONumberValid: boolean | undefined;
  customerPONumberValidMessage: string;
}

export interface ProjectViewModel {
  title: string;
  unauthedMessage: string;
  authorizedContracts: ContractViewModel[];
  canSubmit: boolean;
}

type ContractBuckets = {
  authorizedContracts: ContractViewModel[];
  unauthorizedCount: number;
}

const initialBuckets: ContractBuckets = { authorizedContracts: [], unauthorizedCount: 0 };

function groupContract(buckets: ContractBuckets, c: Contract): ContractBuckets {
  if (
    c.contractBillingStatusId === BillingStatuses.VerifyContract
    || c.contractBillingStatusId === BillingStatuses.NeedsAuthorization
  ) {
    return {
      authorizedContracts: buckets.authorizedContracts,
      unauthorizedCount: buckets.unauthorizedCount + 1,
    };
  }

  if (c.contractBillingStatusId === BillingStatuses.AuthorizedToBill) {
    const description = c.isChangeOrder || c.isReimbursable
      ? c.serviceComponentDescription
      : c.invoiceDescription;

    return {
      authorizedContracts: [
        ...buckets.authorizedContracts,
        {
          id: c.id,
          authorizedAmount: c.authorizedCurrentMonth,
          contractName: c.serviceContractName,
          corpLocation: c.responsiblePartyCorpLocation,
          customerPONumberValid: undefined,
          customerPONumberValidMessage: 'Checking...',
          description,
          override: '',
          total: c.totalAmount,
        },
      ],
      unauthorizedCount: buckets.unauthorizedCount,
    };
  }

  return buckets;
}

function buildAuthorizationMessage(authedCount: number, unauthedCount: number): string {
  if (unauthedCount === 0) return '';

  return `You are about to send ${authedCount} contract(s) to BPO for processing. `
    + `There are ${unauthedCount} other contract(s) for this project that have not been authorized. `
    + 'If the other contract(s) should be sent, please go back to the project and authorize before sending to BPO.';
}

export function initialize(project: Project | undefined): ProjectViewModel | undefined {
  if (project === undefined) {
    return undefined;
  }

  const {
    authorizedContracts, unauthorizedCount,
  } = project.contracts.reduce(groupContract, initialBuckets);

  return {
    title: `${project.pmtNumber} -- ${project.name}`,
    authorizedContracts,
    unauthedMessage: buildAuthorizationMessage(authorizedContracts.length, unauthorizedCount),
    canSubmit: false,
  };
}

export interface ChangeOverride {
  type: 'changeOverride';
  payload: {
    id: number;
    override: string;
  };
}

export interface ChangeContractVerificationStatus {
  type: 'changeContractVerificationStatus',
  payload: CustomerPOVerificationResult[],
}

type Action = ChangeOverride | ChangeContractVerificationStatus;

function updateOverride(
  state: ProjectViewModel, contractId: number, override: string,
): ProjectViewModel {
  const contracts = state.authorizedContracts.map((c) => {
    if (c.id !== contractId) return c;

    return {
      ...c,
      override,
    };
  });
  return {
    ...state,
    authorizedContracts: contracts,
  };
}

function updateVerificationStatus(
  state: ProjectViewModel, payload: CustomerPOVerificationResult[],
): ProjectViewModel {
  const contractCopy = [...state.authorizedContracts];

  // First, flip all of verifications for the contracts we can find
  payload.forEach((r) => {
    const contract = contractCopy.find((c) => c.id === r.contractId);
    if (contract) {
      contract.customerPONumberValid = r.valid;
      contract.customerPONumberValidMessage = r.valid ? 'Yes' : 'No';
    }
  });

  // Next, update the message for any that are still undefined
  const unset = contractCopy.filter((c) => c.customerPONumberValid === undefined);
  unset.forEach((c) => {
    // eslint-disable-next-line no-param-reassign
    c.customerPONumberValidMessage = 'Unknown';
  });

  // Next, figure out if any are invalid
  const invalid = contractCopy.find((c) => c.customerPONumberValid === false);

  return {
    ...state,
    canSubmit: !invalid,
    authorizedContracts: contractCopy,
  };
}

export function reduce(
  state: ProjectViewModel | undefined, action: Action,
): ProjectViewModel | undefined {
  if (state === undefined) return state;

  switch (action.type) {
    case 'changeOverride':
      return updateOverride(state, action.payload.id, action.payload.override);
    case 'changeContractVerificationStatus':
      return updateVerificationStatus(state, action.payload);
    default:
      return state;
  }
}
