import './ConfigureContract.scss';
import * as React from 'react';
import { Link, Prompt } from 'react-router-dom';
import { Button } from '@dsx/react';
import { formatAsCurrency, formatAsPercentage } from '../../features/projects/utils';
import LookupSelection from '../../features/lookups/LookupSelection';
import LookupValue from '../../features/lookups/LookupValue';
import { LookupTypes } from '../../features/lookups/types';
import { useConfigureContract } from './hooks';
import { ContractPhaseViewModel, InvalidReasons } from './viewModels';
import { determineCurrentMonthAbbreviation } from '../../features/monthUtils';

type PhaseRowProps = {
  phase: ContractPhaseViewModel;
  updatePhaseMonths: (
    phaseNameId: number, startMonth: number, startYear: number, endMonth: number, endYear: number,
  ) => void;
  updatePhaseAmount: (phaseNameId: number, newAmount: number) => void;
  updatePhasePercent: (phaseNameId: number, newPercent: number) => void;
}

function PhaseRow({
  phase, updatePhaseMonths, updatePhaseAmount, updatePhasePercent,
}: PhaseRowProps): JSX.Element {
  return (
    <tr className={phase.invalid ? 'invalid' : ''}>
      <td>
        <LookupValue lookupType={LookupTypes.PhaseName} lookupId={phase.phaseNameId} />
      </td>
      <td>
        <select
          value={phase.startMonth}
          disabled={!phase.canModifyStartMonth}
          onChange={
            (e) => updatePhaseMonths(
              phase.phaseNameId,
              Number(e.target.value), phase.startYear,
              phase.endMonth, phase.endYear,
            )
          }
        >
          <option value="1">Jan</option>
          <option value="2">Feb</option>
          <option value="3">Mar</option>
          <option value="4">Apr</option>
          <option value="5">May</option>
          <option value="6">Jun</option>
          <option value="7">Jul</option>
          <option value="8">Aug</option>
          <option value="9">Sep</option>
          <option value="10">Oct</option>
          <option value="11">Nov</option>
          <option value="12">Dec</option>
        </select>
      </td>
      <td>
        <input
          type="number"
          value={phase.startYear}
          disabled={!phase.canModifyStartMonth}
          onChange={
            (e) => updatePhaseMonths(
              phase.phaseNameId,
              phase.startMonth, Number(e.target.value),
              phase.endMonth, phase.endYear,
            )
          }
        />
      </td>
      <td>
        <select
          value={phase.endMonth}
          disabled={!phase.canModifyEndMonth}
          onChange={
            (e) => updatePhaseMonths(
              phase.phaseNameId,
              phase.startMonth, phase.startYear,
              Number(e.target.value), phase.endYear,
            )
          }
        >
          <option value="1">Jan</option>
          <option value="2">Feb</option>
          <option value="3">Mar</option>
          <option value="4">Apr</option>
          <option value="5">May</option>
          <option value="6">Jun</option>
          <option value="7">Jul</option>
          <option value="8">Aug</option>
          <option value="9">Sep</option>
          <option value="10">Oct</option>
          <option value="11">Nov</option>
          <option value="12">Dec</option>
        </select>
      </td>
      <td>
        <input
          type="number"
          value={phase.endYear}
          disabled={!phase.canModifyEndMonth}
          onChange={
            (e) => updatePhaseMonths(
              phase.phaseNameId,
              phase.startMonth, phase.startYear,
              phase.endMonth, Number(e.target.value),
            )
          }
        />
      </td>
      <td>
        <input
          type="number"
          value={phase.phaseAmount}
          disabled={!phase.canModifyPhaseAmount}
          className="right-align-text"
          onChange={
            (e) => updatePhaseAmount(phase.phaseNameId, Number(e.target.value))
          }
        />
      </td>
      <td>
        <input
          type="number"
          value={phase.percentOfContract}
          disabled={!phase.canModifyPhaseAmount}
          className="right-align-text"
          onChange={
            (e) => updatePhasePercent(phase.phaseNameId, Number(e.target.value))
          }
        />
      </td>
      <td className="right-align-text">{formatAsCurrency(phase.remainingAmount)}</td>
      <td className="right-align-text">{formatAsCurrency(phase.amountPerMonth)}</td>
      <td className="right-align-text">
        {formatAsCurrency(phase.activeThisMonth ? phase.amountPerMonth : 0)}
      </td>
    </tr>
  );
}

function convertInvalidReasonToMessage(reason: InvalidReasons): string {
  switch (reason) {
    case InvalidReasons.NoBillingMode: return 'No billing mode set for the contract.';
    case InvalidReasons.InvalidDates: return 'End date is before the start date.';
    case InvalidReasons.PhasesDoNotAddToContract: return 'Phase totals do not add up to the contract total.';
    case InvalidReasons.InvalidAmounts: return 'Invalid phase amount or percent of contract.';
    default: return '???';
  }
}

const ConfigureContract: React.FC = () => {
  const {
    contract,
    saveAndExitClicked,
    saveAndContinueClicked,
    currentlyDirty,
    currentlySaving,
    updateBillingModeId,
    updatePhaseAmount,
    updatePhaseMonths,
    updatePhasePercent,
  } = useConfigureContract();

  if (!contract) {
    return (
      <main className="configure-contract">
        <div className="error-msg">
          The contract you are looking for cannot be found.
        </div>
        <div>
          <Link to="/dashboard">Go back to dashboard</Link>
        </div>
      </main>
    );
  }

  const validationMessages = contract.brokenValidations.length > 0
    ? (
      <ul className="validation-messages">
        {
        contract.brokenValidations.map((r) => (<li key={r}>{convertInvalidReasonToMessage(r)}</li>))
        }
      </ul>
    )
    : <></>;

  return (
    <main className="configure-contract">
      <Prompt when={currentlyDirty} message="You are leaving with unsaved work." />
      <header className="configure-contract-header">
        <h1 className="contract-title">
          {`[${contract.projectName}] ${contract.name}`}
        </h1>
        <nav>
          <ul className="link-list">
            <li className="link"><Link to="/dashboard">Dashboard</Link></li>
            <li className="link"><Link to={`/projects/${contract.projectId}`}>Back to Project</Link></li>
          </ul>
        </nav>
      </header>
      <aside className="configure-contract-summary">
        <dl>
          <dt>Total Amount</dt>
          <dd>{formatAsCurrency(contract?.totalAmount)}</dd>
          <dt>Remaining Amount</dt>
          <dd>{formatAsCurrency(contract?.amountRemaining)}</dd>
          <dt>{`Expected ${determineCurrentMonthAbbreviation()} Month`}</dt>
          <dd>{formatAsCurrency(contract?.amountForMonth)}</dd>
        </dl>
      </aside>
      <section className="configure-contract-details">
        <form>
          <LookupSelection
            type={LookupTypes.BillingMode}
            labelText="Billing Mode"
            selectId="billing-mode"
            lookupId={contract.billingModeId}
            selectionChanged={updateBillingModeId}
            disabled={!contract.canModifyBillingModeId}
          />
        </form>
        <h2>Phase Details</h2>
        {validationMessages}
        <table>
          <thead>
            <tr>
              <th>Phase</th>
              <th>Start Month</th>
              <th>Start Year</th>
              <th>End Month</th>
              <th>End Year</th>
              <th>Amount for Phase ($)</th>
              <th>% of Contract</th>
              <th>Remaining for Phase ($)</th>
              <th>Amount Per Month ($)</th>
              <th>{`Amount for ${determineCurrentMonthAbbreviation()}`}</th>
            </tr>
          </thead>
          <tbody>
            {
            contract.phases.map((p) => (
              <PhaseRow
                key={p.phaseNameId}
                phase={p}
                updatePhaseAmount={updatePhaseAmount}
                updatePhaseMonths={updatePhaseMonths}
                updatePhasePercent={updatePhasePercent}
              />
            ))
            }
            <tr>
              <td />
              <td />
              <td />
              <td />
              <td />
              <td className="bold-text right-align-text">{formatAsCurrency(contract.totalOfPhases)}</td>
              <td className="bold-text right-align-text">
                {
                formatAsPercentage(contract.totalAmount > 0
                  ? ((contract.totalOfPhases / contract.totalAmount) * 100)
                  : 0)
                }
              </td>
              <td className="bold-text right-align-text">{formatAsCurrency(contract.sumPhaseAmountRemaining)}</td>
              <td />
              <td />
            </tr>
          </tbody>
        </table>
      </section>
      <section className="configure-contract-actions">
        <Button
          type="button"
          variant="default"
          onClick={saveAndExitClicked}
          disabled={
            currentlySaving
            || contract.brokenValidations.length > 0
            || contract.needsBillingTemplate
          }
        >
          Save
        </Button>
        <Button
          type="button"
          variant="primary"
          onClick={saveAndContinueClicked}
          disabled={currentlySaving || contract.brokenValidations.length > 0}
        >
          Next
        </Button>
      </section>
    </main>
  );
};

export default ConfigureContract;
