import { formatAsCurrency } from '../../features/projects/utils';

interface AmountWithId {
  id: number;
  amount: number;
}

export interface State {
  adjustmentAmounts: AmountWithId[];
  adjustmentNote: string;
  linkedInvoiceNumber: string;
  currentAdjustmentTotal: string;
  canSubmit: boolean;
}

const initAdj = { id: 0, amount: 0 };

export function initialize(): State {
  return {
    // 7 phases in the app, so start them all off with $0 adjustments
    // index 0 is actually not used, but the phase ids go from 1-6
    adjustmentAmounts: [initAdj, initAdj, initAdj, initAdj, initAdj, initAdj, initAdj],
    adjustmentNote: '',
    linkedInvoiceNumber: '',
    currentAdjustmentTotal: formatAsCurrency(0),
    canSubmit: false,
  };
}

export interface ChangeAdjustmentAmount {
  type: 'changeAdjustmentAmount';
  payload: {
    phaseNameId: number;
    contractPhaseId: number;
    amount: number;
  };
}

export interface ChangeAdjustmentNote {
  type: 'changeAdjustmentNote';
  payload: string;
}

export interface ChangeInvoiceNumber {
  type: 'changeInvoiceNumber',
  payload: string;
}

export interface ClearData {
  type: 'clearData'
}

type Action = ChangeAdjustmentAmount
  | ChangeAdjustmentNote
  | ChangeInvoiceNumber
  | ClearData;

function updateAdjustmentAmounts(
  state: State, action: ChangeAdjustmentAmount,
): State {
  const copy = [...state.adjustmentAmounts];
  copy[action.payload.phaseNameId] = {
    id: action.payload.contractPhaseId,
    amount: action.payload.amount,
  };
  return {
    ...state,
    adjustmentAmounts: copy,
    currentAdjustmentTotal: formatAsCurrency(copy.reduce((agg, a) => agg + a.amount, 0)),
  };
}

function calculateCanSubmit(state: State): boolean {
  // If there are no adjustments, we shouldn't be allowed to submit
  if (state.adjustmentAmounts.find((a) => a.amount !== 0 && a.id !== 0) === undefined) {
    return false;
  }

  const invoiceNumber = Number(state.linkedInvoiceNumber ?? 0);

  // If there is no invoice selected, we shouldn't be allowed to submit
  if (invoiceNumber === 0 || Number.isNaN(invoiceNumber)) {
    return false;
  }

  // If there is no note, we shouldn't be allowed to submit
  if ((state.adjustmentNote ?? '') === '') {
    return false;
  }

  return true;
}

export function reduce(state: State | undefined, action: Action): State | undefined {
  if (state === undefined) {
    return state;
  }

  let newState: State;

  switch (action.type) {
    case 'changeAdjustmentAmount':
      newState = updateAdjustmentAmounts(state, action);
      break;
    case 'changeAdjustmentNote':
      newState = {
        ...state,
        adjustmentNote: action.payload,
      };
      break;
    case 'changeInvoiceNumber':
      newState = {
        ...state,
        linkedInvoiceNumber: action.payload,
      };
      break;
    case 'clearData':
      newState = initialize();
      break;
    default:
      newState = state;
  }

  newState.canSubmit = calculateCanSubmit(newState);
  return newState;
}
