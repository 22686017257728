import React from 'react';
import LookupValue from '../lookups/LookupValue';
import { LookupTypes } from '../lookups/types';
import { ContractPhase } from './types';
import { formatAsCurrency, formatAsMonthAndYear, formatAsPercentage } from './utils';

type PhaseTableProps = {
  contractTotal: number;
  phases: ContractPhase[];
}

function PhaseTable({ contractTotal, phases }: PhaseTableProps): JSX.Element {
  const rows = phases.map((p: ContractPhase):JSX.Element => (
    <tr key={p.id}>
      <td>
        <LookupValue
          lookupType={LookupTypes.PhaseName}
          lookupId={p.phaseNameId}
        />
      </td>
      <td>{formatAsMonthAndYear(p.startMonth, p.startYear)}</td>
      <td>{formatAsMonthAndYear(p.endMonth, p.endYear)}</td>
      <td>{formatAsCurrency(p.totalAmount)}</td>
      <td>{formatAsPercentage((p.totalAmount / contractTotal) * 100)}</td>
      <td>{formatAsCurrency(p.remainingAmount)}</td>
      <td>{formatAsCurrency(p.expectedCurrentMonth)}</td>
      <td>{formatAsCurrency(p.currentMonthAuthorization?.amount)}</td>
    </tr>
  ));

  return (
    <div className="phase-table">
      <div>Phase Details</div>
      <table>
        <thead>
          <tr>
            <th>Phase</th>
            <th>Start Month</th>
            <th>End Month</th>
            <th>Total Amount</th>
            <th>% of Contract Total</th>
            <th>Amount Remaining</th>
            <th>Amount for Month</th>
            <th>Authorized for Month</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </table>
    </div>
  );
}

export default PhaseTable;
