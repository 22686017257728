declare global {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  interface Window { WebToDucMessenger: any; }
}

function getTierOverrideFromHostname(hostname: string): string {
  if (hostname === 'contract-billing.directsupply.cloud') {
    return '';
  }
  if (hostname === 'contract-billing.directsupply-testing.cloud') {
    return 'qa';
  }
  return 'dev';
}

export default function openPmtScreen(pmtNumber: number): void {
  const tierOverride = getTierOverrideFromHostname(window.location.hostname);
  window.WebToDucMessenger.openScreen(813, `${pmtNumber}`, true, tierOverride);
}
