import React from 'react';
import { LoadingStatus } from '../features/LoadingStatus';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { loadUsers } from '../features/users/slice';
import { loadOptions } from '../features/contractBillingStatusOptions/slice';
import { loadLookups } from '../features/lookups/slice';
import { loadProjects } from '../features/projects/slice';
import { loadBillingRequests } from '../features/billingRequests/slice';

type LoadingScreenProps = {
  children: JSX.Element | JSX.Element[];
};

function loadingOrLoaded(status: LoadingStatus, type: string): string {
  return status === LoadingStatus.Idle
    ? `${type} loaded!`
    : `${type} loading...`;
}

function LoadingScreen({ children }: LoadingScreenProps): JSX.Element {
  const usersStatus = useAppSelector((state) => state.users.loading);
  const billingOptionsStatus = useAppSelector(
    (state) => state.contractBillingStatusOptions.loading,
  );
  const lookupsStatus = useAppSelector((state) => state.lookups.loading);
  const projectsStatus = useAppSelector((state) => state.projects.loading);
  const billingRequestsStatus = useAppSelector((state) => state.billingRequests.loading);
  const dispatch = useAppDispatch();

  // Dispatch any that are uninitialized
  if (usersStatus === LoadingStatus.Uninitialized) {
    dispatch(loadUsers());
  }

  if (billingOptionsStatus === LoadingStatus.Uninitialized) {
    dispatch(loadOptions());
  }

  if (lookupsStatus === LoadingStatus.Uninitialized) {
    dispatch(loadLookups());
  }

  if (projectsStatus === LoadingStatus.Uninitialized) {
    dispatch(loadProjects());
  }

  if (billingRequestsStatus === LoadingStatus.Uninitialized) {
    dispatch(loadBillingRequests());
  }

  // If any are errored, then we can't proceed
  if (
    usersStatus === LoadingStatus.Errored
    || billingOptionsStatus === LoadingStatus.Errored
    || lookupsStatus === LoadingStatus.Errored
    || projectsStatus === LoadingStatus.Errored
    || billingRequestsStatus === LoadingStatus.Errored
  ) {
    return (
      <>
        <h1>Failed to load data.</h1>
        <p>Please try refreshing your browser.</p>
        <p>If this continues to fail, submit a ticket to 7400.</p>
      </>
    );
  }

  // If all are idle, then proceed
  if (
    usersStatus === LoadingStatus.Idle
    && billingOptionsStatus === LoadingStatus.Idle
    && lookupsStatus === LoadingStatus.Idle
    && projectsStatus === LoadingStatus.Idle
    && billingRequestsStatus === LoadingStatus.Idle
  ) {
    return (
      <>{children}</>
    );
  }

  // Otherwise, show status
  return (
    <>
      <h1>Loading data, please wait...</h1>
      <ul>
        <li>{loadingOrLoaded(usersStatus, 'Users')}</li>
        <li>{loadingOrLoaded(billingOptionsStatus, 'Contract Billing Status Options')}</li>
        <li>{loadingOrLoaded(lookupsStatus, 'Lookup Values')}</li>
        <li>{loadingOrLoaded(projectsStatus, 'Projects')}</li>
        <li>{loadingOrLoaded(billingRequestsStatus, 'Billing Requests')}</li>
      </ul>
    </>
  );
}

export default LoadingScreen;
