import './ExpandableCard.scss';
import React, { ReactNode } from 'react';

export type ExpandableCardProps = {
  cardId: number | string;
  title: ReactNode;
  children?: ReactNode;
};

const determineClassName = (isCollapsed: boolean): string => {
  const collapsedPiece = isCollapsed ? 'hidden ' : '';
  return `${collapsedPiece}expandable-card-body`;
};

function ExpandableCard({ cardId, title, children }: ExpandableCardProps): JSX.Element {
  const [isCollapsed, setIsCollapsed] = React.useState<boolean>(true);
  const divId = `card_${cardId}`;

  return (
    <section className="expandable-card">
      <button
        type="button"
        className="expandable-card-title"
        aria-expanded={!isCollapsed}
        aria-controls={divId}
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        {title}
      </button>
      <div className={determineClassName(isCollapsed)} id={divId}>
        {children}
      </div>
    </section>
  );
}

export default ExpandableCard;
