import React from 'react';
import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import { selectUserById } from './slice';

type UserNameProps = {
  userId: number | undefined;
};

function UserName({ userId }: UserNameProps): JSX.Element {
  const user = useAppSelector((state: RootState) => selectUserById(state, userId ?? -1));

  if (!userId) {
    return (<>(no user)</>);
  }

  if (!user) {
    return (
      <>
        Unknown user id:
        {userId}
      </>
    );
  }

  return (<>{user.fullName}</>);
}

export default UserName;
