import { BillingRequest, BillingRequestBuckets, Statuses } from './types';

function segment(
  buckets: BillingRequestBuckets, request: BillingRequest,
): BillingRequestBuckets {
  const needsReceiptsAndHasReimb = request.needsReceipts
    && request.authorizedContracts.some((c) => c.isReimbursable);

  if (
    request.statusTypeId === Statuses.ReadyToInvoice || request.statusTypeId === Statuses.OnHold
  ) {
    const onHoldInc = request.statusTypeId === Statuses.OnHold ? 1 : 0;

    if (needsReceiptsAndHasReimb) {
      const arr = buckets.readyWithReceipts;
      arr.push(request.id);
      return {
        ...buckets,
        readyWithReceipts: arr,
        onHoldWithReceipts: buckets.onHoldWithReceipts + onHoldInc,
      };
    }

    const arr = buckets.readyWithoutReceipts;
    arr.push(request.id);
    return {
      ...buckets,
      readyWithoutReceipts: arr,
      onHoldWithoutReceipts: buckets.onHoldWithoutReceipts + onHoldInc,
    };
  }

  if (request.statusTypeId === Statuses.NeedsReceipts) {
    const arr = buckets.needReceipts;
    arr.push(request.id);
    return {
      ...buckets,
      needReceipts: arr,
    };
  }

  // Otherwise, assume the request is completed, so add it to either of the completed buckets
  if (needsReceiptsAndHasReimb) {
    return {
      ...buckets,
      completedWithReceiptsCount: buckets.completedWithReceiptsCount + 1,
    };
  }

  return {
    ...buckets,
    completedWithoutReceiptsCount: buckets.completedWithoutReceiptsCount + 1,
  };
}

export default function segmentBillingRequests(requests: BillingRequest[]): BillingRequestBuckets {
  const buckets: BillingRequestBuckets = {
    completedWithReceiptsCount: 0,
    onHoldWithReceipts: 0,
    readyWithReceipts: [],
    needReceipts: [],
    onHoldWithoutReceipts: 0,
    completedWithoutReceiptsCount: 0,
    readyWithoutReceipts: [],
  };

  return requests.reduce((b, r) => segment(b, r), buckets);
}
