import React from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { loadOptions, selectAllOptions } from './slice';
import { LoadingStatus } from '../LoadingStatus';

function ContractBillingStatusOptions(): JSX.Element {
  const optionsState = useAppSelector((state) => state.contractBillingStatusOptions.loading);
  const options = useAppSelector((state) => selectAllOptions(state));
  const dispatch = useAppDispatch();

  if (optionsState === LoadingStatus.Uninitialized) {
    dispatch(loadOptions());
    return (<option value="" disabled>Loading...</option>);
  }
  if (optionsState === LoadingStatus.Pending) {
    return (<option value="" disabled>Loading...</option>);
  }
  if (optionsState === LoadingStatus.Errored) {
    return (<option value="" disabled>Failed to load options</option>);
  }

  const mappedLookups = (options ?? [])
    .filter((o) => o.enabled) // Only enabled options
    .sort((a, b) => a.sortOrder - b.sortOrder)
    .map((o) => (
      <option key={o.id} value={o.id}>
        {`${String.fromCodePoint(o.emojiCode)} ${o.name}`}
      </option>
    ));

  return (
    <>
      <option value="">Select Status Option...</option>
      {mappedLookups}
    </>
  );
}

export default ContractBillingStatusOptions;
