import React from 'react';
import { determineDistinctAppliedTypes } from './utils';
import ContractBillingStatusEmoji from './ContractBillingStatusEmoji';

type DistinctStatusEmojiProps = {
  ids: number[];
};

function DistinctStatusEmoji({ ids }: DistinctStatusEmojiProps): JSX.Element {
  const values = determineDistinctAppliedTypes(ids);
  if (values.length === 0) {
    return (<span aria-label="No statuses" title="No statuses">&nbsp;</span>);
  }

  return (
    <>
      {values.map((v) => <ContractBillingStatusEmoji key={v} id={v} />)}
    </>
  );
}

export default DistinctStatusEmoji;
