/* eslint-disable jsx-a11y/label-has-associated-control */
import './BillingRequestDetails.scss';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@dsx/react';
import { formatAsCurrency } from '../../features/projects/utils';
import UserName from '../../features/users/UserName';
import LookupValue from '../../features/lookups/LookupValue';
import { LookupTypes } from '../../features/lookups/types';
import { Statuses } from '../../features/billingRequests/types';
import useBillingRequestDetails from './hooks';

function createNavLink(text: string, url: string | undefined): JSX.Element {
  return url === undefined
    ? (<>{text}</>)
    : (<Link to={url}>{text}</Link>);
}

function determineStatusClass(currentStatusId: number): string {
  if (currentStatusId === Statuses.ReadyToInvoice) {
    return 'green-background';
  }
  if (currentStatusId === Statuses.OnHold) {
    return 'yellow-background';
  }
  if (currentStatusId === Statuses.NeedsReceipts) {
    return 'orange-background';
  }
  return '';
}

const BillingRequestDetails: React.FC = () => {
  const vm = useBillingRequestDetails();

  if (vm.isLoading) {
    return (
      <main className="billing-request-details">
        <header className="billing-request-details-header">
          <h1 className="billing-request-title center-align-text">Loading...</h1>
          <nav>
            <ul className="link-list">
              <li className="link"><Link to="/">Home</Link></li>
            </ul>
          </nav>
        </header>
      </main>
    );
  }

  if (vm.failedLoading) {
    return (
      <main className="billing-request-details">
        <header className="billing-request-details-header">
          <h1 className="billing-request-title center-align-text error-msg">
            {`Invalid billing request id: ${vm.requestId}`}
          </h1>
          <nav>
            <ul className="link-list">
              <li className="link"><Link to="/">Home</Link></li>
            </ul>
          </nav>
        </header>
      </main>
    );
  }

  const nextLink = createNavLink('Next', vm.navigation.nextUrl);
  const prevLink = createNavLink('Previous', vm.navigation.prevUrl);

  return (
    <main className="billing-request-details">
      <header className="billing-request-details-header">
        <h1 className="billing-request-title center-align-text">{vm.title}</h1>
        <nav>
          <ul className="link-list">
            <li className="link">
              <Button
                variant="default"
                type="button"
                onClick={vm.onOpenProject}
                disabled={vm.processingAction}
              >
                Open PMT
              </Button>
            </li>
            <li className="link"><Link to="/">Home</Link></li>
            <li className="link">{vm.navigation.currentText}</li>
            <li className="link">{prevLink}</li>
            <li className="link">{nextLink}</li>
          </ul>
        </nav>
      </header>
      <aside className="billing-request-details-sidebar">
        <header>
          <h2>
            General Billing Request Information
          </h2>
        </header>
        <dl>
          <dt>Current Status</dt>
          <dd className={determineStatusClass(vm.requestStatusId)}>
            <LookupValue
              lookupType={LookupTypes.BillingRequestStatus}
              lookupId={vm.requestStatusId}
            />
          </dd>
          <dt>Data Manager</dt>
          <dd><UserName userId={vm.dataManagerId} /></dd>
          <dt>Needs Receipts?</dt>
          <dd>{vm.needsReceipts}</dd>
          <dt>Any Contracts to Approve?</dt>
          <dd>{vm.anyContractsToApprove}</dd>
          <dt>UDI Enabled?</dt>
          <dd>{vm.udiEnabled}</dd>
          <dt>Do Not Send Allowed</dt>
          <dd>
            <LookupValue
              lookupType={LookupTypes.ApprovalItemStatus}
              lookupId={vm.doNotSendAllowed}
            />
          </dd>
          <dt>Notes</dt>
          <dd>{vm.notes}</dd>
          <dt>Invoice Number</dt>
          <dd>
            <input
              type="number"
              onChange={vm.onInvoiceNumberChanged}
              value={vm.invoiceNumber ?? ''}
              disabled={
                vm.requestStatusId === Statuses.NeedsReceipts
                || vm.requestStatusId === Statuses.Complete
              }
            />
          </dd>
        </dl>
        <div>
          <Button
            variant="primary"
            type="button"
            onClick={vm.onSave}
            disabled={
              vm.processingAction
              || vm.requestStatusId === Statuses.NeedsReceipts
              || vm.requestStatusId === Statuses.Complete
            }
          >
            Save
          </Button>
        </div>
        <div>
          <label>
            <input
              type="checkbox"
              checked={vm.waitingForInfo}
              onChange={vm.onWaitingForInfo}
              disabled={vm.waitingForInfoDisabled}
            />
            Waiting for Information
          </label>
        </div>
        <div>
          <Button
            variant="default"
            type="button"
            onClick={vm.onDownloadReceipts}
            disabled={vm.downloadReceiptsDisabled}
          >
            Download Receipts Template
          </Button>
        </div>
        <div>
          <Button
            variant="primary"
            type="button"
            onClick={vm.onSave}
            disabled={vm.processingAction || vm.requestStatusId !== Statuses.NeedsReceipts}
          >
            Complete Receipts
          </Button>
        </div>
      </aside>
      <section className="billing-request-details-main">
        <header>
          <h2>Invoicing Information</h2>
        </header>
        <article className="udi-step">
          <h3>Responsible Party/Ship Selection</h3>
          <dl>
            <dt>Responsible Party</dt>
            <dd>{vm.responsibleParty}</dd>
            <dt>Ship Location</dt>
            <dd>{vm.shipLocation}</dd>
          </dl>
        </article>
        <article className="udi-step">
          <h3>DS Services</h3>
          <table>
            <thead>
              <tr>
                <th>Status</th>
                <th>Contract Name</th>
                <th>Date Entered</th>
                <th>Contract Total</th>
                <th>Charge Amount</th>
                <th>Charge Description</th>
              </tr>
            </thead>
            <tbody>
              {
                vm.contracts.map((c) => (
                  <tr key={c.contractId}>
                    <td>
                      <LookupValue
                        lookupType={LookupTypes.ContractStatus}
                        lookupId={c.statusId}
                      />
                    </td>
                    <td>{c.contractName}</td>
                    <td>{c.dateEntered}</td>
                    <td className="right-align-text">{formatAsCurrency(c.contractTotal)}</td>
                    <td className="right-align-text">{formatAsCurrency(c.authorizedTotal)}</td>
                    <td>{c.invoiceDescription}</td>
                  </tr>
                ))
              }
              <tr>
                <td />
                <td />
                <td />
                <td />
                <td className="bold-text right-align-text">
                  {formatAsCurrency(vm.contractsTotal)}
                </td>
                <td />
              </tr>
            </tbody>
          </table>
        </article>
        <article className="udi-step">
          <h3>Order Contact and Cust PO Information</h3>
          <dl>
            <dt>Order Contact</dt>
            <dd>{vm.billToAttention}</dd>
            <dt>Alt PO Number</dt>
            <dd>{vm.customerPONumber ? vm.customerPONumber : 'PROF SVCS'}</dd>
          </dl>
        </article>
        <article className="udi-step">
          <h3>Billing Configuration</h3>
          <dl>
            <dt>Billing Address</dt>
            <dd>{vm.billingAddress}</dd>
            <dt>Bill Attention</dt>
            <dd>{vm.billToAttention}</dd>
            <dt>Invoice Medium</dt>
            <dd>
              <LookupValue
                lookupType={LookupTypes.InvoiceType}
                lookupId={vm.invoiceTypeId}
              />
            </dd>
          </dl>
        </article>
      </section>
    </main>
  );
};

export default BillingRequestDetails;
