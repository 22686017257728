import React from 'react';
import { Link } from 'react-router-dom';
import ContractBillingStatusEmoji from '../contractBillingStatusOptions/ContractBillingStatusEmoji';
import LookupValue from '../lookups/LookupValue';
import { LookupTypes } from '../lookups/types';
import { selectLookupValue } from '../lookups/slice';
import { determineCurrentMonthAbbreviation } from '../monthUtils';
import PhaseTable from './PhaseTable';
import { selectProjectById } from './slice';
import { Contract, ContractStatuses } from './types';
import { formatAsCurrency } from './utils';
import ExpandableCard from '../../components/ExpandableCard';
import BillingTemplateTable from './BillingTemplateTable';
import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import AuthorizationButtons from './AuthorizationButtons';
import CloseRequest from './CloseRequest';

type ContractCardProps = {
  contract: Contract;
  projectId: number;
}

const determineCardTotals = (contract: Contract): string => {
  const monthAbbrev = determineCurrentMonthAbbreviation();
  const total = `Total: ${formatAsCurrency(contract.totalAmount)}`;
  const remaining = `Remaining: ${formatAsCurrency(contract.amountRemaining)}`;
  const expectedMonth = `Expected ${monthAbbrev} Month: ${formatAsCurrency(contract.expectedCurrentMonth)}`;
  const authorizedMonth = `Authorized ${monthAbbrev} Month: ${formatAsCurrency(contract.authorizedCurrentMonth)}`;
  return `${total} | ${remaining} | ${expectedMonth} | ${authorizedMonth}`;
};

function ContractCard({ contract, projectId }: ContractCardProps): JSX.Element {
  const project = useAppSelector((state: RootState) => selectProjectById(state, projectId));
  const contractType = useAppSelector(
    (state: RootState) => selectLookupValue(
      state, LookupTypes.ContractType, contract.serviceContractTypeId,
    ),
  );
  const componentType = useAppSelector(
    (state: RootState) => selectLookupValue(
      state, LookupTypes.ComponentType, contract.serviceComponentTypeId,
    ),
  );

  const {
    expandButton: closeRequestExpandButton,
    view: closeRequestView,
  } = CloseRequest({
    amountRemainingOnContract: contract.amountRemaining,
    contractId: contract.id,
    contractBillingStatusId: contract.contractBillingStatusId,
    contractName: contract.serviceContractName,
    contractTypeName: contractType?.name ?? '',
    existingRequest: contract.closeRequest,
    project,
    serviceComponentTypeName: componentType?.name ?? '',
  });

  if (project === undefined) {
    return (
      <div>
        {`Can't find project with id ${projectId}`}
      </div>
    );
  }

  // Determine where the "Configure Contract" link should take the user.
  // If the contract status is new, then they need to verify the contract.
  // Otherwise, they can skip straight to configuring the phases.
  const configureAction = contract.serviceContractStatusId === ContractStatuses.New
    ? 'verify'
    : 'phases';

  const title = (
    <>
      <span className="contract-status-icon">
        <ContractBillingStatusEmoji id={contract.contractBillingStatusId} />
      </span>
      <span className="contract-name bold-text left-align-text">
        {contract.serviceContractName}
      </span>
      <span className="contract-totals">
        {determineCardTotals(contract)}
      </span>
    </>
  );

  return (
    <ExpandableCard cardId={contract.id} title={title}>
      <dl>
        <dt>Contract Type</dt>
        <dd>
          <LookupValue
            lookupType={LookupTypes.ContractType}
            lookupId={contract.serviceContractTypeId}
          />
        </dd>
        <dt>Service Component</dt>
        <dd>
          <LookupValue
            lookupType={LookupTypes.ComponentType}
            lookupId={contract.serviceComponentTypeId}
          />
        </dd>
        <dt>Contract Status</dt>
        <dd>
          <LookupValue
            lookupType={LookupTypes.ContractStatus}
            lookupId={contract.serviceContractStatusId}
          />
        </dd>
        <dt>Responsible Party</dt>
        <dd>
          {contract.responsibleParty}
        </dd>
        <dt>Service Location</dt>
        <dd>{contract.serviceLocation}</dd>
        <dt>Service Address</dt>
        <dd>
          {contract.serviceAddress}
        </dd>
        <dt>Date Entered</dt>
        <dd>
          {contract.dateEntered}
        </dd>
        <dt>Service Component Description</dt>
        <dd>
          {contract.serviceComponentDescription}
        </dd>
        <dt>Invoice Progress Description</dt>
        <dd>
          {contract.isChangeOrder || contract.isReimbursable ? 'N/A' : contract.invoiceDescription}
        </dd>
        <BillingTemplateTable projectId={projectId} templateId={contract.billingTemplateId} />
      </dl>
      <ul className="link-list">
        <li className="link">
          <Link to={`/projects/${projectId}/contracts/${contract.id}/${configureAction}`}>
            Configure Contract
          </Link>
        </li>
        <AuthorizationButtons project={project} contract={contract} />
        <li className="link flex-grow-1" />
        <li className="link">
          {closeRequestExpandButton}
        </li>
      </ul>
      {closeRequestView}
      <PhaseTable
        phases={contract.phases}
        contractTotal={contract.totalAmount}
      />
    </ExpandableCard>
  );
}

export default ContractCard;
