import React from 'react';
import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import { selectLookupValue } from './slice';
import { LookupTypes } from './types';

type LookupValueProps = {
  lookupType: LookupTypes;
  lookupId: number | undefined;
};

function LookupValue({ lookupType, lookupId }: LookupValueProps): JSX.Element {
  const value = useAppSelector((s: RootState) => selectLookupValue(s, lookupType, lookupId ?? -1));

  if (!lookupId) {
    return (<>(no value)</>);
  }

  if (!value) {
    return (
      <>
        Unknown lookup id:
        {lookupId}
      </>
    );
  }

  return (<>{value.name}</>);
}

export default LookupValue;
