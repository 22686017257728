import {
  ContractPhaseViewModel,
  ContractViewModel,
  InvalidReasons,
} from './viewModels';

export type PhaseValidationAccumulator = {
  contractTotal: number;
  rollingTotal: number;
  amountForMonthTotal: number;
  remainingAmountTotal: number;
  invalidDates: boolean;
  invalidAmounts: boolean;
  phases: ContractPhaseViewModel[];
}

const initialAccumulator: PhaseValidationAccumulator = {
  contractTotal: 0,
  rollingTotal: 0,
  amountForMonthTotal: 0,
  remainingAmountTotal: 0,
  invalidDates: false,
  invalidAmounts: false,
  phases: [],
};

export function validatePhaseTotal(phase: ContractPhaseViewModel, contractTotal: number): boolean {
  if (
    Number.isNaN(phase.phaseAmount)
    || Number.isNaN(phase.percentOfContract)
    || phase.phaseAmount < 0 || phase.phaseAmount > contractTotal
    || phase.percentOfContract < 0 || phase.percentOfContract > 100
  ) {
    return false;
  }

  return true;
}

export function doesPhaseHaveInvalidDates(phase: ContractPhaseViewModel): boolean {
  const startDate = new Date(phase.startYear, phase.startMonth, 2, 10, 0, 0);
  const endDate = new Date(phase.endYear, phase.endMonth, 5, 10, 0, 0);
  return startDate > endDate;
}

export function validateAndSumPhasesReducer(
  accumulator: PhaseValidationAccumulator, phase: ContractPhaseViewModel,
): PhaseValidationAccumulator {
  const invalidDates = doesPhaseHaveInvalidDates(phase);
  const invalidAmounts = !validatePhaseTotal(phase, accumulator.contractTotal);

  const updatedPhase = { ...phase, invalid: invalidDates || invalidAmounts };

  return {
    contractTotal: accumulator.contractTotal,
    invalidDates: accumulator.invalidDates || invalidDates,
    invalidAmounts: accumulator.invalidAmounts || invalidAmounts,
    rollingTotal: accumulator.rollingTotal + phase.phaseAmount,
    amountForMonthTotal: accumulator.amountForMonthTotal
      + (phase.activeThisMonth ? phase.amountPerMonth : 0),
    remainingAmountTotal: accumulator.remainingAmountTotal + phase.remainingAmount,
    phases: [...accumulator.phases, updatedPhase],
  };
}

export function validateAndSumPhases(
  phases: ContractPhaseViewModel[], contractTotal: number,
): PhaseValidationAccumulator {
  return phases.reduce(validateAndSumPhasesReducer, { ...initialAccumulator, contractTotal });
}

export function doesContractHaveBillingModeSet(contract: ContractViewModel): boolean {
  return contract.billingModeId !== undefined;
}

export default function validateContract(contract: ContractViewModel): ContractViewModel {
  const brokenValidations: InvalidReasons[] = [];

  if (!doesContractHaveBillingModeSet(contract)) {
    brokenValidations.push(InvalidReasons.NoBillingMode);
  }

  const phaseValidationResult = validateAndSumPhases(contract.phases, contract.totalAmount);

  const diff = Math.abs(phaseValidationResult.rollingTotal - contract.totalAmount);
  if (diff > 0) {
    brokenValidations.push(InvalidReasons.PhasesDoNotAddToContract);
  }

  if (phaseValidationResult.invalidDates) {
    brokenValidations.push(InvalidReasons.InvalidDates);
  }

  if (phaseValidationResult.invalidAmounts) {
    brokenValidations.push(InvalidReasons.InvalidAmounts);
  }

  return {
    ...contract,
    brokenValidations,
    totalOfPhases: phaseValidationResult.rollingTotal,
    sumPhaseAmountRemaining: phaseValidationResult.remainingAmountTotal,
    amountForMonth: phaseValidationResult.amountForMonthTotal,
    saveDisabled: brokenValidations.length > 0,
    phases: phaseValidationResult.phases,
  };
}
