import React from 'react';
import { Card } from '@dsx/react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import { UnsentAuthorization } from '../../features/projects/types';
import { deleteAuthorizations, selectProjectById } from '../../features/projects/slice';
import { formatAsCurrency } from '../../features/projects/utils';
import { sendBillingRequestsToBpo } from '../../features/billingRequests/slice';
import UserName from '../../features/users/UserName';
import displayError from '../../components/displayError';

type SummaryRowProps = {
  authorization: UnsentAuthorization;
}

function SummaryRow({ authorization }: SummaryRowProps): JSX.Element {
  const project = useAppSelector(
    (state: RootState) => selectProjectById(state, authorization.projectId),
  );
  const dispatch = useAppDispatch();
  const contract = project?.contracts.find((c) => c.id === authorization.contractId);

  const [sending, setSending] = React.useState<boolean>(false);
  const [sent, setSent] = React.useState<boolean>(false);

  const onSend = React.useCallback(() => {
    setSending(true);
    sendBillingRequestsToBpo(dispatch, authorization.projectId, authorization.authorizedMonth, [])
      .then((newRequests) => {
        if (newRequests) {
          setSent(true);
        } else {
          displayError(`Failed to send authorization to BPO for ${authorization.pmtNumber}`);
        }
        setSending(false);
      })
      .catch(() => {
        displayError(`Failed to send authorization to BPO for ${authorization.pmtNumber}`);
        setSending(false);
      });
  }, [authorization, dispatch]);

  const onDelete = React.useCallback(() => {
    if (project === undefined || contract === undefined) return;

    setSending(true);
    deleteAuthorizations(dispatch, project, contract.id, authorization.authorizedMonth)
      .then((success) => {
        if (!success) {
          displayError('Failed to delete the authorization.');
        } else {
          setSent(true);
        }
      })
      .catch(() => {
        displayError('Failed to delete the authorization.');
      })
      .finally(() => {
        setSending(false);
      });
  }, [authorization, contract, dispatch, project]);

  return (
    <tr>
      <td>{authorization.pmtNumber}</td>
      <td>{project?.name ?? '(unknown)'}</td>
      <td><UserName userId={project?.dataManagerId ?? 0} /></td>
      <td>{contract?.serviceContractName ?? '(unknown)'}</td>
      <td><UserName userId={authorization.whoAuthorized} /></td>
      <td>{authorization.whenAuthorized}</td>
      <td>{formatAsCurrency(authorization.authorizedAmount)}</td>
      <td>
        <button
          type="button"
          disabled={project === null || contract === null || sent || sending}
          onClick={onSend}
        >
          Send to BPO
        </button>
      </td>
      <td>
        <button
          type="button"
          disabled={project === null || contract === null || sent || sending}
          onClick={onDelete}
        >
          Delete Authorization
        </button>
      </td>
    </tr>
  );
}

type MonthSummaryTableProps = {
  month: string;
  authorizations: UnsentAuthorization[];
}

function MonthSummaryTable({ month, authorizations }: MonthSummaryTableProps): JSX.Element {
  return (
    <Card variant="outline">
      <h2>{`Unsent authorizations for ${month}`}</h2>
      <table>
        <thead>
          <tr>
            <th>PMT Number</th>
            <th>Project Name</th>
            <th>Data Manager</th>
            <th>Contract Name</th>
            <th>Authorized By</th>
            <th>Authorized On</th>
            <th>Amount</th>
            <th>Send to BPO</th>
            <th>Delete Authorization</th>
          </tr>
        </thead>
        <tbody>
          {
            authorizations.map(
              (a) => (
                <SummaryRow key={`${a.projectId}-${a.contractId}`} authorization={a} />
              ),
            )
          }
        </tbody>
      </table>
    </Card>
  );
}

export default MonthSummaryTable;
