import { BillingRequest } from '../billingRequests/types';
import {
  ApprovalItemStatuses,
  BillingStatuses, Contract, ContractStatuses, DashboardFilter, InvoiceTypes, Project,
} from './types';

export function projectPassesFilter(project: Project, filter: DashboardFilter): boolean {
  if (filter.dataManagerId !== undefined && project.dataManagerId !== filter.dataManagerId) {
    return false;
  }

  if (filter.pmtNumber !== '' && project.pmtNumber.toString().indexOf(filter.pmtNumber) === -1) {
    return false;
  }

  if (filter.projectName !== '' && project.name.toLowerCase().indexOf(filter.projectName.toLowerCase()) === -1) {
    return false;
  }

  if (filter.contractStatusOptionId !== undefined
    && project.contracts.findIndex(
      (_) => _.contractBillingStatusId === filter.contractStatusOptionId,
    ) === -1) {
    return false;
  }

  if (filter.designerName !== ''
    && project.designers.every(
      (_) => _.toLowerCase().indexOf(filter.designerName.toLowerCase()) === -1,
    )) {
    return false;
  }

  if (filter.onlyProjectsWithExpectedAmounts
    && project.contracts.every((_) => (_.expectedCurrentMonth ?? 0) === 0)
  ) {
    return false;
  }

  return true;
}

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export function formatAsCurrency(amount: number | null | undefined): string {
  if (amount === null || amount === undefined) return '';

  return currencyFormatter.format(amount);
}

const percentageFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export function formatAsPercentage(percent: number): string {
  return `${percentageFormatter.format(percent)}%`;
}

function determineMonthAbbreviation(month: number): string {
  switch (month) {
    case 1: return 'Jan';
    case 2: return 'Feb';
    case 3: return 'Mar';
    case 4: return 'Apr';
    case 5: return 'May';
    case 6: return 'Jun';
    case 7: return 'Jul';
    case 8: return 'Aug';
    case 9: return 'Sep';
    case 10: return 'Oct';
    case 11: return 'Nov';
    case 12: return 'Dec';
    default: return '???';
  }
}

export function formatAsMonthAndYear(month: number, year: number): string {
  return `${determineMonthAbbreviation(month)} ${year}`;
}

export type UpdatedMonthlyAmounts = {
  expectedCurrentMonth: number;
  authorizedCurrentMonth: number;
};

function monthlyAmountsReducer(
  acc: UpdatedMonthlyAmounts, contract: Contract,
): UpdatedMonthlyAmounts {
  const newExpected = acc.expectedCurrentMonth + (contract.expectedCurrentMonth ?? 0);
  const newAuthorized = acc.authorizedCurrentMonth + contract.authorizedCurrentMonth;
  return {
    authorizedCurrentMonth: newAuthorized,
    expectedCurrentMonth: newExpected,
  };
}

export function computeMonthlyAmounts(project: Project): UpdatedMonthlyAmounts {
  const accumulator: UpdatedMonthlyAmounts = {
    authorizedCurrentMonth: 0,
    expectedCurrentMonth: 0,
  };
  return project.contracts.reduce(monthlyAmountsReducer, accumulator);
}

export interface CanAuthorize {
  can: boolean;
  reason: string;
}

enum ProjectTypesToCheck {
  BuildingAddition = 16,
  ExistingBuilding = 17,
  NewBuilding = 18,
}

enum InvalidContractTypes {
  ApturaTrackingContract = 11,
  CapitalTrackingContract = 23,
}

/*
-- Query for this:
select Id = DSServiceMasterID, Description = Name, SortOrder = DSServiceMasterID
from ICISM_QUOTE.dbo.dssServiceMaster
where Name like '%|%'
order by DSServiceMasterID;
*/
const invalidComponentTypes = [
  1, 4, 7, 8, 13, 14, 15, 16, 43, 44, 46, 47, 48, 56, 57, 58, 59, 60, 61,
  62, 63, 68, 69, 72, 73, 75, 77, 78, 79,
];

function financialDocumentsProvided(project: Project): boolean {
  const trimmedReqSheet = project.invoiceRequirementsSheet.trim();
  const trimmedInfoSheet = project.professionalServicesProvidedInfoSheet.trim();

  if (trimmedReqSheet !== 'NR' && trimmedReqSheet !== 'Y' && trimmedReqSheet !== 'E') {
    return false;
  }

  return trimmedInfoSheet === 'NR' || trimmedInfoSheet === 'Y' || trimmedInfoSheet === 'E';
}

function isAptura360Invoice(project: Project, contract: Contract): boolean {
  if (contract.billingTemplateId === null) return false;

  const template = project.billingTemplates.find((t) => t.id === contract.billingTemplateId);
  if (!template) return false;

  return template.invoiceTypeId === InvoiceTypes.Aptura360;
}

function hasDoNotSendEnabled(project: Project): boolean {
  return project.doNotSendAllowed === ApprovalItemStatuses.Yes;
}

function invalidContractOrComponent(contract: Contract): boolean {
  if (
    contract.serviceContractTypeId === InvalidContractTypes.ApturaTrackingContract
    || contract.serviceContractTypeId === InvalidContractTypes.CapitalTrackingContract
  ) {
    return true;
  }

  const match = invalidComponentTypes.find(
    (id) => id === contract.serviceComponentTypeId,
  );

  return match !== undefined;
}

export function determineExpectedServiceLocation(project: Project, contract: Contract): string {
  // If the contract isn't in the New state, then just return back the service location
  // We have already billed incorrectly
  if (contract.serviceContractStatusId !== ContractStatuses.New) {
    return contract.serviceLocation;
  }

  if (
    project.typeId === ProjectTypesToCheck.BuildingAddition
    || project.typeId === ProjectTypesToCheck.ExistingBuilding
  ) {
    return project.masterAccount;
  }

  if (project.typeId === ProjectTypesToCheck.NewBuilding) {
    return contract.responsibleParty;
  }

  return '?';
}

function validateProjectAndContract(project: Project, contract: Contract): string[] {
  const validationIssues = [];

  // First step - check to make sure the service locations are correct
  const expectedServiceLocation = determineExpectedServiceLocation(project, contract);
  if (contract.serviceLocation !== expectedServiceLocation) {
    validationIssues.push(`Expected the service location to be ${expectedServiceLocation}, but it was ${contract.serviceLocation}.`);
  }

  // Next, check to make sure that the FS documents are provided
  if (!financialDocumentsProvided(project)) {
    validationIssues.push('Financial Services documents not provided.');
  }

  // Next, check to make sure if the linked billing template is A360, then the DNS is enabled
  if (isAptura360Invoice(project, contract) && !hasDoNotSendEnabled(project)) {
    validationIssues.push('Invoice type set to Aptura360, but Do Not Send is not set to Yes.');
  }

  // Next, check if user defined invoicing is enabled
  if (!project.userDefinedInvoicingEnabled) {
    validationIssues.push('User defined invoicing is not enabled.');
  }

  // Next, check if the contract or component types are invalid
  if (invalidContractOrComponent(contract)) {
    validationIssues.push('Invalid contract or component type.');
  }

  // Next, check if the project has a data manager
  if (project.dataManagerId <= 0) {
    validationIssues.push('No data manager set for the project.');
  }

  // Next, check if the contract has a discrepancy in the amount remaining
  // AND not in the Authorized or Sent to BPO state
  // It is important to check for Authorized, because there will be a discrepancy
  // until the authorization is actually billed.
  if (
    contract.contractBillingStatusId !== BillingStatuses.AuthorizedToBill
    && contract.contractBillingStatusId !== BillingStatuses.SentToBPO
    && contract.discrepancyInRemainingAmount
  ) {
    validationIssues.push('Amount remaining discrepancy.');
  }

  return validationIssues;
}

export function contractCanAuthorize(project: Project, contract: Contract): CanAuthorize {
  const validationIssues = validateProjectAndContract(project, contract);

  if (validationIssues.length > 0) {
    return {
      can: false,
      reason: validationIssues[0],
    };
  }

  if (contract.contractBillingStatusId === BillingStatuses.NeedsAuthorization
    || contract.contractBillingStatusId === BillingStatuses.AuthorizedToBill) {
    return { can: true, reason: '' };
  }

  if (contract.contractBillingStatusId === BillingStatuses.VerifyContract) {
    return { can: false, reason: 'Contract not configured.' };
  }

  return { can: false, reason: '' };
}

export function determineCustomPropertyDisplayValue(value: string): string {
  const trimmed = value.trim();

  if (trimmed === 'N') return 'No';
  if (trimmed === 'Y') return 'Yes';
  if (trimmed === 'NR') return 'Not Required';
  if (trimmed === 'R') return 'Required';
  if (trimmed === 'E') return 'Exception';
  return `Unexpected value - ${trimmed}`;
}

export type MonthlySummary = {
  activeContracts: number;
  contractsToVerify: number;
  contractsToVerifyExpectedAmount: number;
  contractsToAuthorize: number;
  contractsToAuthorizeExpectedAmount: number;
  contractsWithNoAction: number;
  contractsAuthorized: number;
  contractsAuthorizedAuthorizationAmount: number;
  contractsSentToBpo: number;
  contractsSentToBpoAuthorizationAmount: number;
  contractsInvoiced: number;
  contractsInvoicedAuthorizationAmount: number;
  contractsOnHold: number;
  contractsOnHoldRemainingAmount: number;
  contractsRequestedToClose: number;
  contractsRequestedToCloseRemainingAmount: number;
  contractsApprovedToClose: number;
  contractsApprovedToCloseRemainingAmount: number;
  apturaAmountBilled: number;
  apturaAmountUnbilled: number;
  capitalAmountBilled: number;
  capitalAmountUnbilled: number;
  currentMonth: Date,
}

export function updateMonthlySummary(
  summary: MonthlySummary, contract: Contract,
): MonthlySummary {
  if (contract.contractBillingStatusId === BillingStatuses.Billed) {
    return {
      ...summary,
      activeContracts: summary.activeContracts + 1,
      contractsInvoiced: summary.contractsInvoiced + 1,
      contractsInvoicedAuthorizationAmount: summary.contractsInvoicedAuthorizationAmount
        + contract.authorizedCurrentMonth,
    };
  }

  if (contract.contractBillingStatusId === BillingStatuses.OnHold) {
    return {
      ...summary,
      activeContracts: summary.activeContracts + 1,
      contractsOnHold: summary.contractsOnHold + 1,
      contractsOnHoldRemainingAmount: summary.contractsOnHoldRemainingAmount
        + contract.amountRemaining,
    };
  }

  if (contract.contractBillingStatusId === BillingStatuses.CloseRequested) {
    return {
      ...summary,
      activeContracts: summary.activeContracts + 1,
      contractsRequestedToClose: summary.contractsRequestedToClose + 1,
      contractsRequestedToCloseRemainingAmount: summary.contractsRequestedToCloseRemainingAmount
        + contract.amountRemaining,
    };
  }

  if (contract.contractBillingStatusId === BillingStatuses.ApprovedToClose) {
    return {
      ...summary,
      activeContracts: summary.activeContracts + 1,
      contractsApprovedToClose: summary.contractsApprovedToClose + 1,
      contractsApprovedToCloseRemainingAmount: summary.contractsApprovedToCloseRemainingAmount
        + contract.amountRemaining,
    };
  }

  if (contract.contractBillingStatusId === BillingStatuses.NoAction) {
    return {
      ...summary,
      activeContracts: summary.activeContracts + 1,
      contractsWithNoAction: summary.contractsWithNoAction + 1,
    };
  }

  if (contract.contractBillingStatusId === BillingStatuses.SentToBPO) {
    return {
      ...summary,
      activeContracts: summary.activeContracts + 1,
      contractsSentToBpo: summary.contractsSentToBpo + 1,
      contractsSentToBpoAuthorizationAmount: summary.contractsSentToBpoAuthorizationAmount
        + contract.authorizedCurrentMonth,
    };
  }

  if (contract.contractBillingStatusId === BillingStatuses.VerifyContract) {
    return {
      ...summary,
      activeContracts: summary.activeContracts + 1,
      contractsToVerify: summary.contractsToVerify + 1,
      contractsToVerifyExpectedAmount: summary.contractsToVerifyExpectedAmount
        + (contract.expectedCurrentMonth ?? 0),
    };
  }

  if (contract.contractBillingStatusId === BillingStatuses.NeedsAuthorization) {
    return {
      ...summary,
      activeContracts: summary.activeContracts + 1,
      contractsToAuthorize: summary.contractsToAuthorize + 1,
      contractsToAuthorizeExpectedAmount: summary.contractsToAuthorizeExpectedAmount
        + (contract.expectedCurrentMonth ?? 0),
    };
  }

  if (contract.contractBillingStatusId === BillingStatuses.AuthorizedToBill) {
    return {
      ...summary,
      activeContracts: summary.activeContracts + 1,
      contractsAuthorized: summary.contractsAuthorized + 1,
      contractsAuthorizedAuthorizationAmount: summary.contractsAuthorizedAuthorizationAmount
        + contract.authorizedCurrentMonth,
    };
  }

  return summary;
}

export function updateMonthlyFinancialSummary(
  summary: MonthlySummary, request: BillingRequest, projects: Project[],
): MonthlySummary {
  // Ignore any requests submitted before the current month
  const submittedOn = new Date(request.submittedOn);
  if (submittedOn < summary.currentMonth) {
    return summary;
  }

  // Find the project to determine the project, but skip if we can't find it
  const proj = projects.find((p) => p.id === request.projectId);
  if (!proj) {
    return summary;
  }

  const authorized = request.authorizedContracts.reduce(
    (sum, c) => sum + c.authorizedTotal, 0,
  );

  if (proj.ownerDepartmentId === 1) {
    if (request.invoicedOn) {
      return {
        ...summary,
        apturaAmountBilled: summary.apturaAmountBilled + authorized,
      };
    }

    return {
      ...summary,
      apturaAmountUnbilled: summary.apturaAmountUnbilled + authorized,
    };
  }

  if (request.invoicedOn) {
    return {
      ...summary,
      capitalAmountBilled: summary.capitalAmountBilled + authorized,
    };
  }

  return {
    ...summary,
    capitalAmountUnbilled: summary.capitalAmountUnbilled + authorized,
  };
}

export function createInitialMonthlySummary(): MonthlySummary {
  const now = new Date();
  return {
    activeContracts: 0,
    contractsToVerify: 0,
    contractsToVerifyExpectedAmount: 0,
    contractsToAuthorize: 0,
    contractsToAuthorizeExpectedAmount: 0,
    contractsWithNoAction: 0,
    contractsAuthorized: 0,
    contractsAuthorizedAuthorizationAmount: 0,
    contractsSentToBpo: 0,
    contractsSentToBpoAuthorizationAmount: 0,
    contractsInvoiced: 0,
    contractsInvoicedAuthorizationAmount: 0,
    contractsOnHold: 0,
    contractsOnHoldRemainingAmount: 0,
    contractsRequestedToClose: 0,
    contractsRequestedToCloseRemainingAmount: 0,
    contractsApprovedToClose: 0,
    contractsApprovedToCloseRemainingAmount: 0,
    apturaAmountBilled: 0,
    apturaAmountUnbilled: 0,
    capitalAmountBilled: 0,
    capitalAmountUnbilled: 0,
    currentMonth: new Date(now.getFullYear(), now.getMonth(), 1),
  };
}

export function calculateMonthlySummary(
  allProjects: Project[], allRequests: BillingRequest[],
): MonthlySummary {
  const summary = allProjects.reduce(
    (s1, p) => p.contracts.reduce(
      (s2, c) => updateMonthlySummary(s2, c), s1,
    ), createInitialMonthlySummary(),
  );

  return allRequests.reduce(
    (s, r) => updateMonthlyFinancialSummary(s, r, allProjects), summary,
  );
}
