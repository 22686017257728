import { BillingModes, PhaseNames } from '../../features/projects/types';
import { ContractPhaseViewModel } from './viewModels';

function round(n: number, precision: number): number {
  const temp = n.toFixed(precision);
  return Number.parseFloat(temp);
}

export function computePercentOfContract(phaseTotal: number, contractTotal: number): number {
  if (contractTotal === 0) return 0;

  return round((phaseTotal / contractTotal) * 100, 0);
}

export function computePhaseAmount(percent: number, contractTotal: number): number {
  return round(contractTotal * (percent / 100), 2);
}

// Why 1, 2, and 3 for days of the month?
// - Because the day doesn't matter.
// - This should ensure that if today is in the month of the start and end month.
// - In the event that they are all the same month, it ensures start < today < end.
// Why 10:00:00 for the time?
// - This is to prevent timezones from rolling dates back to the previous month.
function buildStartDate(startYear: number, startMonth: number): Date {
  return new Date(startYear, startMonth, 1, 10, 0, 0);
}

function buildEndDate(endYear: number, endMonth: number): Date {
  return new Date(endYear, endMonth, 3, 10, 0, 0);
}

function buildThisMonth(todayYear: number, todayMonth: number): Date {
  return new Date(todayYear, todayMonth, 2, 10, 0, 0);
}

function monthSpan(today: Date, start: Date, end: Date): number {
  const beginningSpan = (today < start) ? start : today;

  if (today > end) {
    return 0;
  }

  const spanYears = end.getFullYear() - beginningSpan.getFullYear();
  return 1 + (spanYears * 12) + (end.getMonth() - beginningSpan.getMonth());
}

function computeAmountPerMonth(
  remainingAmount: number, today: Date, startDate: Date, endDate: Date,
): number {
  const spanMonths = monthSpan(today, startDate, endDate);

  if (spanMonths <= 0) return 0;

  return remainingAmount / spanMonths;
}

function computeWhetherPhaseIsActive(today: Date, start: Date, end: Date): boolean {
  if (today < start) return false;
  return today < end;
}

export function computeAmountPerMonthForPhase(
  startMonth: number, startYear: number, endMonth: number, endYear: number, remainingAmount: number,
): { activeThisMonth: boolean, amountPerMonth: number } {
  const today = new Date(Date.now());
  const todayMonth = buildThisMonth(today.getFullYear(), today.getMonth());
  // Subtracting 1 because the DB is storing 1-based months (Jan = 1, Feb = 2, etc.)
  // But, the UI uses 0-based months (Jan = 0, Feb = 1, etc.)
  const startDate = buildStartDate(startYear, startMonth - 1);
  const endDate = buildEndDate(endYear, endMonth - 1);

  return {
    amountPerMonth: computeAmountPerMonth(remainingAmount, todayMonth, startDate, endDate),
    activeThisMonth: computeWhetherPhaseIsActive(todayMonth, startDate, endDate),
  };
}

function createPhase(
  phaseAmount: number, phaseNameId: number, contractTotal: number,
): ContractPhaseViewModel {
  const today = new Date();

  return {
    phaseNameId,
    remainingAmount: phaseAmount,
    canModifyRemainingAmount: true,
    startMonth: today.getMonth() + 1, // Months are 0-based
    startYear: today.getFullYear(),
    endMonth: today.getMonth() + 1, // Months are 0-based
    endYear: today.getFullYear(),
    amountPerMonth: phaseAmount,
    canModifyEndMonth: true,
    canModifyPhaseAmount: true,
    canModifyStartMonth: true,
    activeThisMonth: false,
    invalid: false,
    percentOfContract: computePercentOfContract(phaseAmount, contractTotal),
    phaseAmount,
  };
}

export function createPhasesForMode(
  billingModeId: number | undefined, contractTotal: number,
): ContractPhaseViewModel[] {
  if (billingModeId === BillingModes.FlatBill) {
    const phase = createPhase(contractTotal, PhaseNames.FlatBill, contractTotal);
    phase.canModifyPhaseAmount = false; // Don't allow changing flat bill amounts
    return [phase];
  }

  if (billingModeId === BillingModes.ByPhase) {
    return [
      createPhase(0, PhaseNames.ConceptualAndSchematicDesign, contractTotal),
      createPhase(0, PhaseNames.DesignDevelopment, contractTotal),
      createPhase(0, PhaseNames.ConstructionDocuments, contractTotal),
      createPhase(0, PhaseNames.FFandEDesign, contractTotal),
      createPhase(0, PhaseNames.ContractAdministration, contractTotal),
    ];
  }

  return [];
}
