import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../../app/store';
import { LoadingStatus } from '../LoadingStatus';
import {
  fetchBillingRequestById, fetchBillingRequests,
  updateBillingRequest as updateBillingRequestApi,
  generateReceiptTemplate as generateReceiptTemplateApi,
  submitBillingRequests,
  verifyCustPOrderNumbers,
} from './api';
import {
  BillingRequest, BillingRequestBuckets, BillingRequestNavigation,
  BilllingRequestUpdate, ContractCorpLocation, ContractInvoiceDescriptionOverride,
  CustomerPOVerificationResult, NewBillingRequest, ReceiptTemplateRequest,
} from './types';
import segmentBillingRequests from './utils';

type ProjectsState = {
  loading: LoadingStatus;
  buckets: BillingRequestBuckets | undefined;
};

const initialState: ProjectsState = {
  loading: LoadingStatus.Uninitialized,
  buckets: undefined,
};

const requestsAdapter = createEntityAdapter<BillingRequest>();

export const billingRequestsSlice = createSlice({
  name: 'billingRequests',
  initialState: requestsAdapter.getInitialState(initialState),
  reducers: {
    billingRequestsLoaded: (state, action) => {
      state.loading = LoadingStatus.Idle;
      requestsAdapter.setAll(state, action);
      const requests = requestsAdapter
        .getSelectors(() => state)
        .selectAll(state);
      state.buckets = segmentBillingRequests(requests);
    },
    billingRequestsLoading: (state) => {
      state.loading = LoadingStatus.Pending;
    },
    billingRequestsLoadingFailed: (state) => {
      state.loading = LoadingStatus.Errored;
    },
    billingRequestUpdated: requestsAdapter.updateOne,
    billingRequestsCreated: requestsAdapter.addMany,
  },
});

export const {
  billingRequestsLoaded,
  billingRequestsLoading,
  billingRequestsLoadingFailed,
  billingRequestUpdated,
  billingRequestsCreated,
} = billingRequestsSlice.actions;

const selectors = requestsAdapter.getSelectors((state: RootState) => state.billingRequests);

export const selectAllRequests = selectors.selectAll;
export const selectRequestEntities = selectors.selectEntities;
export const selectRequestById = selectors.selectById;

function buildNavigation(ids: number[], currentIndex: number): BillingRequestNavigation {
  return {
    currentIndex,
    nextId: (currentIndex + 1 >= ids.length) ? undefined : ids[currentIndex + 1],
    previousId: (currentIndex - 1 < 0) ? undefined : ids[currentIndex - 1],
    totalCount: ids.length,
  };
}

export const selectNavigation = (
  state: RootState, requestId: number,
): BillingRequestNavigation => {
  // Try to find the request in the "ready with receipts" bucket
  const readyWithReceiptsIndex = state
    .billingRequests.buckets?.readyWithReceipts.findIndex((id) => id === requestId) ?? -1;

  if (readyWithReceiptsIndex !== -1) {
    return buildNavigation(
      state.billingRequests.buckets?.readyWithReceipts ?? [], readyWithReceiptsIndex,
    );
  }

  // Try to find the request in the "ready without receipts" bucket
  const readyWithoutReceiptsIndex = state
    .billingRequests.buckets?.readyWithoutReceipts.findIndex((id) => id === requestId) ?? -1;

  if (readyWithoutReceiptsIndex !== -1) {
    return buildNavigation(
      state.billingRequests.buckets?.readyWithoutReceipts ?? [], readyWithoutReceiptsIndex,
    );
  }

  // Try to find the request in the "needs receipts" bucket
  const needsReceiptsIndex = state
    .billingRequests.buckets?.needReceipts.findIndex((id) => id === requestId) ?? -1;

  if (needsReceiptsIndex !== -1) {
    return buildNavigation(
      state.billingRequests.buckets?.needReceipts ?? [], needsReceiptsIndex,
    );
  }

  // Didn't find it, so just return back an empty result
  return {
    currentIndex: undefined,
    nextId: undefined,
    previousId: undefined,
    totalCount: 0,
  };
};

export default billingRequestsSlice.reducer;

export const loadBillingRequests = () => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(billingRequestsLoading());
  try {
    const response = await fetchBillingRequests();
    dispatch(billingRequestsLoaded(response));
  } catch {
    dispatch(billingRequestsLoadingFailed());
  }
};

export const refreshBillingRequests = async (dispatch: AppDispatch): Promise<boolean> => {
  try {
    const response = await fetchBillingRequests();
    if (response) {
      dispatch(billingRequestsLoaded(response));
      return true;
    }
    return false;
  } catch {
    return false;
  }
};

export const loadBillingRequestById = async (
  dispatch: AppDispatch, id: number,
): Promise<BillingRequest | undefined> => {
  try {
    const response = await fetchBillingRequestById(id);
    if (response) {
      dispatch(billingRequestUpdated({ id: response.id, changes: response }));
    }
    return response;
  } catch {
    return undefined;
  }
};

export const sendBillingRequestsToBpo = async (
  dispatch: AppDispatch, projectId: number, authorizationMonth: string | null,
  descriptionOverrides: ContractInvoiceDescriptionOverride[],
): Promise<BillingRequest[] | undefined> => {
  const payload: NewBillingRequest = { projectId, authorizationMonth, descriptionOverrides };
  try {
    const response = await submitBillingRequests(payload);
    if (!response) {
      return undefined;
    }

    dispatch(billingRequestsCreated(response));
    return response;
  } catch {
    return undefined;
  }
};

export const updateBillingRequest = async (
  dispatch: AppDispatch, update: BilllingRequestUpdate,
): Promise<BillingRequest | undefined> => {
  try {
    const response = await updateBillingRequestApi(update);
    if (!response) {
      return undefined;
    }

    dispatch(billingRequestUpdated({ id: response.id, changes: response }));
    return response;
  } catch {
    return undefined;
  }
};

export const generateReceiptTemplate = async (
  data: ReceiptTemplateRequest,
): Promise<Blob | undefined> => {
  try {
    return await generateReceiptTemplateApi(data);
  } catch {
    return undefined;
  }
};

export const verifyCustomerPurchaseOrderNumbers = async (
  projectId: number, contractCorpLocations: ContractCorpLocation[],
): Promise<CustomerPOVerificationResult[] | undefined> => {
  try {
    return await verifyCustPOrderNumbers({ projectId, contractCorpLocations });
  } catch {
    return undefined;
  }
};
