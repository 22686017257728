import React from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { LookupTypes } from './types';
import { loadLookups, selectLookupList } from './slice';
import { LoadingStatus } from '../LoadingStatus';

type LookupOptionProps = {
  lookupType: LookupTypes;
}

function LookupOptions({ lookupType }: LookupOptionProps): JSX.Element {
  const lookupsState = useAppSelector((state) => state.lookups.loading);
  const lookups = useAppSelector((state) => selectLookupList(state, lookupType));
  const dispatch = useAppDispatch();

  if (lookupsState === LoadingStatus.Uninitialized) {
    dispatch(loadLookups());
    return (<option value="" disabled>Loading...</option>);
  }
  if (lookupsState === LoadingStatus.Pending) {
    return (<option value="" disabled>Loading...</option>);
  }
  if (lookupsState === LoadingStatus.Errored) {
    return (<option value="" disabled>Failed to load lookups</option>);
  }

  const mappedLookups = (lookups ?? [])
    .filter((lu) => lu.enabled) // Only enabled lookups
    .sort((a, b) => a.sortOrder - b.sortOrder)
    .map((lu) => (<option key={lu.id} value={lu.id}>{lu.name}</option>));

  return (
    <>
      <option value="">{`Select ${lookupType}...`}</option>
      {mappedLookups}
    </>
  );
}

export default LookupOptions;
