import React from 'react';
import { authorizeAmounts, deleteAuthorizations } from './slice';
import { Contract, PhaseAuthorization, Project } from './types';
import { contractCanAuthorize } from './utils';
import { useAppDispatch } from '../../app/hooks';
import displayError from '../../components/displayError';

type AuthorizationButtonsProps = {
  contract: Contract;
  project: Project;
}

function AuthorizationButtons({ contract, project }: AuthorizationButtonsProps): JSX.Element {
  const dispatch = useAppDispatch();
  const [modifyingAuthorizations, setModifyingAuthorizations] = React.useState<boolean>(false);

  const hasAuthorization = React.useMemo(
    () => contract.phases.some((p) => p.currentMonthAuthorization !== null), [contract],
  );

  const authorize = React.useCallback((amounts: PhaseAuthorization[]) => {
    if (project === undefined) return;

    setModifyingAuthorizations(true);

    authorizeAmounts(dispatch, project, contract.id, amounts)
      .then((success) => {
        if (!success) {
          displayError('Failed to authorize amount');
        }
        setModifyingAuthorizations(false);
      })
      .catch(() => {
        displayError('Failed to authorize amount');
        setModifyingAuthorizations(false);
      });
  }, [contract, dispatch, project]);
  const authorizeZero = React.useCallback(() => {
    const phaseAuths = contract.phases.map((p) => ({ phaseId: p.id, amount: 0 }));
    authorize(phaseAuths);
  }, [contract, authorize]);
  const authorizeExpected = React.useCallback(() => {
    const phaseAuths = contract.phases.map(
      (p) => ({ phaseId: p.id, amount: p.expectedCurrentMonth ?? 0 }),
    );
    authorize(phaseAuths);
  }, [contract, authorize]);

  const deleteAuths = React.useCallback(() => {
    if (project === undefined) return;

    setModifyingAuthorizations(true);

    deleteAuthorizations(dispatch, project, contract.id, null)
      .then((success) => {
        if (!success) {
          displayError('Failed to delete authorizations');
        }
        setModifyingAuthorizations(false);
      })
      .catch(() => {
        displayError('Failed to delete authorizations');
        setModifyingAuthorizations(false);
      });
  }, [contract, dispatch, project]);

  const { can, reason } = contractCanAuthorize(project, contract);

  if (!can) {
    return (
      <li className="link red-text bold-text">
        {reason}
      </li>
    );
  }

  return (
    <>
      <li className={`link ${hasAuthorization ? 'hidden' : ''}`}>
        <button
          type="button"
          disabled={modifyingAuthorizations}
          onClick={authorizeExpected}
        >
          Authorize Expected
        </button>
      </li>
      <li className={`link ${hasAuthorization ? '' : 'hidden'}`}>
        <button
          type="button"
          disabled={modifyingAuthorizations}
          onClick={deleteAuths}
        >
          Remove Authorization
        </button>
      </li>
      <li className="link">
        <button
          type="button"
          disabled={modifyingAuthorizations}
          onClick={authorizeZero}
        >
          Authorize $0
        </button>
      </li>
    </>
  );
}

export default AuthorizationButtons;
