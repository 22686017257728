import './ProjectDetails.scss';
import * as React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useAppSelector } from '../app/hooks';
import { RootState } from '../app/store';
import ProjectSummary from '../features/projects/ProjectSummary';
import ContractCard from '../features/projects/ContractCard';
import { selectFilterNavigation, selectProjectById } from '../features/projects/slice';

const ProjectDetails: React.FC = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const id = Number(projectId);
  const project = useAppSelector(
    (state: RootState) => selectProjectById(state, id),
  );
  const {
    currentIndex,
    nextId,
    previousId,
    totalCount,
  } = useAppSelector(
    (state: RootState) => selectFilterNavigation(state, project?.id ?? 0),
  );

  // Is the id invalid?
  if (Number.isNaN(id) || project === undefined) {
    return (
      <div className="project-details">
        <div className="error-msg">
          {`Invalid project id: ${id}`}
        </div>
        <div>
          <Link to="/dashboard">Go back to dashboard</Link>
        </div>
      </div>
    );
  }

  const contracts = project.contracts.map((c) => (
    <ContractCard
      key={c.id}
      contract={c}
      projectId={project.id}
    />
  ));
  const nextLink = nextId === undefined
    ? (<>Next</>)
    : (<Link to={`/projects/${nextId}`}>Next</Link>);
  const prevLink = previousId === undefined
    ? (<>Previous</>)
    : (<Link to={`/projects/${previousId}`}>Previous</Link>);
  const currentText = currentIndex === undefined
    ? ''
    : `${currentIndex + 1} of ${totalCount}`;
  const projectTitle = `${project.pmtNumber} - ${project.name}`;

  return (
    <main className="project-details">
      <header className="project-details-header">
        <h1 className="project-title">
          {projectTitle}
        </h1>
        <nav>
          <ul className="link-list">
            <li className="link"><Link to={`/projects/${projectId}/sendToBpo`}>Send to BPO</Link></li>
            <li className="link"><Link to="/dashboard">Dashboard</Link></li>
            <li className="link">{currentText}</li>
            <li className="link">{prevLink}</li>
            <li className="link">{nextLink}</li>
          </ul>
        </nav>
      </header>
      <ProjectSummary project={project} />
      <div className="card-container">
        {contracts}
      </div>
    </main>
  );
};

export default ProjectDetails;
