import './ConfigureBillingTemplate.scss';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@dsx/react';
import BillingTemplateCard from './BillingTemplateCard';
import { toBillingTemplateViewModel } from './mapping';
import { useConfigureBillingTemplate } from './hooks';

const ConfigureBillingTemplate: React.FC = () => {
  const {
    project, contract, newBillingTemplate, relatedContractIds, projectUrl,
    onNew, onBack, onFinish,
  } = useConfigureBillingTemplate();

  if (!project || !contract) {
    return (
      <main className="configure-template">
        <div className="error-msg">
          The contract you are looking for cannot be found.
        </div>
        <div>
          <Link to="/dashboard">Go back to dashboard</Link>
        </div>
      </main>
    );
  }

  const templates = project.billingTemplates;

  const newTemplateSection = newBillingTemplate === undefined
    ? (<></>)
    : (
      <BillingTemplateCard
        project={project}
        template={newBillingTemplate}
        contractId={contract.id}
        isSelected={false}
        relatedContractIds={[]}
      />
    );

  const templateSections = templates.map(
    (t) => (
      <BillingTemplateCard
        key={t.id}
        project={project}
        contractId={contract.id}
        isSelected={contract.billingTemplateId === t.id}
        template={toBillingTemplateViewModel(t)}
        relatedContractIds={relatedContractIds}
      />
    ),
  );

  const header = (
    <header className="configure-template-header">
      <h1 className="contract-title">
        {`[${project.pmtNumber} -- ${project.name}] ${contract.serviceContractName}`}
      </h1>
      <nav>
        <ul className="link-list">
          <li className="link"><Link to="/dashboard">Dashboard</Link></li>
          <li className="link"><Link to={projectUrl}>Back to Project</Link></li>
        </ul>
      </nav>
    </header>
  );
  return (
    <main className="configure-template">
      {header}
      <section className="configure-template-summary">
        <div>
          <Button
            type="button"
            variant="default"
            disabled={newBillingTemplate !== undefined}
            onClick={onNew}
          >
            New Template
          </Button>
        </div>
        {templateSections}
        {newTemplateSection}
      </section>
      <section className="configure-template-actions">
        <Button
          type="button"
          variant="default"
          onClick={onBack}
        >
          Back
        </Button>
        <Button
          type="button"
          variant="primary"
          disabled={contract.billingTemplateId === null}
          onClick={onFinish}
        >
          Finish
        </Button>
      </section>
    </main>
  );
};

export default ConfigureBillingTemplate;
