import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../../app/store';
import {
  Lookup, LookupTable, LookupTypes,
} from './types';
import fetchLookups from './api';
import { LoadingStatus } from '../LoadingStatus';

type LookupsState = {
  loading: LoadingStatus;
};

const initialState: LookupsState = {
  loading: LoadingStatus.Uninitialized,
};

const lookupsAdapter = createEntityAdapter<LookupTable>({
  selectId: (table) => table.name,
});

export const lookupsSlice = createSlice({
  name: 'lookups',
  initialState: lookupsAdapter.getInitialState(initialState),
  reducers: {
    lookupsLoaded: (state, action) => {
      state.loading = LoadingStatus.Idle;
      lookupsAdapter.setAll(state, action);
    },
    lookupsLoading: (state) => {
      state.loading = LoadingStatus.Pending;
    },
    lookupsLoadingFailed: (state) => {
      state.loading = LoadingStatus.Errored;
    },
  },
});

export const { lookupsLoaded, lookupsLoading, lookupsLoadingFailed } = lookupsSlice.actions;

const selectors = lookupsAdapter.getSelectors((state: RootState) => state.lookups);

export const selectLookups = (
  state: RootState, type: LookupTypes,
): LookupTable | undefined => selectors.selectById(state, type);

export const selectLookupList = (state: RootState, type: LookupTypes): Lookup[] | undefined => {
  const table = selectLookups(state, type);
  if (table === undefined) return undefined;

  return Object.values(table.data);
};

export const selectLookupValue = (
  state: RootState, type: LookupTypes, id: number,
): Lookup | undefined => {
  const table = selectLookups(state, type);
  if (table === undefined) return undefined;

  return table.data[id];
};

export default lookupsSlice.reducer;

export const loadLookups = () => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(lookupsLoading());
  try {
    const response = await fetchLookups();
    dispatch(lookupsLoaded(response));
  } catch {
    dispatch(lookupsLoadingFailed());
  }
};
