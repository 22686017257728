import { User } from './types';

export async function fetchUsers(): Promise<User[] | undefined> {
  try {
    const result = await fetch('/api/v1/users', { credentials: 'include' });

    if (!result.ok) {
      return undefined;
    }

    return result.json();
  } catch {
    return undefined;
  }
}

export async function fetchMe(): Promise<User | undefined> {
  try {
    const result = await fetch('/api/v1/users/me', { credentials: 'include' });

    if (!result.ok) {
      return undefined;
    }

    return result.json();
  } catch {
    return undefined;
  }
}

export async function updateUser(user: User): Promise<User | undefined> {
  try {
    const result = await fetch(`/api/v1/users/${user.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(user),
      credentials: 'include',
    });

    if (!result.ok) {
      return undefined;
    }

    return result.json();
  } catch {
    return undefined;
  }
}
