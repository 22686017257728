export interface Lookup {
  id: number;
  name: string;
  enabled: boolean;
  sortOrder: number;
}

export enum LookupTypes {
  PhaseName = 'PhaseName',
  InvoiceType = 'InvoiceType',
  BillingMode = 'BillingMode',
  ComponentType = 'ComponentType',
  ContractStatus = 'ContractStatus',
  ContractType = 'ContractType',
  OwnerDepartment = 'OwnerDepartment',
  ProjectStatus = 'ProjectStatus',
  ProjectType = 'ProjectType',
  ApprovalItemStatus = 'ApprovalItemStatus',
  UserRole = 'UserRole',
  BillingRequestStatus = 'BillingRequestStatus',
  CloseRequestStatus = 'CloseRequestStatus'
}

export type LookupDictionary = {
  [K in number]: Lookup
}

export interface LookupTable {
  name: LookupTypes;
  data: LookupDictionary;
}
