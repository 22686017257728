import React from 'react';
import { LookupTypes } from './types';
import LookupOptions from './LookupOptions';

type LookupSelectionProps = {
  selectId: string;
  type: LookupTypes;
  lookupId: number | undefined;
  labelText: string;
  selectionChanged: (userId: number | undefined) => void;
  // eslint-disable-next-line react/require-default-props
  disabled?: boolean;
};

function LookupSelection({
  selectId, type, lookupId, labelText, selectionChanged, disabled,
}: LookupSelectionProps): JSX.Element {
  return (
    <>
      <label htmlFor={selectId}>
        {labelText}
      </label>
      <select
        id={selectId}
        value={lookupId ?? 0}
        disabled={disabled}
        onChange={(e) => {
          if (e.target.value) {
            selectionChanged(Number(e.target.value));
          } else {
            selectionChanged(undefined);
          }
        }}
      >
        <LookupOptions lookupType={type} />
      </select>
    </>
  );
}

export default LookupSelection;
