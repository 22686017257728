import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import {
  generateReceiptTemplate, loadBillingRequestById, selectNavigation, selectRequestById,
  updateBillingRequest,
} from '../../features/billingRequests/slice';
import { selectProjectById } from '../../features/projects/slice';
import { Contract, ContractStatuses } from '../../features/projects/types';
import {
  AuthorizedContract, BilllingRequestUpdate, ReceiptTemplateRequest, Statuses,
} from '../../features/billingRequests/types';
import { BillingRequestViewModel, ContractDetailsViewModel } from './viewModels';
import downloadHelper from '../../features/downloadHelper';
import openPmtScreen from '../../webToDucMessenger';
import displayError from '../../components/displayError';

interface MarriedContractData {
  contracts: ContractDetailsViewModel[];
  anyContractsNeedApproval: boolean;
  responsibleParty: string;
  shipLocation: string;
  totalAuthorized: number;
}

export function marryContractData(
  allContracts: Contract[], authorizedContracts: AuthorizedContract[],
): MarriedContractData {
  let anyContractsNeedApproval = false;
  let shipLocation = '';
  let responsibleParty = '';
  let totalAuthorized = 0;

  const allContractsMap = new Map<number, Contract>();
  allContracts.forEach((c) => allContractsMap.set(c.id, c));

  const viewModels: ContractDetailsViewModel[] = [];

  authorizedContracts.forEach((ac) => {
    const c = allContractsMap.get(ac.contractId);
    if (c) {
      viewModels.push({
        authorizedTotal: ac.authorizedTotal,
        contractId: c.id,
        contractName: c.serviceContractName,
        contractTotal: c.totalAmount,
        dateEntered: c.dateEntered,
        invoiceDescription: ac.invoiceDescription,
        statusId: c.serviceContractStatusId,
        serviceContractDescription: c.serviceContractDescription,
      });
      totalAuthorized += ac.authorizedTotal;

      if (shipLocation === '') {
        shipLocation = c.serviceLocation;
      }

      if (responsibleParty === '') {
        responsibleParty = ac.responsibleParty;
      }

      if (c.serviceContractStatusId === ContractStatuses.New) {
        anyContractsNeedApproval = true;
      }
    }
  });

  return {
    contracts: viewModels,
    anyContractsNeedApproval,
    responsibleParty,
    shipLocation,
    totalAuthorized,
  };
}

export default function useBillingRequestDetails(): BillingRequestViewModel {
  const { requestId } = useParams<{ requestId: string }>();
  const id = Number(requestId);
  const dispatch = useAppDispatch();

  // Selectors
  const request = useAppSelector(
    (state: RootState) => selectRequestById(state, id),
  );
  const {
    currentIndex,
    nextId,
    previousId,
    totalCount,
  } = useAppSelector(
    (state: RootState) => selectNavigation(state, id),
  );
  const project = useAppSelector(
    (state: RootState) => selectProjectById(state, request?.projectId ?? 0),
  );

  // States
  // Default to true - we'll start the page by loading
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [failedLoading, setFailedLoading] = React.useState<boolean>(false);
  const [invoiceNumber, setInvoiceNumber] = React.useState<number | null>(null);
  const [processingAction, setProcessingAction] = React.useState<boolean>(false);

  // Effects
  React.useEffect(() => {
    loadBillingRequestById(dispatch, id)
      .then((refreshedRequest) => {
        if (!refreshedRequest) {
          setFailedLoading(false);
          setInvoiceNumber(null);
        } else {
          setInvoiceNumber(refreshedRequest.invoiceNumber);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setFailedLoading(true);
        setIsLoading(false);
        setInvoiceNumber(null);
      });
  }, [dispatch, id]);

  // Memos
  const {
    anyContractsNeedApproval, contracts, responsibleParty, shipLocation,
    totalAuthorized,
  } = React.useMemo(
    () => marryContractData(project?.contracts ?? [], request?.authorizedContracts ?? []),
    [project, request],
  );
  const billingTemplate = React.useMemo(
    () => project?.billingTemplates?.find((t) => t.id === request?.billingTemplateId),
    [project, request],
  );

  // Create the callbacks
  const onDownloadReceipts = React.useCallback(() => {
    setProcessingAction(true);
    const payload: ReceiptTemplateRequest = {
      billingRequestId: id,
      projectName: project?.name ?? '',
      contracts: contracts.map((c) => ({
        id: c.contractId,
        name: c.contractName,
        serviceContractDescription: c.serviceContractDescription,
      })),
    };
    generateReceiptTemplate(payload)
      .then((template) => {
        if (template) {
          downloadHelper(template, `${invoiceNumber} Reimbursable Receipts.docx`);
        } else {
          displayError('Failed to generate receipts template.');
        }
        setProcessingAction(false);
      })
      .catch(() => {
        displayError('Failed to generate receipts template.');
        setProcessingAction(false);
      });
  }, [contracts, id, invoiceNumber, project]);
  const onInvoiceNumberChanged = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const newNumber = Number(event.target.value);
    if (!Number.isNaN(newNumber)) {
      setInvoiceNumber(newNumber);
    }
  }, []);
  const onOpenProject = React.useCallback(() => {
    openPmtScreen(project?.pmtNumber ?? 0);
  }, [project]);
  const save = React.useCallback((payload: BilllingRequestUpdate) => {
    setProcessingAction(true);
    updateBillingRequest(dispatch, payload)
      .then((response) => {
        if (!response) {
          displayError('Failed to save the billing request updates.');
        }
        setProcessingAction(false);
      })
      .catch(() => {
        displayError('Failed to save the billing request updates.');
        setProcessingAction(false);
      });
  }, [dispatch]);
  const onSave = React.useCallback(() => {
    if (invoiceNumber === null) {
      displayError('Invoice number not set');
    } else {
      const payload: BilllingRequestUpdate = {
        blocked: false,
        id,
        invoiceNumber,
      };
      save(payload);
    }
  }, [id, invoiceNumber, save]);
  const onWaitingForInfo = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const payload: BilllingRequestUpdate = {
      blocked: event.target.checked,
      id,
      invoiceNumber: null,
    };
    save(payload);
  }, [id, save]);

  // Simple calculations
  const nextUrl = nextId === undefined
    ? undefined
    : `/billing/${nextId}`;
  const prevUrl = previousId === undefined
    ? undefined
    : `/billing/${previousId}`;
  const currentText = currentIndex === undefined
    ? ''
    : `${currentIndex + 1} of ${totalCount}`;

  return {
    requestId,

    // State
    failedLoading,
    invoiceNumber,
    isLoading,
    processingAction,

    // Computed data
    billToAttention: billingTemplate?.billToAttention ?? '',
    billingAddress: billingTemplate?.billingAddress ?? '',
    anyContractsToApprove: anyContractsNeedApproval ? 'Yes' : 'No',
    contractsTotal: totalAuthorized,
    customerPONumber: billingTemplate?.customerPONumber ?? '',
    dataManagerId: project?.dataManagerId ?? 0,
    invoiceTypeId: billingTemplate?.invoiceTypeId ?? 0,
    needsReceipts:
      (
        request?.needsReceipts && request?.authorizedContracts?.some((c) => c.isReimbursable)
      ) ? 'Yes' : 'No',
    requestStatusId: request?.statusTypeId ?? 0,
    responsibleParty,
    shipLocation,
    title: `Billing request for PMT ${project?.pmtNumber}`,
    udiEnabled: project?.userDefinedInvoicingEnabled ? 'Yes' : 'No',
    waitingForInfo: request?.statusTypeId === Statuses.OnHold,
    // Disable the checkbox if any action is processing or we've already created the invoice
    // (Complete or Needs Receipts means that someone has created the invoice for the request.)
    waitingForInfoDisabled: request?.statusTypeId === Statuses.Complete
      || request?.statusTypeId === Statuses.NeedsReceipts
      || processingAction,
    // Disable the button if any action is processing or
    // the request is not currently in the needs receipt state.
    downloadReceiptsDisabled: request?.statusTypeId !== Statuses.NeedsReceipts
      || processingAction,
    notes: billingTemplate?.notes ?? '',
    doNotSendAllowed: project?.doNotSendAllowed ?? 0,

    navigation: { currentText, nextUrl, prevUrl },

    contracts,

    // Action callbacks
    onDownloadReceipts,
    onInvoiceNumberChanged,
    onOpenProject,
    onSave,
    onWaitingForInfo,
  };
}
