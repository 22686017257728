import React from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { checkIfAuthenticated } from '../features/users/slice';
import { AuthStatus } from '../features/users/types';

type AuthenticatedProps = {
  children: JSX.Element | JSX.Element[];
};

function Authenticated({ children }: AuthenticatedProps): JSX.Element {
  const authStatus = useAppSelector((state) => state.users.authStatus);
  const dispatch = useAppDispatch();

  if (authStatus === AuthStatus.Unauthenticated) {
    sessionStorage.setItem('destinationUrl', window.location.href);
    window.location.assign('/SignInIntermediate');
    return (
      <div>Redirecting you to log in...</div>
    );
  }

  if (authStatus === AuthStatus.Inactive) {
    return (
      <div>You are not an active user in the system.</div>
    );
  }

  if (authStatus === AuthStatus.Authenticated) {
    const destinationUrl = sessionStorage.getItem('destinationUrl');
    if (destinationUrl !== null) {
      sessionStorage.removeItem('destinationUrl');
      window.location.href = destinationUrl;
    }

    return (
      <>{children}</>
    );
  }

  if (authStatus === AuthStatus.Unknown) {
    dispatch(checkIfAuthenticated());
  }

  return (
    <div>Checking if you are logged in...</div>
  );
}

export default Authenticated;
