import './LandingPage.scss';
import * as React from 'react';
import { Button, Card } from '@dsx/react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { selectCurrentUser } from '../features/users/slice';
import { User } from '../features/users/types';
import ContractBillingStatusEmoji from '../features/contractBillingStatusOptions/ContractBillingStatusEmoji';
import { selectAllProjects } from '../features/projects/slice';
import { BillingStatuses } from '../features/projects/types';
import { calculateMonthlySummary, formatAsCurrency } from '../features/projects/utils';
import { refreshBillingRequests, selectAllRequests } from '../features/billingRequests/slice';
import displayError from '../components/displayError';

const adminRole = 1;
const financialServices = 3;

function buildLinks(user: User | undefined): JSX.Element {
  if (user?.roleId === adminRole) {
    return (
      <nav>
        <ul className="link-list">
          <li className="link"><Link to="/users">Edit Users</Link></li>
          <li className="link"><Link to="/authorizations/unsent">Unsent Authorizations</Link></li>
          <li className="link"><Link to="/discrepancies">Contract Discrepancies</Link></li>
          <li className="link"><Link to="/invoices">Invoice Summary</Link></li>
        </ul>
      </nav>
    );
  }

  if (user?.roleId === financialServices) {
    return (
      <nav>
        <ul className="link-list">
          <li className="link"><Link to="/discrepancies">Contract Discrepancies</Link></li>
          <li className="link"><Link to="/invoices">Invoice Summary</Link></li>
        </ul>
      </nav>
    );
  }

  return (<></>);
}

function buildBillingLinks(bucket: number[] | undefined): JSX.Element {
  if (!bucket || bucket.length === 0) {
    return (<></>);
  }

  const firstId = bucket[0];
  const lastId = bucket[bucket.length - 1];

  return (
    <>
      <Link to={`/billing/${firstId}`}>First</Link>
      ,&nbsp;
      <Link to={`/billing/${lastId}`}>Last</Link>
    </>
  );
}

const LandingPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector((state) => selectCurrentUser(state));
  const projects = useAppSelector((state) => selectAllProjects(state));
  const billingRequests = useAppSelector((state) => selectAllRequests(state));
  const billingRequestsSummary = useAppSelector((state) => state.billingRequests.buckets);
  const [refreshingBillingRequests, setRefreshingBillingRequests] = React.useState<boolean>(false);

  const projectSummary = React.useMemo(
    () => calculateMonthlySummary(projects, billingRequests), [billingRequests, projects],
  );

  const {
    apturaAmountBilled, apturaAmountUnbilled,
    capitalAmountBilled, capitalAmountUnbilled,
  } = projectSummary;

  const apturaTotal = apturaAmountBilled + apturaAmountUnbilled;
  const capitalTotal = capitalAmountBilled + capitalAmountUnbilled;

  const links = buildLinks(currentUser);

  return (
    <main className="landing-page">
      <header className="landing-page-header">
        <h1 className="center-align-text landing-page-title">Contract Billing</h1>
        {links}
      </header>
      <h2 className="pm-title center-align-text"><Link to="/dashboard">Project Manager and Leader Dashboard</Link></h2>
      <div className="contract-status-summary-card">
        <Card variant="outline">
          <h3 className="center-align-text">
            {`${projectSummary.activeContracts} Active Contracts`}
          </h3>
          <table>
            <thead>
              <tr>
                <th>Status</th>
                <th>Icon</th>
                <th>Count</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Verify Contract</td>
                <td className="center-align-text">
                  <ContractBillingStatusEmoji id={BillingStatuses.VerifyContract} />
                </td>
                <td className="center-align-text">{projectSummary.contractsToVerify}</td>
                <td className="right-align-text">
                  {`(expected) ${formatAsCurrency(projectSummary.contractsToVerifyExpectedAmount)}`}
                </td>
              </tr>
              <tr>
                <td>Needs Authorization</td>
                <td className="center-align-text">
                  <ContractBillingStatusEmoji id={BillingStatuses.NeedsAuthorization} />
                </td>
                <td className="center-align-text">{projectSummary.contractsToAuthorize}</td>
                <td className="right-align-text">
                  {`(expected) ${formatAsCurrency(projectSummary.contractsToAuthorizeExpectedAmount)}`}
                </td>
              </tr>
              <tr>
                <td>Authorized to Bill</td>
                <td className="center-align-text">
                  <ContractBillingStatusEmoji id={BillingStatuses.AuthorizedToBill} />
                </td>
                <td className="center-align-text">{projectSummary.contractsAuthorized}</td>
                <td className="right-align-text">
                  {`(authorized) ${formatAsCurrency(projectSummary.contractsAuthorizedAuthorizationAmount)}`}
                </td>
              </tr>
              <tr>
                <td>Sent to BPO</td>
                <td className="center-align-text">
                  <ContractBillingStatusEmoji id={BillingStatuses.SentToBPO} />
                </td>
                <td className="center-align-text">{projectSummary.contractsSentToBpo}</td>
                <td className="right-align-text">
                  {`(authorized) ${formatAsCurrency(projectSummary.contractsSentToBpoAuthorizationAmount)}`}
                </td>
              </tr>
              <tr>
                <td>Billed</td>
                <td className="center-align-text">
                  <ContractBillingStatusEmoji id={BillingStatuses.Billed} />
                </td>
                <td className="center-align-text">{projectSummary.contractsInvoiced}</td>
                <td className="right-align-text">
                  {`(authorized) ${formatAsCurrency(projectSummary.contractsInvoicedAuthorizationAmount)}`}
                </td>
              </tr>
              <tr>
                <td>No Action</td>
                <td className="center-align-text">
                  <ContractBillingStatusEmoji id={BillingStatuses.NoAction} />
                </td>
                <td className="center-align-text">{projectSummary.contractsWithNoAction}</td>
                <td className="right-align-text">-</td>
              </tr>
              <tr>
                <td>On Hold</td>
                <td className="center-align-text">
                  <ContractBillingStatusEmoji id={BillingStatuses.OnHold} />
                </td>
                <td className="center-align-text">{projectSummary.contractsOnHold}</td>
                <td className="right-align-text">
                  {`(remaining on contract) ${formatAsCurrency(projectSummary.contractsOnHoldRemainingAmount)}`}
                </td>
              </tr>
              <tr>
                <td>Close Requested</td>
                <td className="center-align-text">
                  <ContractBillingStatusEmoji id={BillingStatuses.CloseRequested} />
                </td>
                <td className="center-align-text">{projectSummary.contractsRequestedToClose}</td>
                <td className="right-align-text">
                  {`(remaining on contract) ${formatAsCurrency(projectSummary.contractsRequestedToCloseRemainingAmount)}`}
                </td>
              </tr>
              <tr>
                <td>Approved to Close</td>
                <td className="center-align-text">
                  <ContractBillingStatusEmoji id={BillingStatuses.ApprovedToClose} />
                </td>
                <td className="center-align-text">{projectSummary.contractsApprovedToClose}</td>
                <td className="right-align-text">
                  {`(remaining on contract) ${formatAsCurrency(projectSummary.contractsApprovedToCloseRemainingAmount)}`}
                </td>
              </tr>
            </tbody>
          </table>
        </Card>
      </div>
      <div className="financial-summary-card">
        <Card variant="outline">
          <h3 className="center-align-text">Monthly financial status update</h3>
          <table>
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th>Aptura</th>
                <th>Capital</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Amount sent to BPO</td>
                <td className="right-align-text">{formatAsCurrency(apturaTotal)}</td>
                <td className="right-align-text">{formatAsCurrency(capitalTotal)}</td>
                <td className="right-align-text">{formatAsCurrency(apturaTotal + capitalTotal)}</td>
              </tr>
              <tr>
                <td>Amount billed</td>
                <td className="right-align-text">{formatAsCurrency(apturaAmountBilled)}</td>
                <td className="right-align-text">{formatAsCurrency(capitalAmountBilled)}</td>
                <td className="right-align-text">{formatAsCurrency(apturaAmountBilled + capitalAmountBilled)}</td>
              </tr>
              <tr>
                <td>Amount unbilled</td>
                <td className="right-align-text">{formatAsCurrency(apturaAmountUnbilled)}</td>
                <td className="right-align-text">{formatAsCurrency(capitalAmountUnbilled)}</td>
                <td className="right-align-text">{formatAsCurrency(apturaAmountUnbilled + capitalAmountUnbilled)}</td>
              </tr>
            </tbody>
          </table>
        </Card>
      </div>
      <h2 className="bpo-title center-align-text">
        BPO Workflows
        <Button
          type="button"
          variant="default"
          disabled={refreshingBillingRequests}
          onClick={() => {
            setRefreshingBillingRequests(true);
            refreshBillingRequests(dispatch)
              .then((success) => {
                if (!success) {
                  displayError('Failed to refresh billing requests.');
                }
                setRefreshingBillingRequests(false);
              })
              .catch(() => {
                displayError('Failed to refresh billing requests.');
                setRefreshingBillingRequests(false);
              });
          }}
        >
          Refresh
        </Button>
      </h2>
      <div className="with-receipts-card">
        <Card variant="outline">
          <h3 className="center-align-text">
            Invoices with reimbursable receipts
          </h3>
          <ul>
            <li>
              {`${billingRequestsSummary?.readyWithReceipts.length} ready to invoice`}
              &nbsp;
              (
              {buildBillingLinks(billingRequestsSummary?.readyWithReceipts)}
              )
              &nbsp;
              {`(${billingRequestsSummary?.onHoldWithReceipts} on hold)`}
            </li>
            <li>
              {`${billingRequestsSummary?.needReceipts.length} invoiced but need receipt statements completed`}
              &nbsp;
              (
              {buildBillingLinks(billingRequestsSummary?.needReceipts)}
              )
            </li>
            <li>
              {`${billingRequestsSummary?.completedWithReceiptsCount} completed in last 30 days`}
            </li>
          </ul>
        </Card>
      </div>
      <div className="without-receipts-card">
        <Card variant="outline">
          <h3 className="center-align-text">
            Invoices without reimbursable receipts
          </h3>
          <ul>
            <li>
              {`${billingRequestsSummary?.readyWithoutReceipts.length} ready to invoice`}
              &nbsp;
              (
              {buildBillingLinks(billingRequestsSummary?.readyWithoutReceipts)}
              )
              &nbsp;
              {`(${billingRequestsSummary?.onHoldWithoutReceipts} on hold)`}
            </li>
            <li>
              {`${billingRequestsSummary?.completedWithoutReceiptsCount} completed in last 30 days`}
            </li>
          </ul>
        </Card>
      </div>
    </main>
  );
};

export default LandingPage;
