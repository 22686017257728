import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../../app/store';
import { LoadingStatus } from '../LoadingStatus';
import fetchOptions from './api';
import { ContractBillingStatusOption } from './types';
import { determineHighestPriority } from './utils';

type LookupsState = {
  loading: LoadingStatus;
};

const initialState: LookupsState = {
  loading: LoadingStatus.Uninitialized,
};

const optionsAdapter = createEntityAdapter<ContractBillingStatusOption>();

export const optionsSlice = createSlice({
  name: 'contractBillingStatusOptions',
  initialState: optionsAdapter.getInitialState(initialState),
  reducers: {
    contractBillingStatusOptionsLoaded: (state, action) => {
      state.loading = LoadingStatus.Idle;
      optionsAdapter.setAll(state, action);
    },
    contractBillingStatusOptionsLoading: (state) => {
      state.loading = LoadingStatus.Pending;
    },
    contractBillingStatusOptionsLoadingFailed: (state) => {
      state.loading = LoadingStatus.Errored;
    },
  },
});

export const {
  contractBillingStatusOptionsLoaded,
  contractBillingStatusOptionsLoading,
  contractBillingStatusOptionsLoadingFailed,
} = optionsSlice.actions;

const optionsSelectors = optionsAdapter.getSelectors(
  (state: RootState) => state.contractBillingStatusOptions,
);

export const selectAllOptions = optionsSelectors.selectAll;

export function selectAllEnabledOptions(state: RootState): ContractBillingStatusOption[] {
  return selectAllOptions(state).filter((o) => o.enabled);
}

// eslint-disable-next-line max-len
export function selectOptionValue(state: RootState, id: number): ContractBillingStatusOption | undefined {
  return optionsSelectors.selectById(state, id);
}

// eslint-disable-next-line max-len
export const selectHighestPriorityType = (state: RootState, ids: number[]): ContractBillingStatusOption | undefined => {
  const options = ids.map((i) => selectOptionValue(state, i));
  return determineHighestPriority(options);
};

export default optionsSlice.reducer;

export const loadOptions = () => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(contractBillingStatusOptionsLoading());
  try {
    const response = await fetchOptions();
    dispatch(contractBillingStatusOptionsLoaded(response));
  } catch {
    dispatch(contractBillingStatusOptionsLoadingFailed());
  }
};
