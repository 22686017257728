import { LookupTable } from './types';

const fetchLookups = async (): Promise<LookupTable[] | undefined> => {
  try {
    const result = await fetch('/api/v1/lookups', { credentials: 'include' });

    if (!result.ok) {
      return undefined;
    }

    return result.json();
  } catch {
    return undefined;
  }
};

export default fetchLookups;
