export enum InvalidReasons {
  NoBillingMode = 1,
  InvalidDates = 2,
  PhasesDoNotAddToContract = 3,
  InvalidAmounts = 4,
}

export type ContractPhaseViewModel = {
  phaseNameId: number;
  startMonth: number;
  startYear: number;
  canModifyStartMonth: boolean;
  endMonth: number;
  endYear: number;
  canModifyEndMonth: boolean;
  phaseAmount: number;
  percentOfContract: number;
  canModifyPhaseAmount: boolean;
  remainingAmount: number;
  canModifyRemainingAmount: boolean;
  amountPerMonth: number;
  activeThisMonth: boolean;
  invalid: boolean;
}

export type ContractViewModel = {
  name: string;
  projectName: string;
  projectId: number;
  billingModeId: number | undefined;
  canModifyBillingModeId: boolean;
  totalAmount: number;
  amountRemaining: number;
  sumPhaseAmountRemaining: number;
  amountForMonth: number;
  brokenValidations: InvalidReasons[];
  phases: ContractPhaseViewModel[];
  totalOfPhases: number;
  saveDisabled: boolean;
  needsBillingTemplate: boolean;
}
