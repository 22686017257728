import { ContractBillingStatusOption } from './types';

const fetchOptions = async (): Promise<ContractBillingStatusOption[] | undefined> => {
  try {
    const result = await fetch('/api/v1/lookups/statusoptions', { credentials: 'include' });

    if (!result.ok) {
      return undefined;
    }

    return result.json();
  } catch {
    return undefined;
  }
};

export default fetchOptions;
