/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import displayError from '../../components/displayError';
import { useAppDispatch } from '../../app/hooks';
import { refreshContracts, submitNewCloseContractRequest, updateCloseRequest } from './slice';
import {
  BillingStatuses,
  CloseRequestStatuses, ContractCloseRequest, ContractCloseRequestUpdate,
  NewCloseContractRequest, Project,
} from './types';

export type CloseRequestProps = {
  project: Project | undefined;
  contractId: number;
  contractBillingStatusId: number;
  contractName: string;
  contractTypeName: string;
  serviceComponentTypeName: string;
  amountRemainingOnContract: number;
  existingRequest: ContractCloseRequest | null;
}

type CloseRequestComponents = {
  expandButton: JSX.Element;
  view: JSX.Element;
}

function determineExpandButtonText(
  existingRequest: ContractCloseRequest | null, contractBillingStatusId: number,
): string | null {
  if (existingRequest?.statusTypeId === CloseRequestStatuses.Requested) {
    return 'Approve/Deny';
  }

  if (
    existingRequest?.statusTypeId === CloseRequestStatuses.Approved
    || contractBillingStatusId === BillingStatuses.CompletelyBilled
    || contractBillingStatusId === BillingStatuses.Closed
  ) {
    return null;
  }

  return 'Request Close';
}

function CloseRequest({
  project, contractId, contractBillingStatusId, contractName, contractTypeName,
  serviceComponentTypeName, amountRemainingOnContract, existingRequest,
}: CloseRequestProps): CloseRequestComponents {
  const dispatch = useAppDispatch();
  const [showCloseRequest, setShowCloseRequest] = React.useState<boolean>(false);
  const [closeNote, setCloseNote] = React.useState<string>('');
  const [savingCloseRequest, setSavingCloseRequest] = React.useState<boolean>(false);

  const refreshData = React.useCallback(() => {
    if (project === undefined) {
      return;
    }

    refreshContracts(dispatch, project, [contractId])
      .then((success) => {
        if (!success) {
          displayError('Saved close request, but failed to refresh the contract.');
        }
      })
      .catch(() => {
        displayError('Saved close request, but failed to refresh the contract.');
      })
      .finally(() => {
        setShowCloseRequest(false);
        setSavingCloseRequest(false);
        setCloseNote('');
      });
  }, [contractId, dispatch, project]);
  const saveHandler = React.useCallback((promise: Promise<boolean>) => {
    setSavingCloseRequest(true);
    promise
      .then((result) => {
        if (!result) {
          displayError('Failed to submit or update the close request. Do you have permission?');
          setSavingCloseRequest(false);
        } else {
          refreshData();
        }
      })
      .catch(() => {
        displayError('Failed to submit or update the close request. Do you have permission?');
        setSavingCloseRequest(false);
      });
  }, [refreshData]);
  const newCloseRequestHandler = React.useCallback((notes: string) => {
    if (project === undefined) {
      return;
    }

    if (!notes) {
      displayError('You need to provide a close note.');
      return;
    }

    const request: NewCloseContractRequest = {
      amountRemainingOnContract,
      contractName,
      contractTypeName,
      serviceComponentTypeName,
      notes,
      projectName: project.name,
    };

    const promise = submitNewCloseContractRequest(project.id, contractId, request);
    saveHandler(promise);
  }, [
    amountRemainingOnContract, contractId, contractName, contractTypeName,
    project, saveHandler, serviceComponentTypeName,
  ]);
  const updateCloseRequestHandler = React.useCallback((newStatusId: number, notes: string) => {
    if (project === undefined || existingRequest === null) {
      return;
    }

    if (!notes) {
      displayError('You need to provide a close note.');
      return;
    }

    const request: ContractCloseRequestUpdate = {
      closeRequestId: existingRequest.id,
      notes,
      statusId: newStatusId,
      amountRemainingOnContract,
      contractName,
      contractTypeName,
      projectName: project.name,
      serviceComponentTypeName,
      dataManagerId: project.dataManagerId,
    };

    const promise = updateCloseRequest(project.id, contractId, request);
    saveHandler(promise);
  }, [
    amountRemainingOnContract, contractId, contractName, contractTypeName, existingRequest,
    project, saveHandler, serviceComponentTypeName,
  ]);

  const divId = `closeRequest_${contractId}`;

  const expandButtonText = determineExpandButtonText(existingRequest, contractBillingStatusId);

  const expandButton = expandButtonText
    ? (
      <button
        type="button"
        aria-expanded={showCloseRequest}
        aria-controls={divId}
        onClick={() => setShowCloseRequest(true)}
      >
        {expandButtonText}
      </button>
    )
    : (<></>);

  const buttons = existingRequest?.statusTypeId === CloseRequestStatuses.Requested
    ? (
      <>
        <button
          type="button"
          disabled={savingCloseRequest}
          onClick={() => updateCloseRequestHandler(CloseRequestStatuses.Approved, closeNote)}
        >
          Approve
        </button>
        <button
          type="button"
          disabled={savingCloseRequest}
          onClick={() => updateCloseRequestHandler(CloseRequestStatuses.Denied, closeNote)}
        >
          Deny
        </button>
      </>
    )
    : (
      <button
        type="button"
        disabled={savingCloseRequest}
        onClick={() => newCloseRequestHandler(closeNote)}
      >
        Submit Close Request
      </button>
    );

  const view = (
    <form className={showCloseRequest ? '' : 'hidden'} id={divId}>
      <div className="margin">
        <label>
          Close Note:
          <textarea
            className="notes"
            value={closeNote}
            onChange={(e) => setCloseNote(e.target.value)}
            disabled={savingCloseRequest}
          />
        </label>
      </div>
      {buttons}
      <button
        type="button"
        disabled={savingCloseRequest}
        onClick={() => {
          setShowCloseRequest(false);
          setCloseNote('');
        }}
      >
        Cancel
      </button>
    </form>
  );

  return {
    expandButton,
    view: expandButtonText ? view : (<></>),
  };
}

export default CloseRequest;
