import './VerifyContract.scss';
import * as React from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Button } from '@dsx/react';
import { useAppSelector } from '../app/hooks';
import { RootState } from '../app/store';
import { selectProjectById, submitContractCorrection } from '../features/projects/slice';
import { determineExpectedServiceLocation, formatAsCurrency } from '../features/projects/utils';
import { ContractCorrection } from '../features/projects/types';
import LookupValue from '../features/lookups/LookupValue';
import { LookupDictionary, LookupTypes } from '../features/lookups/types';
import { selectLookups } from '../features/lookups/slice';
import LookupOptions from '../features/lookups/LookupOptions';
import displayError from '../components/displayError';

interface VerificationValues {
  verifiedTotal: boolean;
  newTotal: number;
  verifiedResponsibleParty: boolean;
  newResponsibleParty: string;
  verifiedContractType: boolean;
  newContractType: number | undefined;
  verifiedServiceComponentType: boolean;
  newServiceComponentType: number | undefined;
}

const initialValues: VerificationValues = {
  verifiedTotal: false,
  newTotal: 0,
  verifiedResponsibleParty: false,
  newResponsibleParty: '',
  verifiedContractType: false,
  newContractType: undefined,
  verifiedServiceComponentType: false,
  newServiceComponentType: undefined,
};

function verified(values: VerificationValues): boolean {
  return values.verifiedContractType
    && values.verifiedResponsibleParty
    && values.verifiedServiceComponentType
    && values.verifiedTotal;
}

function getLookupValue(
  lookups: LookupDictionary | undefined, id: number | undefined,
): string | null {
  if (!lookups || !id) return null;

  const value = lookups[id];
  if (!value) return null;

  return value.name;
}

const VerifyContract: React.FC = () => {
  const { projectId, id } = useParams<{ projectId: string, id: string }>();
  const history = useHistory();
  const contractTypeLookups = useAppSelector(
    (state) => selectLookups(state, LookupTypes.ContractType),
  );
  const componentTypeLookups = useAppSelector(
    (state) => selectLookups(state, LookupTypes.ComponentType),
  );
  const project = useAppSelector(
    (state: RootState) => selectProjectById(state, Number(projectId)),
  );
  const contract = project?.contracts.find((c) => c.id === Number(id));

  const [
    formData, setFormData,
  ] = React.useState<VerificationValues>(initialValues);
  const [nextClicked, setNextClicked] = React.useState<boolean>(false);

  const expectedServiceLocation = React.useMemo(() => {
    if (!contract || !project) return '';

    return determineExpectedServiceLocation(project, contract);
  }, [contract, project]);

  const onNext = React.useCallback(() => {
    setNextClicked(true);

    if (verified(formData) && contract?.serviceLocation === expectedServiceLocation) {
      history.push(`/projects/${projectId}/contracts/${id}/phases`);
    } else if (project && contract) {
      const correction: ContractCorrection = {
        pmtNumber: project.pmtNumber,
        serviceContractName: contract.serviceContractName,
        dataManagerId: project.dataManagerId,
        newContractTotal: formData.verifiedTotal ? null : formData.newTotal,
        newResponsibleParty: formData.verifiedResponsibleParty
          ? null
          : formData.newResponsibleParty,
        newContractTypeName: formData.verifiedContractType
          ? null
          : getLookupValue(contractTypeLookups?.data, formData.newContractType),
        newServiceComponentTypeName: formData.verifiedServiceComponentType
          ? null
          : getLookupValue(componentTypeLookups?.data, formData.newServiceComponentType),
        newServiceLocation: contract.serviceLocation === expectedServiceLocation
          ? null
          : expectedServiceLocation,
      };
      submitContractCorrection(project.id, contract.id, correction)
        .then((success) => {
          if (!success) {
            displayError('Failed to save the contract correction.');
          } else {
            history.push(`/projects/${projectId}/contracts/${id}/phases`);
          }
          setNextClicked(false);
        })
        .catch(() => {
          displayError('Failed to save the contract correction.');
          setNextClicked(false);
        });
    }
  }, [
    componentTypeLookups, contract, contractTypeLookups, expectedServiceLocation, formData,
    history, id, project, projectId,
  ]);

  if (!project || !contract) {
    return (
      <main className="verify-contract">
        <div className="error-msg">
          The contract you are looking for cannot be found.
        </div>
        <div>
          <Link to="/dashboard">Go back to dashboard</Link>
        </div>
      </main>
    );
  }

  const totalOk = formData.verifiedTotal || formData.newTotal;
  const respPartyOk = formData.verifiedResponsibleParty || formData.newResponsibleParty;
  const contTypeOk = formData.verifiedContractType || formData.newContractType;
  const compTypeOk = formData.verifiedServiceComponentType || formData.newServiceComponentType;
  const nextEnabled = totalOk && respPartyOk && contTypeOk && compTypeOk;

  const header = (
    <header className="verify-contract-header">
      <h1 className="contract-title">
        {`[${project.pmtNumber} -- ${project.name}] ${contract.serviceContractName}`}
      </h1>
      <nav>
        <ul className="link-list">
          <li className="link"><Link to="/dashboard">Dashboard</Link></li>
          <li className="link"><Link to={`/projects/${projectId}`}>Back to Project</Link></li>
        </ul>
      </nav>
    </header>
  );

  return (
    <main className="verify-contract">
      {header}
      <section className="verify-contract-details">
        <h2>Verify the following data</h2>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Value</th>
              <th>Verified?</th>
              <th>Correct Value?</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Contract Total</td>
              <td>
                {formatAsCurrency(contract.totalAmount)}
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={formData.verifiedTotal}
                  onChange={
                    (e) => {
                      setFormData({ ...formData, verifiedTotal: e.target.checked, newTotal: 0 });
                    }
                  }
                />
              </td>
              <td>
                <input
                  type="number"
                  value={formData.newTotal}
                  disabled={formData.verifiedTotal}
                  onChange={(e) => setFormData({ ...formData, newTotal: Number(e.target.value) })}
                />
              </td>
            </tr>
            <tr>
              <td>Responsible Party</td>
              <td>{contract.responsibleParty}</td>
              <td>
                <input
                  type="checkbox"
                  checked={formData.verifiedResponsibleParty}
                  onChange={
                    (e) => {
                      setFormData({
                        ...formData,
                        verifiedResponsibleParty: e.target.checked,
                        newResponsibleParty: '',
                      });
                    }
                  }
                />
              </td>
              <td>
                <input
                  type="text"
                  value={formData.newResponsibleParty}
                  disabled={formData.verifiedResponsibleParty}
                  onChange={
                    (e) => setFormData({ ...formData, newResponsibleParty: e.target.value })
                  }
                />
              </td>
            </tr>
            <tr>
              <td>Contract Type</td>
              <td>
                <LookupValue
                  lookupId={contract.serviceContractTypeId}
                  lookupType={LookupTypes.ContractType}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={formData.verifiedContractType}
                  onChange={
                    (e) => {
                      setFormData({
                        ...formData,
                        verifiedContractType: e.target.checked,
                        newContractType: undefined,
                      });
                    }
                  }
                />
              </td>
              <td>
                <select
                  disabled={formData.verifiedContractType}
                  onChange={
                    (e) => setFormData({
                      ...formData, newContractType: Number(e.target.value),
                    })
                  }
                >
                  <LookupOptions lookupType={LookupTypes.ContractType} />
                </select>
              </td>
            </tr>
            <tr>
              <td>Component Type</td>
              <td>
                <LookupValue
                  lookupId={contract.serviceComponentTypeId}
                  lookupType={LookupTypes.ComponentType}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={formData.verifiedServiceComponentType}
                  onChange={
                    (e) => {
                      setFormData({
                        ...formData,
                        verifiedServiceComponentType: e.target.checked,
                        newServiceComponentType: undefined,
                      });
                    }
                  }
                />
              </td>
              <td>
                <select
                  disabled={formData.verifiedServiceComponentType}
                  onChange={
                    (e) => setFormData({
                      ...formData, newServiceComponentType: Number(e.target.value),
                    })
                  }
                >
                  <LookupOptions lookupType={LookupTypes.ComponentType} />
                </select>
              </td>
            </tr>
            <tr>
              <td>Service Location</td>
              <td>{contract.serviceLocation}</td>
              <td>
                <input
                  type="checkbox"
                  checked={contract.serviceLocation === expectedServiceLocation}
                  disabled
                />
              </td>
              <td>
                <input
                  type="text"
                  value={expectedServiceLocation}
                  disabled
                />
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <section className="verify-contract-actions">
        <Button
          type="submit"
          variant="primary"
          onClick={onNext}
          disabled={!nextEnabled || nextClicked}
        >
          Next
        </Button>
      </section>
    </main>
  );
};

export default VerifyContract;
