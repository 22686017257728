import { Contract, ContractPhase, PhaseNames } from '../../features/projects/types';
import { ContractPhaseViewModel, ContractViewModel } from './viewModels';
import { computeAmountPerMonthForPhase, computePercentOfContract } from './logic';

export function toPhaseViewModel(
  source: ContractPhase, contractTotal: number,
): ContractPhaseViewModel {
  const { amountPerMonth, activeThisMonth } = computeAmountPerMonthForPhase(
    source.startMonth, source.startYear, source.endMonth, source.endYear, source.remainingAmount,
  );

  return {
    phaseNameId: source.phaseNameId,
    startMonth: source.startMonth,
    startYear: source.startYear,
    canModifyStartMonth: true,
    endMonth: source.endMonth,
    endYear: source.endYear,
    canModifyEndMonth: true,
    canModifyPhaseAmount: source.phaseNameId !== PhaseNames.FlatBill,
    phaseAmount: source.totalAmount,
    percentOfContract: computePercentOfContract(source.totalAmount, contractTotal),
    remainingAmount: source.remainingAmount,
    canModifyRemainingAmount: false,
    amountPerMonth,
    activeThisMonth,
    invalid: false,
  };
}

export function toContractViewModel(
  source: Contract, projectName: string, projectId: number,
): ContractViewModel {
  return {
    name: source.serviceContractName,
    projectName,
    projectId,
    billingModeId: source.billingModeId ?? undefined,
    canModifyBillingModeId: source.billingModeId === null,
    totalAmount: source.totalAmount,
    brokenValidations: [],
    phases: source.phases.map((p) => toPhaseViewModel(p, source.totalAmount)),
    saveDisabled: false,
    amountForMonth: source.expectedCurrentMonth ?? 0,
    amountRemaining: source.amountRemaining,
    sumPhaseAmountRemaining: 0,
    totalOfPhases: source.phases.reduce((acc, p) => acc + p.totalAmount, 0),
    needsBillingTemplate: source.billingTemplateId === null,
  };
}

export function mergeFromPhaseViewModel(
  source: ContractPhaseViewModel, destination: ContractPhase,
): ContractPhase {
  return {
    ...destination,
    startMonth: source.startMonth,
    startYear: source.startYear,
    endMonth: source.endMonth,
    endYear: source.endYear,
    totalAmount: source.phaseAmount ?? 0,
    remainingAmount: source.remainingAmount,
  };
}

function createBlankPhase(phaseNameId: number): ContractPhase {
  return {
    id: 0,
    endMonth: 0,
    endYear: 0,
    phaseNameId,
    remainingAmount: 0,
    startMonth: 0,
    startYear: 0,
    totalAmount: 0,
    currentMonthAuthorization: null,
    expectedCurrentMonth: 0,
  };
}

export function mergeFromContractViewModel(
  source: ContractViewModel, destination: Contract,
): Contract {
  const phases: ContractPhase[] = [];

  // Marry them together - assume they are in the same order
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < source.phases.length; ++i) {
    if (i < destination.phases.length) {
      phases.push(mergeFromPhaseViewModel(source.phases[i], destination.phases[i]));
    } else {
      phases.push(
        mergeFromPhaseViewModel(source.phases[i], createBlankPhase(source.phases[i].phaseNameId)),
      );
    }
  }

  return {
    ...destination,
    billingModeId: source.billingModeId ?? null,
    phases,
    expectedCurrentMonth: source.amountForMonth === undefined ? null : source.amountForMonth,
  };
}
