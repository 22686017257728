import { createSlice, createEntityAdapter, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../../app/store';
import { AuthStatus, User } from './types';
import { fetchMe, fetchUsers, updateUser as updateUserApi } from './api';
import { LoadingStatus } from '../LoadingStatus';

type UsersState = {
  currentUser: User | undefined;
  loading: LoadingStatus;
  authStatus: AuthStatus;
};

const initialState: UsersState = {
  currentUser: undefined,
  loading: LoadingStatus.Uninitialized,
  authStatus: AuthStatus.Unknown,
};

const usersAdapter = createEntityAdapter<User>();

export const usersSlice = createSlice({
  name: 'users',
  initialState: usersAdapter.getInitialState(initialState),
  reducers: {
    usersLoaded: (state, action) => {
      state.loading = LoadingStatus.Idle;
      usersAdapter.setAll(state, action);
    },
    usersLoading: (state) => {
      state.loading = LoadingStatus.Pending;
    },
    usersLoadingFailed: (state) => {
      state.loading = LoadingStatus.Errored;
    },
    authenticated: (state, action: PayloadAction<User>) => {
      state.authStatus = action.payload.isActive ? AuthStatus.Authenticated : AuthStatus.Inactive;
      state.currentUser = action.payload;
    },
    checkingAuth: (state) => {
      state.authStatus = AuthStatus.Checking;
    },
    unauthenticated: (state) => {
      state.authStatus = AuthStatus.Unauthenticated;
    },
    userUpdated: usersAdapter.updateOne,
  },
});

export const {
  usersLoaded, usersLoading, usersLoadingFailed, authenticated, checkingAuth,
  unauthenticated, userUpdated,
} = usersSlice.actions;

const usersSelectors = usersAdapter.getSelectors((state: RootState) => state.users);

export const selectAllUsers = usersSelectors.selectAll;
export const selectUserEntities = usersSelectors.selectEntities;
export const selectUserById = usersSelectors.selectById;
export const selectCurrentUser = (state: RootState): User | undefined => state.users.currentUser;

export default usersSlice.reducer;

export const loadUsers = () => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(usersLoading());
  try {
    const response = await fetchUsers();
    dispatch(usersLoaded(response));
  } catch {
    dispatch(usersLoadingFailed());
  }
};

export const checkIfAuthenticated = () => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(checkingAuth());

  const checkAuth = (): void => {
    fetchMe()
      .then((response) => {
        if (response === undefined) {
          dispatch(unauthenticated());
        } else {
          dispatch(authenticated(response));
          setTimeout(checkAuth, 60000);
        }
      })
      .catch(() => {
        dispatch(unauthenticated());
      });
  };
  checkAuth();
};

export const updateUser = async (
  dispatch: AppDispatch, user: User,
): Promise<boolean> => {
  try {
    if (!user) return false;

    const response = await updateUserApi(user);
    // Undefined response, so some issue saving
    if (response === undefined) return false;

    dispatch(userUpdated({ id: response.id, changes: response }));
    return true;
  } catch {
    return false;
  }
};
