import { Contract, Project } from '../../features/projects/types';
import { toContractViewModel } from './mapping';
import { ContractPhaseViewModel, ContractViewModel } from './viewModels';
import {
  createPhasesForMode,
  computeAmountPerMonthForPhase,
  computePercentOfContract,
  computePhaseAmount,
} from './logic';
import validateContract from './validations';

export interface InitialData {
  contract: Contract | undefined;
  project: Project | undefined;
}

export function initialize({ contract, project }: InitialData): ContractViewModel | undefined {
  if (!contract || !project) return undefined;

  const projectName = `${project.pmtNumber} -- ${project.name}`;
  const vm = toContractViewModel(contract, projectName, project.id);
  return validateContract(vm);
}

export interface ChangeBillingModeAction {
  type: 'changeBillingMode';
  payload: number | undefined;
}

export interface ChangeDatesAction {
  type: 'changeDates';
  payload: {
    phaseNameId: number;
    startMonth: number;
    startYear: number;
    endMonth: number;
    endYear: number;
  };
}

export interface ChangePercentOfContractAction {
  type: 'changePercentOfContract';
  payload: {
    phaseNameId: number;
    newPercent: number;
  }
}

export interface ChangePhaseAmountAction {
  type: 'changePhaseAmount';
  payload: {
    phaseNameId: number;
    newAmount: number;
  }
}

type Action = ChangeBillingModeAction
  | ChangeDatesAction
  | ChangePercentOfContractAction
  | ChangePhaseAmountAction;

function updateDates(
  phases: ContractPhaseViewModel[], action: ChangeDatesAction,
): ContractPhaseViewModel[] {
  return phases.map((p) => {
    if (p.phaseNameId !== action.payload.phaseNameId) return p;

    const {
      startMonth, startYear, endMonth, endYear,
    } = action.payload;

    const { activeThisMonth, amountPerMonth } = computeAmountPerMonthForPhase(
      startMonth, startYear, endMonth, endYear, p.remainingAmount,
    );

    return {
      ...p,
      startMonth,
      startYear,
      endMonth,
      endYear,
      activeThisMonth,
      amountPerMonth,
    };
  });
}

function updatePercentOfContract(
  phases: ContractPhaseViewModel[], action: ChangePercentOfContractAction, contractTotal: number,
): ContractPhaseViewModel[] {
  return phases.map((p) => {
    if (p.phaseNameId !== action.payload.phaseNameId) return p;

    const {
      startMonth, startYear, endMonth, endYear, canModifyRemainingAmount,
    } = p;

    const newAmount = computePhaseAmount(action.payload.newPercent, contractTotal);

    const remainingAmount = canModifyRemainingAmount ? newAmount : p.remainingAmount;

    const { activeThisMonth, amountPerMonth } = computeAmountPerMonthForPhase(
      startMonth, startYear, endMonth, endYear, remainingAmount,
    );

    return {
      ...p,
      amountPerMonth,
      activeThisMonth,
      remainingAmount,
      percentOfContract: action.payload.newPercent,
      phaseAmount: newAmount,
    };
  });
}

function updatePhaseAmount(
  phases: ContractPhaseViewModel[], action: ChangePhaseAmountAction, contractTotal: number,
): ContractPhaseViewModel[] {
  return phases.map((p) => {
    if (p.phaseNameId !== action.payload.phaseNameId) return p;

    const {
      startMonth, startYear, endMonth, endYear, canModifyRemainingAmount,
    } = p;

    const percentOfContract = computePercentOfContract(action.payload.newAmount, contractTotal);

    const remainingAmount = canModifyRemainingAmount ? action.payload.newAmount : p.remainingAmount;

    const { activeThisMonth, amountPerMonth } = computeAmountPerMonthForPhase(
      startMonth, startYear, endMonth, endYear, remainingAmount,
    );

    return {
      ...p,
      amountPerMonth,
      activeThisMonth,
      remainingAmount,
      percentOfContract,
      phaseAmount: action.payload.newAmount,
    };
  });
}

export function reduce(
  state: ContractViewModel | undefined, action: Action,
): ContractViewModel | undefined {
  if (!state) return state;

  let newState = state;

  switch (action.type) {
    case 'changeBillingMode':
      // Skip the action if we can't modify it
      if (!state.canModifyBillingModeId) break;

      newState = {
        ...state,
        billingModeId: action.payload,
        phases: createPhasesForMode(action.payload, state.totalAmount),
      };
      break;
    case 'changeDates':
      newState = { ...state, phases: updateDates(state.phases, action) };
      break;
    case 'changePercentOfContract':
      newState = {
        ...state,
        phases: updatePercentOfContract(state.phases, action, state.totalAmount),
      };
      break;
    case 'changePhaseAmount':
      newState = {
        ...state,
        phases: updatePhaseAmount(state.phases, action, state.totalAmount),
      };
      break;
    default:
      break;
  }

  return validateContract(newState);
}
